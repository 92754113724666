import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getTableData } from "../../../api/table/tableApi";
import {
	Box,
	Checkbox,
	CircularProgress,
	Divider,
	Stack,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
	Badge,
	Button,
	IconButton,
	MenuItem,
} from "@mui/material";
import { default as MuiTable } from "@mui/material/Table";
import { default as CustomIcon } from "../../../assets/icons/custom";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import { default as DragIcon } from "../../../assets/icons/drag";
import { default as FilterIcon } from "../../../assets/icons/filter";
import { TableHeaderLabel, twozoStyles } from "../../../styles/twozo";
import { default as MoreIcon } from "../../../assets/icons/more";
import Menu from "../Menu";
import MenuSearchBar from "../MenuSearchBar";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Emails from "./Fields/emails";
import Phones from "./Fields/phones";
import Company from "./Fields/company";
import Text from "./Fields/text";
import Monetary from "./Fields/monetary";
import Contact from "./Fields/contact";
import Address from "./Fields/address";
import { getTableKey } from "../../../utils/queryKeys/commonQueryKeys";
import Email from "./Fields/email";
import Phone from "./Fields/phone";
import Dropdown from "./Fields/dropdown";
import MultiSelect from "./Fields/multiSelect";
import DateTimeRange from "./Fields/dateTimeRange";
import ActivityStatus from "./Fields/activityStatus";
import ActivityDeal from "./Fields/activityDeal";
import ActivityTitle from "./Fields/activityTitle";
import Owner from "./Fields/owner";
import ContactsOnBoard from "./Fields/contactsOnBoard";
import DateComponent from "./Fields/date";
import FilterDropdown from "./Filter/FilterDropdown";
import CountOverview from "./CountOverview";
import TimeRange from "./Fields/timeRange";
import FilterDrawer from "./Filter/FilterDrawer";
import Note from "./Fields/note";
import { useFilterSaveViewContext } from "./Filter/Context/FilterSaveViewContext";
import { useFilterDataContext } from "./Filter/Context/FilterDataContext";
import Territory from "./Fields/territory";
import Tags from "./Fields/tags";
import DateRange from "./Fields/dateRange";
import TimeComponent from "./Fields/time";

export default function Table(props) {
	const {
		table,
		menu,
		selectedMenu,
		additionalView,
		moduleName,
		enhancedMenu,
		onRowClick,
		isActionsVisible,
		tableMenuOptions = [],
		noResultsMessage,
		hasShareViewPermissions,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const [selected, setSelected] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [selectedColumns, setSelectedColumns] = useState([]);
	const [selectRequest, setSelectRequest] = useState([]);
	const [sortRequest, setSortRequest] = useState([]);
	const [tableStructure, setTableStructure] = useState({});
	const [tableHeadHovered, setTableHeadHovered] = useState(null);
	const [tableRowHovered, setTableRowHovered] = useState(false);
	const [tableRowElement, setTableRowElement] = useState(null);
	const [contactSettingsElement, setContactSettingsElement] = useState(null);
	const [tableMenuElement, setTableMenuElement] = useState(null);
	const isOpenTableMenu = Boolean(tableMenuElement);
	const openTableHeaderMenu = Boolean(contactSettingsElement);
	const [loadingTableData, setLoadingTableData] = useState(true);
	const [searchInput, setSearchInput] = useState("");
	const [filterdTableStructure, setFilteredTableStructure] = useState([]);
	const [countSummary, setCountSummary] = useState([]);

	const sortTypes = {
		ascending: 1,
		decending: 0,
	};

	const styles = {
		menuItem: {
			fontWeight: 500,
			fontSize: "13px",
			color: theme.palette.primary.main,
		},
	};

	const limit = { start: 1, count: 2000 };

	const { filterState, filterListByCondition, openSwipeableFilter } =
		useFilterDataContext();

	const { saveViewState, handleOpenSaveViewAsDialog } =
		useFilterSaveViewContext();

	const tableKey = getTableKey(table, filterState.filterConditions);

	const { isLoading, data, error } = useQuery(
		tableKey,
		() =>
			getTableData(
				table,
				{ fetchHits: true, fetchStruct: true },
				filterState.filterConditions
			),
		{ refetchOnWindowFocus: false }
	);

	const fetchTableData = useMutation(
		async (request) =>
			getTableData(
				request.tableName,
				request.body,
				filterState.filterConditions
			),
		{
			onSuccess: () => {
				setLoadingTableData(false);
				setTableRowHovered(null);
			},
			onError: () => {
				alert("Error fetching table data");
				setLoadingTableData(false);
			},
		}
	);

	if (error) {
		alert("Error" + error);
	}

	useEffect(() => {
		if (!isLoading && !error) {
			setTableStructure(data.structure);
			updateSelectedColumn(data.structure.columns);
			setFilteredTableStructure(data.structure.columns);
		}
		// eslint-disable-next-line
	}, [isLoading, data, error]);

	// if scroll is triggered, close the popover
	useEffect(() => {
		document.addEventListener("scroll", onMouseOutTableRow, true);
		// on unmount, remove the scroll event listener
		return () => {
			document.removeEventListener("scroll", onMouseOutTableRow, true);
		};
	}, []);

	const OpenTableHeaderMenu = (event) => {
		setContactSettingsElement(event.currentTarget);
		setFilteredTableStructure(tableStructure?.columns);
		setSearchInput("");
	};

	const CloseTableHeaderMenu = () => {
		setContactSettingsElement(null);
	};

	const onMouseOverTableRow = (_, id) => {
		setTableRowHovered(id);
	};

	const onMouseOutTableRow = () => {
		setTableRowHovered(null);
	};

	const onMouseOverTableHead = (id) => setTableHeadHovered(id);
	const onMouseOutTableHead = () => setTableHeadHovered(null);

	const reorder = (item, startIndex, endIndex) => {
		const result = Array.from(item);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};
	const onTableHeaderDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		let updatedColumnsStructure = reorder(
			tableStructure.columns,
			result.source.index,
			result.destination.index
		);
		updateSelectedColumn(updatedColumnsStructure);
		setTableStructure((tableStructure) => ({
			...tableStructure,
			columns: [...updatedColumnsStructure],
		}));
		setFilteredTableStructure(updatedColumnsStructure);
		reArrangeSelectedColumn(updatedColumnsStructure);
	};

	const reArrangeSelectedColumn = async (selectedColumns) => {
		const updatedColumnsStructure = selectedColumns.filter(
			(column) => column.hidden === false
		);
		setSelectedColumns(updatedColumnsStructure);
	};

	const updateSelectedColumn = async (columns) => {
		const selectedColumns = [];
		const selectRequest = [];

		columns.forEach((column) => {
			if (!column.hidden) {
				selectedColumns.push(column);
			}
			selectRequest.push({
				field: column.name,
				isEnabled: !column.hidden,
			});
		});

		const tableData = await fetchTableData.mutateAsync({
			tableName: table,
			body: {
				fields: selectRequest,
				sort: sortRequest,
				limit: limit,
			},
		});

		setSelectRequest(selectRequest);
		setSelectedColumns(selectedColumns);
		setTableData(tableData.rows || []);
		setCountSummary(tableData.countSummary || []);
	};

	const toggleColumnVisibility = (column) => {
		let columns = tableStructure.columns;
		let updatedColumnIndex = columns.indexOf(column);

		if (columns?.[updatedColumnIndex]) {
			columns[updatedColumnIndex].hidden =
				!columns[updatedColumnIndex].hidden;
		}

		updateSelectedColumn(columns);

		setTableStructure((tableStructure) => ({
			...tableStructure,
			columns: [...columns],
		}));
	};

	const toggleSort = async (columnIndex) => {
		const sortRequest = [];

		setSelectedColumns((selectedColumns) => {
			if (selectedColumns[columnIndex].sort === sortTypes.ascending) {
				selectedColumns[columnIndex].sort = sortTypes.decending;
				sortRequest.push({
					field: selectedColumns[columnIndex].name,
					type: "desc",
				});
			} else {
				selectedColumns[columnIndex].sort = sortTypes.ascending;
				sortRequest.push({
					field: selectedColumns[columnIndex].name,
					type: "asc",
				});
			}

			selectedColumns.forEach((column, index) => {
				if (
					column.sort === sortTypes.ascending &&
					index !== columnIndex
				) {
					selectedColumns[index].sort = sortTypes.decending;
				}
			});

			return selectedColumns;
		});

		const tableData = await fetchTableData.mutateAsync({
			tableName: table,
			body: {
				select: selectRequest,
				sort: sortRequest,
				limit: limit,
			},
		});

		setSortRequest(sortRequest);
		setTableData(tableData.rows || []);
	};

	const isSelected = (id) => selected.indexOf(id) !== -1;

	const handleCheckboxClick = (event, id) => {
		event.stopPropagation();
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = tableData.map((row) => row.id);
			setSelected(newSelected);
		} else {
			setSelected([]);
		}
	};

	const deSelectAll = async () => {
		setSelected([]);
		const tableData = await fetchTableData.mutateAsync({
			tableName: table,
			body: {
				select: selectRequest,
				sort: sortRequest,
				limit: limit,
			},
		});

		setSelectRequest(selectRequest);
		setSelectedColumns(selectedColumns);
		setTableData(tableData.rows || []);
	};

	const getCellData = (data, structure, row) => {
		switch (structure.fieldType) {
			case "EMAILS":
				return (
					<Emails data={data} id={row.id} rowState={row?.rowState} />
				);
			case "EMAIL":
				return (
					<Email data={data} id={row.id} rowState={row?.rowState} />
				);
			case "PHONES":
				return (
					<Phones data={data} id={row.id} rowState={row?.rowState} />
				);
			case "PHONE":
				return (
					<Phone data={data} id={row.id} rowState={row?.rowState} />
				);
			case "COMPANY_ONBOARD":
				return (
					<Company data={data} id={row.id} rowState={row?.rowState} />
				);
			case "COMPANY":
				return (
					<Company data={data} id={row.id} rowState={row?.rowState} />
				);
			case "TAGS":
				return <Tags data={data} />;
			case "TERRITORY":
				return <Territory data={data} />;
			case "MONETARY":
				return (
					<Monetary
						data={data}
						id={row.id}
						rowState={row?.rowState}
					/>
				);
			case "DEAL_VALUE":
				return (
					<Monetary
						data={data}
						id={row.id}
						rowState={row?.rowState}
					/>
				);
			case "CONTACT":
				return (
					<Contact data={data} id={row.id} rowState={row?.rowState} />
				);
			case "CONTACT_ONBOARD":
				return (
					<Contact data={data} id={row.id} rowState={row?.rowState} />
				);
			case "DROPDOWN":
				return (
					<Dropdown
						data={data}
						id={row.id}
						rowState={row?.rowState}
					/>
				);
			case "ADDRESS":
				return (
					<Address data={data} id={row.id} rowState={row?.rowState} />
				);
			case "DATE_RANGE":
				return <DateRange data={data} />;
			case "TIME_RANGE":
				return <TimeRange data={data} />;
			case "TIME":
				return <TimeComponent data={data} />;
			case "DATE":
				return (
					!!data && (
						<DateComponent
							data={data}
							id={row.id}
							rowState={row?.rowState}
						/>
					)
				);

			case "ACTIVITY_STATUS":
				return (
					<ActivityStatus
						data={data}
						id={row.id}
						rowState={row?.rowState}
					/>
				);
			case "DEAL":
				return (
					<ActivityDeal
						data={data}
						id={row.id}
						rowState={row?.rowState}
					/>
				);
			case "ACTIVITY_TITLE":
				return (
					<ActivityTitle
						data={data}
						id={row.id}
						rowState={row?.rowState}
					/>
				);
			case "OWNER":
				return (
					<Owner data={data} id={row.id} rowState={row?.rowState} />
				);
			case "CONTACTS_ONBOARD":
				return (
					<ContactsOnBoard
						data={data}
						id={row.id}
						rowState={row?.rowState}
					/>
				);
			case "MULTI_SELECT":
				return <MultiSelect data={data} />;
			case "DATE_TIME_RANGE":
				return <DateTimeRange data={data} />;
			case "NOTE":
				return <Note data={data} rowState={row?.rowState} />;
			default:
				try {
					return (
						<Text
							data={data?.toString()}
							id={row.id}
							rowState={row?.rowState}
						/>
					);
				} catch (e) {
					console.log(e);
					return null;
				}
		}
	};

	const handleFilteredField = (event) => {
		const { value } = event.target;

		if (value !== "") {
			const result = tableStructure.columns?.filter((tableStructure) => {
				return tableStructure.displayName
					.toLowerCase()
					.startsWith(value.toLowerCase());
			});
			setFilteredTableStructure(result);
		} else {
			setFilteredTableStructure(tableStructure.columns);
		}
		setSearchInput(value);
	};

	// Table stating position
	const tableRef = useRef(null);
	const [tableStartingPosition, setTableStartingPosition] = useState(0);

	useEffect(() => {
		if (tableRef) {
			setTableStartingPosition(
				tableRef.current.getBoundingClientRect().top
			);
		}
	}, [tableRef]);

	const openTableMenu = (event, row) => {
		event.stopPropagation();
		setTableRowElement(row);
		setTableMenuElement(event.currentTarget);
	};

	const closeTableMenu = () => {
		setTableMenuElement(null);
		setTableRowElement(null);
	};

	const getDragProps = (provided) => {
		return searchInput ? {} : provided.dragHandleProps;
	};

	return (
		<React.Fragment>
			<FilterDrawer
				moduleName={moduleName}
				hasShareViewPermissions={hasShareViewPermissions}
			/>

			<Box className={classes.menuBar}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					height="100%"
					style={{
						marginLeft: filterState.isSwipeableFilterOpened
							? "250px"
							: null,
						transition: filterState.isSwipeableFilterOpened
							? theme.transitions.create("margin", {
									easing: theme.transitions.easing.easeOut,
									duration:
										theme.transitions.duration
											.enteringScreen,
								})
							: theme.transitions.create("margin", {
									easing: theme.transitions.easing.sharp,
									duration:
										theme.transitions.duration
											.leavingScreen,
								}),
					}}
				>
					<Stack direction="row" spacing={3} alignItems="center">
						{!filterState.isSwipeableFilterOpened && (
							<Stack
								onClick={openSwipeableFilter}
								style={{ cursor: "pointer" }}
							>
								{filterState.filterCount ? (
									<Badge
										badgeContent={filterState.filterCount}
										color="primary"
									>
										{FilterIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</Badge>
								) : (
									<Stack>
										{FilterIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</Stack>
								)}
							</Stack>
						)}

						{additionalView && additionalView}

						<FilterDropdown
							moduleName={moduleName}
							hasShareViewPermissions={hasShareViewPermissions}
						/>

						{!!enhancedMenu && selected?.length === 0 && (
							<Stack direction="row" spacing={2}>
								<Divider
									orientation="vertical"
									flexItem={true}
								/>

								<Box display="flex">
									{React.cloneElement(enhancedMenu, {
										filterListByCondition:
											filterListByCondition,
									})}
								</Box>
							</Stack>
						)}

						{saveViewState.isSaveViewAsButtonVisible &&
							filterState.filterCount >= 1 && (
								<Button
									variant="contained"
									color="secondary"
									disableElevation
									onClick={handleOpenSaveViewAsDialog}
								>
									Save View
								</Button>
							)}
					</Stack>

					{selected.length > 0
						? React.cloneElement(selectedMenu, {
								selected: selected,
								setSelected: setSelected,
								data: tableData,
								deSelectAll: deSelectAll,
							})
						: menu}
				</Stack>
			</Box>

			<Menu
				minWidth="200px"
				anchorEl={tableMenuElement}
				open={isOpenTableMenu}
				onClose={closeTableMenu}
			>
				{tableMenuOptions.map((menu) => (
					<MenuItem
						key={menu.id}
						style={{ height: "40px" }}
						onClick={() => {
							menu.action(tableRowElement);
							closeTableMenu();
						}}
					>
						<Stack direction="row" spacing={2}>
							<Box display="flex">
								{menu.icon(20, 20, theme.palette.primary.main)}
							</Box>

							<Typography style={styles.menuItem}>
								{menu.name}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<Box>
				<Menu
					anchorEl={contactSettingsElement}
					open={openTableHeaderMenu}
					onClose={CloseTableHeaderMenu}
					style={{
						marginTop: "3px",
					}}
					sx={{
						"& .MuiMenu-list": {
							paddingTop: "0px",
						},
					}}
				>
					<Box p={0.5}>
						<MenuSearchBar
							value={searchInput}
							onChange={handleFilteredField}
						/>
					</Box>
					<Box sx={{ maxHeight: "350px", overflowY: "auto" }}>
						<DragDropContext onDragEnd={onTableHeaderDragEnd}>
							<Droppable droppableId="tableHead_items">
								{(provided) => (
									<div
										ref={provided.innerRef}
										{...provided.droppableProps}
									>
										{filterdTableStructure &&
										filterdTableStructure.length > 0 ? (
											filterdTableStructure.map(
												(column, index) => (
													<Draggable
														draggableId={
															column.name
														}
														index={index}
														key={column.name}
													>
														{(provided) => (
															<Box
																ref={
																	provided.innerRef
																}
																{...provided.draggableProps}
															>
																<Box
																	pl={1}
																	pr={2}
																	style={{
																		backgroundColor:
																			tableHeadHovered ===
																			column.name
																				? "#F4F5F5"
																				: "#fff",
																	}}
																	onMouseOver={() =>
																		onMouseOverTableHead(
																			column.name
																		)
																	}
																	onMouseOut={() =>
																		onMouseOutTableHead()
																	}
																>
																	<Stack
																		direction="row"
																		alignItems="center"
																		justifyContent="space-between"
																		minHeight="40px"
																	>
																		<Stack
																			direction="row"
																			alignItems="center"
																			spacing={
																				1
																			}
																		>
																			<Checkbox
																				checked={
																					!column.hidden ||
																					column.isMandatoryField
																				}
																				onClick={() =>
																					toggleColumnVisibility(
																						column
																					)
																				}
																				disabled={
																					column.isMandatoryField
																				}
																			/>
																			<Box>
																				<Typography
																					fontSize={
																						13
																					}
																				>
																					{
																						column.displayName
																					}
																				</Typography>
																			</Box>
																		</Stack>

																		<Box
																			{...getDragProps(
																				provided
																			)}
																		>
																			{tableHeadHovered ===
																			column.name ? (
																				<Stack
																					style={{
																						cursor: "pointer",
																						display:
																							searchInput
																								? "none"
																								: "block",
																					}}
																					spacing={
																						2
																					}
																				>
																					{DragIcon(
																						24,
																						24,
																						"grey"
																					)}
																				</Stack>
																			) : (
																				<Box
																					height={
																						24
																					}
																					width={
																						24
																					}
																				/>
																			)}
																		</Box>
																	</Stack>
																</Box>
															</Box>
														)}
													</Draggable>
												)
											)
										) : (
											<Typography
												style={{
													textAlign: "center",
													padding: 10,
													fontSize: "14px",
												}}
											>
												{" "}
												No data{" "}
											</Typography>
										)}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</Box>
				</Menu>
			</Box>

			<Box
				ref={tableRef}
				sx={{
					height: `calc(100vh - ${tableStartingPosition}px)`,
				}}
				style={{
					marginLeft: filterState.isSwipeableFilterOpened
						? "250px"
						: null,
					transition: filterState.isSwipeableFilterOpened
						? theme.transitions.create("margin", {
								easing: theme.transitions.easing.easeOut,
								duration:
									theme.transitions.duration.enteringScreen,
							})
						: theme.transitions.create("margin", {
								easing: theme.transitions.easing.sharp,
								duration:
									theme.transitions.duration.leavingScreen,
							}),
				}}
			>
				{!!isLoading || loadingTableData ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="60vh"
					>
						<CircularProgress />
					</Stack>
				) : (
					<TableContainer sx={{ height: "100%" }}>
						<Stack
							p={1.3}
							width="60px"
							sx={{
								position: "absolute",
								right: 0,
								zIndex: 2,
								backgroundColor: "#fff",
								direction: "row",
								alignItems: "center",
								justifyContent: "center",
								cursor: "pointer",
							}}
							onClick={OpenTableHeaderMenu}
						>
							{CustomIcon(20, 20, theme.palette.primary.main)}
						</Stack>

						<MuiTable sx={{ minWidth: 650 }} size="small">
							<TableHead>
								<TableRow
									sx={{
										position: "sticky",
										top: 0,
										backgroundColor: "#fff",
										zIndex: 1,
										"&:hover": {
											backgroundColor: "#fff",
										},
									}}
								>
									<TableCell padding="checkbox">
										<Checkbox
											indeterminate={
												selected.length > 0 &&
												selected.length <
													tableData.length
											}
											checked={
												tableData.length > 0 &&
												selected.length ===
													tableData.length
											}
											onChange={handleSelectAllClick}
										/>
									</TableCell>

									{selectedColumns.map((column, index) => (
										<TableCell
											key={column.name}
											style={{ minWidth: "200px" }}
											onClick={
												column.isSortable
													? () => toggleSort(index)
													: null
											}
										>
											<Stack
												direction="row"
												spacing={0.5}
												alignItems="center"
											>
												<TableHeaderLabel
													style={{
														whiteSpace: "nowrap",
													}}
												>
													{column.displayName}
												</TableHeaderLabel>

												<Box
													sx={{
														display:
															column.isSortable
																? "flex"
																: "none",
													}}
												>
													{column.sort ===
													sortTypes.ascending ? (
														<Box
															style={{
																transform:
																	"rotate(180deg)",
															}}
															pt={0.5}
														>
															{DropDownIcon(
																13,
																13,
																"rgba(0, 0, 0, 0.6)"
															)}
														</Box>
													) : (
														DropDownIcon(
															13,
															13,
															"rgba(0, 0, 0, 0.6)"
														)
													)}
												</Box>
											</Stack>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{tableData.length > 0 ? (
									tableData.map((row, index) => (
										<TableRow
											key={index + "-" + row.id}
											selected={isSelected(row.id)}
											onMouseOver={(e) =>
												onMouseOverTableRow(e, row.id)
											}
											onMouseOut={() =>
												onMouseOutTableRow()
											}
											onClick={() => onRowClick(row)}
										>
											<TableCell padding="checkbox">
												<Checkbox
													checked={isSelected(row.id)}
													onClick={(e) =>
														handleCheckboxClick(
															e,
															row.id
														)
													}
												/>
											</TableCell>

											{selectedColumns.map(
												(column, index) => (
													<TableCell
														key={index}
														style={{
															minWidth: "200px",
														}}
													>
														{getCellData(
															row[column.name],
															column,
															row
														)}
													</TableCell>
												)
											)}

											<Box
												sx={{
													position: "absolute",
													right: 5,
													zIndex: 2,
												}}
												component="td"
												hidden={
													tableRowElement?.id !==
														row.id &&
													tableRowHovered !== row.id
												}
											>
												{isActionsVisible && (
													<Box
														display="flex"
														p="5px 13px"
														mt={0.2}
														style={{
															border: `1px solid ${theme.palette.primary.main}`,
															borderRadius: "8px",
															backgroundColor:
																"#fff",
														}}
														onClick={(event) =>
															openTableMenu(
																event,
																row
															)
														}
													>
														<Stack alignItems="center">
															<IconButton size="small">
																{MoreIcon(
																	16,
																	16,
																	theme
																		.palette
																		.primary
																		.main
																)}
															</IconButton>
														</Stack>
													</Box>
												)}
											</Box>
										</TableRow>
									))
								) : (
									<TableRow>
										<Stack
											alignItems="center"
											justifyContent="center"
											style={{
												position: "absolute",
												top: "45%",
												left: "40%",
											}}
										>
											<Typography
												fontSize={18}
												fontWeight={500}
												color="rgba(0, 0, 0, 0.6)"
											>
												{noResultsMessage.title}
											</Typography>
											<Typography
												fontSize={14}
												color="rgba(0, 0, 0, 0.6)"
											>
												{noResultsMessage.message}
											</Typography>
										</Stack>
									</TableRow>
								)}
							</TableBody>
						</MuiTable>

						<CountOverview countSummary={countSummary} />
					</TableContainer>
				)}
			</Box>
		</React.Fragment>
	);
}
