import React from "react";
import { Box, Button, Stack } from "@mui/material";
import UserManagementDialog from "../../../../Elements/UserManagementDialog";
import { useUserActivateMutation } from "../../../../../hooks/services/userManagement/user";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { enqueueSnackbar } from "notistack";

export default function ActivateDialog(props) {
	const { open, onClose, userIds, selectedUserFilterId, ...others } = props;

	const activateUserMutation = useUserActivateMutation(selectedUserFilterId);

	const activateUser = (userIds) => {
		activateUserMutation.mutate(userIds, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.userActivation,
				});
				onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<UserManagementDialog open={open} onClose={onClose} {...others}>
				<Box px={3} pb={3}>
					<Stack
						direction="row"
						spacing={2}
						justifyContent="flex-end"
					>
						<Button
							onClick={onClose}
							variant="contained"
							color="secondary"
							disableElevation
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							color="primary"
							disableElevation
							onClick={() => activateUser(userIds)}
						>
							Activate
						</Button>
					</Stack>
				</Box>
			</UserManagementDialog>
		</React.Fragment>
	);
}
