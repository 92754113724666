import { activityGoalsIds, activityGoalsPermission, type } from "..";

export const GetActivityGoalsConfigForPermission = (permissions) => {
	return [
		{
			id: activityGoalsIds.viewGoals,
			name: "View Goals",
			access: permissions.viewGoals.access,
			permissions: {
				type: type.radio,
				permission: [
					{
						id: activityGoalsPermission.allGoals,
						name: "All Goals",
						value:
							permissions.viewGoals.scope ===
							activityGoalsPermission.allGoals,
					},
					{
						id: activityGoalsPermission.teamsGoals,
						name: "Team’s Goals",
						value:
							permissions.viewGoals.scope ===
							activityGoalsPermission.teamsGoals,
					},
					{
						id: activityGoalsPermission.usersGoals,
						name: "User’s Goals",
						value:
							permissions.viewGoals.scope ===
							activityGoalsPermission.usersGoals,
					},
				],
			},
		},
		{
			id: activityGoalsIds.createGoals,
			name: "Create Goals",
			access: permissions.createGoals.access,
			accessDisable: !permissions.viewGoals.access,
			permissions: {
				type: type.radio,
				permission: [
					{
						id: activityGoalsPermission.allGoals,
						name: "All Goals",
						value:
							permissions.createGoals.scope ===
							activityGoalsPermission.allGoals,
					},
					{
						id: activityGoalsPermission.usersGoals,
						name: "User Goals",
						value:
							permissions.createGoals.scope ===
							activityGoalsPermission.usersGoals,
					},
				],
			},
		},
		{
			id: activityGoalsIds.editGoals,
			name: "Edit Goals",
			access: permissions.editGoals.access,
			accessDisable: !permissions.viewGoals.access,
			permissions: {
				type: type.radio,
				permission: [
					{
						id: activityGoalsPermission.allGoals,
						name: "All Goals",
						value:
							permissions.editGoals.scope ===
							activityGoalsPermission.allGoals,
					},
					{
						id: activityGoalsPermission.goalsCreatedbyUser,
						name: "Goals Created by User",
						value:
							permissions.editGoals.scope ===
							activityGoalsPermission.goalsCreatedbyUser,
					},
				],
			},
		},
		{
			id: activityGoalsIds.deleteGoals,
			name: "Delete Goals",
			access: permissions.deleteGoals.access,
			accessDisable: !permissions.viewGoals.access,
			permissions: {
				type: type.radio,
				permission: [
					{
						id: activityGoalsPermission.allGoals,
						name: "All Goals",
						value:
							permissions.deleteGoals.scope ===
							activityGoalsPermission.allGoals,
					},
					{
						id: activityGoalsPermission.goalsCreatedbyUser,
						name: "Goals Created by User",
						value:
							permissions.deleteGoals.scope ===
							activityGoalsPermission.goalsCreatedbyUser,
					},
				],
			},
		},
		{
			id: activityGoalsIds.recalculateGoals,
			name: "Recalculate Goals",
			access: permissions.recalculateGoals.access,
			accessDisable: !permissions.viewGoals.access,
		},
	];
};
