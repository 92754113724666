import React from "react";
import ViewEmail from "../../../Mail/ViewEmail";
import { useMailData } from "../../../../hooks/services/mail";
import { Stack } from "@mui/system";
import { CircularProgress } from "@mui/material";

export default function ConversationViewer(props) {
	const { onClose, mailId } = props;

	const { data: mailData, isLoading: isLoadingMailData } =
		useMailData(mailId);

	return (
		<React.Fragment>
			{isLoadingMailData ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<ViewEmail mailData={mailData} onClose={onClose} />
			)}
		</React.Fragment>
	);
}
