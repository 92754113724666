import { getRootKey } from "../../rootKey";

const userKey = "users";

// user query key
export const getUserKey = () => {
	return [...getRootKey(), userKey];
};

// query key methods
export const getUserKeyById = (id) => {
	return [...getUserKey(), String(id)];
};

export const getUserTableDataKey = (stateId, searchValue, fieldToSort) => {
	if (searchValue || Object.keys(fieldToSort).length > 0) {
		return [
			...getUserKey(),
			"userTableData",
			stateId,
			searchValue,
			fieldToSort,
		];
	} else {
		return [...getUserKey(), "userTableData", stateId];
	}
};

export const getUserListKey = (searchedValue) => {
	if (searchedValue) {
		return [...getUserKey(), "userList", searchedValue];
	}

	return [...getUserKey(), "userList"];
};

export const getUserDataKey = (id) => {
	return [...getUserKeyById(id), "userDetails"];
};

export const getUserSummaryKey = (id) => {
	return [...getUserKeyById(id), "userSummary"];
};

export const getUserToDoAcitivityKey = (id) => {
	return [...getUserKeyById(id), "todoActivities"];
};

export const getUserCompletedActivityKey = (id) => {
	return [...getUserKeyById(id), "completedActivities"];
};

export const getUserAuditLogKey = (id) => {
	return [...getUserKeyById(id), "auditLog"];
};

export const getUserDetailsKey = () => {
	return [...getUserKey(), "allUserDeatils"];
};
