import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDownCentered";
import {
	TableCellText,
	TableHeaderLabel,
	Tooltip,
	hexToRgba,
} from "../../../../../styles/twozo";
import Menu from "../../../../Elements/Menu";
import UnKnownImage from "../../../../../assets/images/contact/unknownContact.png";
import { default as MoreIcon } from "../../../../../assets/icons/more";
import { useUserDetailsList } from "../../../../../hooks/services/userManagement/user";

export default function AddTeamDialog(props) {
	const {
		title,
		onClose,
		isSaving,
		onSave,
		onUpdate,
		isEditMode,
		teamDetail,
		isLoadingTeamData,
	} = props;
	const theme = useTheme();

	// query call :-
	const { data: userList } = useUserDetailsList();

	const [teamName, setTeamName] = useState("");
	const [selectedUser, setSelectedUser] = useState({});
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [teamUsersList, setTeamUsersList] = useState([]);
	const [selectedUserIds, setSelectedUserIds] = useState([]);
	const [tableRowHovered, setTableRowHovered] = useState(null);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [isAllUserSelected, setIsAllUserSelected] = useState(false);
	const [isAddUserTooltipOpened, setIsAddUserTooltipOpened] = useState(false);

	const [userMenuElement, setUserMenuElement] = useState(null);
	const isUserMenuOpened = Boolean(userMenuElement);
	const [teamsMenuElement, setTeamsMenuElement] = useState(null);
	const isTeamsMenuOpened = Boolean(teamsMenuElement);
	const teamUsersInTeam = teamUsersList.filter(
		(list) => !list.isUserNotAdded
	);
	const filteredUserListData = useMemo(() => {
		let userListData = userList?.filter((user) => {
			let teamUsersIds = teamUsersList.map((teamUser) => teamUser.id);
			return !teamUsersIds.includes(user.id);
		});
		return userListData;
	}, [userList, teamUsersList]);

	const handleChangeTeamName = (event) => {
		const { value } = event.target;
		setTeamName(value);
	};

	useEffect(() => {
		if (teamDetail?.name) {
			setTeamName(teamDetail.name);
		}
		if (Array.isArray(teamDetail?.users)) {
			setTeamUsersList(teamDetail.users);
		}
	}, [teamDetail, userList]);

	const openTeamsMenu = (event, teamUsers) => {
		setTeamsMenuElement(event.currentTarget);
		setSelectedUserId(teamUsers.id);
		setSelectedUser(teamUsers);
	};

	const closeTeamsMenu = () => {
		setTeamsMenuElement(null);
		setSelectedUserId(null);
	};

	const onMouseOverTableRow = (id) => setTableRowHovered(id);
	const onMouseOutTableRow = () => setTableRowHovered(null);

	const onMouseOverAddUser = () => setIsAddUserTooltipOpened(true);
	const onMouseOutAddUser = () => setIsAddUserTooltipOpened(false);

	const openUserMenu = (event, index) => {
		if (filteredUserListData?.length > 0) {
			setUserMenuElement(event.currentTarget);
			setSelectedIndex(index);
		}
	};

	const closeUserMenu = () => {
		setUserMenuElement(null);
		setSelectedIndex(null);
	};

	const addTeamUsers = () => {
		if (filteredUserListData?.length > 0) {
			let teamUsersListLength = teamUsersList.length;

			let teamUsers = {
				id: `id ${teamUsersListLength + 1}`,
				name: "",
				email: "-",
				role: {
					name: "-",
				},
				isUserNotAdded: true,
				assignAsManager: false,
			};

			setTeamUsersList([...teamUsersList, teamUsers]);
		}
	};

	const deleteTeamUsers = (index) => {
		setTeamUsersList((teamUsersList) => {
			teamUsersList.splice(index, 1);
			return [...teamUsersList];
		});
	};

	const selectUser = (user) => {
		let updatedTeamUsersList = teamUsersList.map((teamUsers, index) => {
			if (index === selectedIndex) {
				return {
					...teamUsers,
					name: user.name,
					email: user.email,
					role: {
						...user.role,
						name: user.role.name,
						id: user.role.id,
					},
					isUserNotAdded: false,
					id: user.value || user.id,
				};
			}
			return teamUsers;
		});
		setTeamUsersList(updatedTeamUsersList);
		setUserMenuElement(null);
		setSelectedIndex(null);

		if (isAllUserSelected) {
			let newSelectedUserIds = updatedTeamUsersList
				.filter((teamUsers) => !teamUsers.isUserNotAdded)
				.map((teamUsers) => teamUsers.id);
			setSelectedUserIds(newSelectedUserIds);
		}
	};

	const handleSelectAllUsers = (event) => {
		if (event.target.checked) {
			let newSelectedUserIds = teamUsersList
				.filter((teamUsers) => !teamUsers.isUserNotAdded)
				.map((teamUsers) => teamUsers.id);
			setSelectedUserIds(newSelectedUserIds);
			setIsAllUserSelected(true);
		} else {
			setSelectedUserIds([]);
			setIsAllUserSelected(false);
		}
	};

	const isUserSelected = (id) => selectedUserIds.indexOf(id) !== -1;

	const handleSelectUser = (event, id) => {
		event.stopPropagation();
		let selectedIndex = selectedUserIds?.indexOf(id);
		let newSelectedIds = [];

		if (selectedIndex === -1) {
			newSelectedIds = newSelectedIds.concat(selectedUserIds, id);
		} else {
			newSelectedIds = [
				...selectedUserIds.slice(0, selectedIndex),
				...selectedUserIds.slice(selectedIndex + 1),
			];
		}
		setSelectedUserIds(newSelectedIds);
	};

	const removeMultipleUserFromTeam = () => {
		let remainingUsers = teamUsersList.filter(
			(list) => !selectedUserIds.includes(list.id)
		);

		setTeamUsersList(remainingUsers);
		setSelectedUserIds([]);
	};

	const removeUserFromTeam = () => {
		let remainingUsers = teamUsersList.filter(
			(user) => user.id !== selectedUserId
		);
		setTeamUsersList(remainingUsers);
		closeTeamsMenu();
	};

	const updateTeamManager = (isManager) => {
		let updatedTeamUsersList = teamUsersList.map((teamUsers) => {
			if (teamUsers.id === selectedUser.id) {
				return { ...teamUsers, assignAsManager: isManager };
			} else {
				return teamUsers;
			}
		});
		setTeamUsersList(updatedTeamUsersList);
		closeTeamsMenu();
	};

	const getCountOfTeamUsers = () => {
		let singleDigitCount = 9;
		if (
			teamUsersInTeam.length > singleDigitCount ||
			teamUsersInTeam.length === 0
		) {
			return teamUsersInTeam.length;
		} else {
			return `0${teamUsersInTeam.length}`;
		}
	};

	const handleSave = () => {
		if (isValidTeam()) {
			let requestData = getCreateTeamRequestData();
			onSave(requestData);
		}
	};

	const handleUpdate = () => {
		if (isValidTeam()) {
			let requestData = getUpdateTeamRequestData();
			onUpdate(requestData);
		}
	};

	const getFilteredTeamUsersList = () => {
		let filteredTeamUsersList = teamUsersList
			.filter((teamUsers) => teamUsers.name !== "")
			.map((Users) => {
				return {
					id: Users.id,
					assignAsManager: !!Users.assignAsManager,
				};
			});

		return filteredTeamUsersList;
	};

	const getCreateTeamRequestData = () => {
		let requestData = {};
		requestData.name = teamName;

		let filteredTeamUsersList = getFilteredTeamUsersList();
		requestData.users = filteredTeamUsersList;

		return requestData;
	};

	const getUpdateTeamRequestData = () => {
		let requestData = { ...teamDetail };
		requestData.name = teamName;

		let filteredTeamUsersList = getFilteredTeamUsersList();
		requestData.users = filteredTeamUsersList;

		return requestData;
	};

	const isValidTeam = () => {
		let filteredTeamUsersList = getFilteredTeamUsersList();
		return teamName.trim() !== "" && filteredTeamUsersList.length > 0;
	};

	const isHideMoreIcon = (user) => {
		return !(selectedUserId !== user.id && tableRowHovered !== user.id);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="300px"
				anchorEl={userMenuElement}
				open={isUserMenuOpened}
				onClose={closeUserMenu}
				style={{ marginTop: "12px" }}
				PaperProps={{ style: { maxHeight: "213px" } }}
			>
				{filteredUserListData?.map((user) => (
					<MenuItem
						key={user.id}
						style={{ minHeight: "40px" }}
						onClick={() => selectUser(user)}
					>
						<Stack direction="row" alignItems="center" spacing={1}>
							<img
								src={UnKnownImage}
								alt="img"
								width="20px"
								height="20px"
							/>
							<Typography fontSize={13}>{user.name}</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<Menu
				minWidth="200px"
				anchorEl={teamsMenuElement}
				open={isTeamsMenuOpened}
				onClose={closeTeamsMenu}
				style={{ marginTop: "8px" }}
			>
				{selectedUser.assignAsManager ? (
					<MenuItem
						style={{ minHeight: "40px" }}
						onClick={() => updateTeamManager(false)}
					>
						<Typography
							fontSize={13}
							fontWeight={500}
							color={theme.palette.secondary.contrastText}
						>
							Remove Team Manager
						</Typography>
					</MenuItem>
				) : (
					<MenuItem
						style={{ minHeight: "40px" }}
						onClick={() => updateTeamManager(true)}
					>
						<Typography
							fontSize={13}
							fontWeight={500}
							color={theme.palette.secondary.contrastText}
						>
							Make Team Manager
						</Typography>
					</MenuItem>
				)}

				<MenuItem
					style={{ minHeight: "40px" }}
					onClick={removeUserFromTeam}
				>
					<Typography
						fontSize={13}
						fontWeight={500}
						color={theme.palette.secondary.contrastText}
					>
						Remove from Team
					</Typography>
				</MenuItem>
			</Menu>

			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontSize={16} fontWeight={600}>
							{title}
						</Typography>

						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(25, 25, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				{isLoadingTeamData ? (
					<Stack
						height="90vh"
						justifyContent="center"
						alignItems="center"
					>
						<CircularProgress />
					</Stack>
				) : (
					<Box
						px={2}
						py={2}
						style={{
							flex: "1 1 auto",
							minHeight: 0,
						}}
					>
						<Box
							height="100%"
							style={{
								display: "flex",
								flex: "1 1 auto",
								flexDirection: "column",
							}}
						>
							<Box pb={selectedUserIds.length >= 1 ? 2 : 3}>
								<Stack>
									<Typography
										fontSize={13}
										fontWeight={500}
										padding="5px"
									>
										Team Name{" "}
										<span
											style={{
												color: theme.palette.error.main,
											}}
										>
											*
										</span>
									</Typography>

									<TextField
										value={teamName}
										onChange={handleChangeTeamName}
										placeholder="Eg: Team India"
									/>
								</Stack>
							</Box>

							{selectedUserIds.length > 0 ? (
								<Box pb={2}>
									<Stack
										alignItems="flex-end"
										justifyContent="center"
									>
										<Typography
											fontSize={13}
											fontWeight={600}
											color={theme.palette.primary.main}
											borderRadius="8px"
											bgcolor={
												theme.palette.secondary.main
											}
											style={{
												padding: "4px 8px",
												cursor: "pointer",
											}}
											onClick={removeMultipleUserFromTeam}
										>
											Remove from Team (
											{getCountOfTeamUsers()})
										</Typography>
									</Stack>
								</Box>
							) : null}

							<Box
								style={{
									border: "1px solid rgba(0, 0, 0, 0.1)",
									borderRadius: "8px",
									overflowY: "auto",
									flex: "1 1 auto",
								}}
							>
								<TableContainer>
									<Table size="small">
										<TableHead>
											<TableRow style={{ width: "100%" }}>
												<TableCell
													padding="checkbox"
													style={{ width: "10%" }}
												>
													<Stack
														alignItems="center"
														justifyContent="center"
													>
														<Checkbox
															indeterminate={
																selectedUserIds.length >
																	0 &&
																selectedUserIds.length <
																	teamUsersInTeam.length
															}
															checked={
																teamUsersInTeam.length >
																	0 &&
																selectedUserIds.length ===
																	teamUsersInTeam.length
															}
															onChange={
																handleSelectAllUsers
															}
														/>
													</Stack>
												</TableCell>

												<TableCell
													style={{ width: "35%" }}
												>
													<Stack
														direction="row"
														alignItems="center"
														spacing={0.5}
													>
														<TableHeaderLabel>
															User
														</TableHeaderLabel>
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.5)"
														)}
													</Stack>
												</TableCell>

												<TableCell
													style={{ width: "30%" }}
												>
													<Stack
														direction="row"
														alignItems="center"
														spacing={0.5}
													>
														<TableHeaderLabel>
															Email
														</TableHeaderLabel>
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.5)"
														)}
													</Stack>
												</TableCell>

												<TableCell
													style={{ width: "15%" }}
												>
													<Stack
														direction="row"
														alignItems="center"
														spacing={0.5}
													>
														<TableHeaderLabel>
															Role
														</TableHeaderLabel>
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.5)"
														)}
													</Stack>
												</TableCell>

												<TableCell
													style={{ width: "10%" }}
												></TableCell>
											</TableRow>
										</TableHead>

										<TableBody>
											<TableRow style={{ width: "100%" }}>
												<TableCell
													style={{ width: "10%" }}
												></TableCell>

												<TableCell colSpan={4}>
													<Tooltip
														title="No user available"
														placement="top"
														open={
															isAddUserTooltipOpened &&
															filteredUserListData?.length ===
																0
														}
													>
														<Typography
															onMouseOver={
																onMouseOverAddUser
															}
															onMouseOut={
																onMouseOutAddUser
															}
															fontWeight={500}
															fontSize={13}
															color={
																theme.palette
																	.secondary
																	.contrastText
															}
															sx={{
																opacity:
																	filteredUserListData?.length >
																	0
																		? 1
																		: 0.5,
																width: "25%",
															}}
															onClick={
																addTeamUsers
															}
														>
															+ Add Team Member
														</Typography>
													</Tooltip>
												</TableCell>
											</TableRow>

											{teamUsersList.map(
												(user, index) => (
													<TableRow
														key={user.id}
														selected={isUserSelected(
															user.id
														)}
														onMouseOver={() =>
															onMouseOverTableRow(
																user.id
															)
														}
														onMouseOut={
															onMouseOutTableRow
														}
													>
														<TableCell
															padding="checkbox"
															style={{
																width: "10%",
															}}
														>
															<Stack
																alignItems="center"
																justifyContent="center"
															>
																{user.isUserNotAdded ? (
																	<Stack
																		alignItems="center"
																		onClick={() =>
																			deleteTeamUsers(
																				index
																			)
																		}
																	>
																		{CloseIcon(
																			20,
																			20,
																			"rgba(0, 0, 0, 0.6)"
																		)}
																	</Stack>
																) : (
																	<Checkbox
																		checked={isUserSelected(
																			user.id
																		)}
																		onClick={(
																			event
																		) =>
																			handleSelectUser(
																				event,
																				user.id
																			)
																		}
																	/>
																)}
															</Stack>
														</TableCell>

														<TableCell
															style={{
																borderBottom:
																	selectedIndex ===
																	index
																		? `2px solid ${theme.palette.primary.main}`
																		: null,
																width: "35%",
															}}
														>
															{user.isUserNotAdded ? (
																<Box
																	onClick={(
																		event
																	) =>
																		openUserMenu(
																			event,
																			index
																		)
																	}
																>
																	<Typography
																		fontSize={
																			14
																		}
																		fontWeight={
																			400
																		}
																		color="rgba(0, 0, 0, 0.6)"
																	>
																		Select
																	</Typography>
																</Box>
															) : (
																<Stack
																	direction="row"
																	alignItems="center"
																	spacing={1}
																>
																	<img
																		src={
																			UnKnownImage
																		}
																		alt="img"
																		width="26px"
																		height="26px"
																	/>
																	<TableCellText
																		style={{
																			whiteSpace:
																				"nowrap",
																		}}
																	>
																		{
																			user.name
																		}
																	</TableCellText>

																	{user.assignAsManager && (
																		<Typography
																			fontSize={
																				12
																			}
																			fontWeight={
																				500
																			}
																			color={
																				theme
																					.palette
																					.secondary
																					.contrastText
																			}
																			bgcolor={
																				theme
																					.palette
																					.secondary
																					.main
																			}
																			py={
																				0.2
																			}
																			px={
																				0.5
																			}
																			borderRadius="6px"
																		>
																			Manager
																		</Typography>
																	)}
																</Stack>
															)}
														</TableCell>

														<TableCell
															style={{
																width: "30%",
															}}
														>
															<TableCellText>
																{user.email}
															</TableCellText>
														</TableCell>

														<TableCell
															style={{
																width: "15%",
															}}
														>
															<TableCellText>
																{user.role.name}
															</TableCellText>
														</TableCell>

														<TableCell
															sx={{
																py: 0,
																pl: 0,
																pr: 1,
																width: "10%",
															}}
														>
															{!user.isUserNotAdded && (
																<Stack alignItems="flex-end">
																	{isHideMoreIcon(
																		user
																	) ? (
																		<Box
																			style={{
																				width: "fit-content",
																				border: `1px solid ${theme.palette.primary.main}`,
																				borderRadius:
																					"8px",
																				padding:
																					"2px 8px",
																			}}
																		>
																			<IconButton
																				size="small"
																				onClick={(
																					event
																				) =>
																					openTeamsMenu(
																						event,
																						user
																					)
																				}
																			>
																				{MoreIcon(
																					20,
																					20,
																					theme
																						.palette
																						.primary
																						.main
																				)}
																			</IconButton>
																		</Box>
																	) : null}
																</Stack>
															)}
														</TableCell>
													</TableRow>
												)
											)}
										</TableBody>
									</Table>

									<Stack
										direction="row"
										justifyContent="flex-end"
										py={1}
										px={2}
										sx={{
											position: "fixed",
											bottom: "108px",
											right: "40px",
											backgroundColor:
												theme.palette.secondary.main,
											borderRadius: "8px",
										}}
									>
										<Typography
											style={{
												fontSize: "14px",
												fontWeight: "500",
												color: "rgba(0, 0, 0, 0.6)",
											}}
										>
											Total: {getCountOfTeamUsers()}
										</Typography>
									</Stack>
								</TableContainer>
							</Box>
						</Box>
					</Box>
				)}
				<Divider />

				{isEditMode ? (
					<Box p={2}>
						<Button
							variant="contained"
							color="primary"
							disableElevation
							onClick={handleUpdate}
							disabled={isSaving}
							style={{
								color: isValidTeam()
									? "#FFFFFF"
									: hexToRgba("#FFFFFF", 0.6),
							}}
						>
							Update
						</Button>
					</Box>
				) : (
					<Box p={2}>
						<Button
							variant="contained"
							color="primary"
							disableElevation
							onClick={handleSave}
							disabled={isSaving}
							style={{
								color: isValidTeam()
									? "#FFFFFF"
									: hexToRgba("#FFFFFF", 0.6),
							}}
						>
							Save
						</Button>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}
