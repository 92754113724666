import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import {
	SummaryFieldName,
	Tooltip,
	twozoStyles,
} from "../../../../../../../styles/twozo";
import { getDateMonthAndYearFormat } from "../../../../../../../utils/DateUtils";
import { useSummaryContext } from "../../../../../../Summary/SummaryContext";
import FieldEdit from "../../../../../FieldEdit";
import DateRangeFieldEditForm from "../../../../../FieldEditFormComponents/DateRangeFIeldEditForm";

export default function DateRangeFieldValue(props) {
	const { summaryField, subFields } = props;

	// summary context
	const { updateSummaryField, entityId } = useSummaryContext();

	const theme = useTheme();
	const classes = twozoStyles();

	const [openToolTip, setOpenToolTip] = useState(false);

	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subFields && Array.isArray(subFields)) {
			subFields.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const fromDateField = getSubFieldObject("FROM");
	const toDateField = getSubFieldObject("TO");

	const isSummaryFieldValid = (summaryField) => {
		return summaryField.value?.FROM?.value && summaryField.value?.TO?.value;
	};

	const getSummaryFieldData = (summaryField) => {
		if (!isSummaryFieldValid(summaryField)) {
			return "";
		}

		return `${getDateMonthAndYearFormat(
			summaryField?.value?.FROM?.value
		)}  TO  ${getDateMonthAndYearFormat(summaryField?.value?.TO?.value)}`;
	};

	const handleOpenTooltip = (event) => {
		setOpenToolTip(event.target.scrollWidth > event.target.clientWidth);
	};
	const getDateRangeRequestData = (dateRangeFieldValue) => {
		return {
			...dateRangeFieldValue,
			id: entityId,
		};
	};

	const hasValueOrIsChanged = (dateRangeFieldValue) => {
		const value = dateRangeFieldValue[summaryField?.fieldId];
		if (summaryField.value) {
			const { FROM, TO } = summaryField.value;
			return (
				(FROM?.value !== value[fromDateField?.id] &&
					!!value[fromDateField?.id]) ||
				(TO?.value !== value[toDateField?.id] &&
					!!value[toDateField?.id])
			);
		}
		return !!value;
	};

	const handleSave = (dateRangeFieldValue, onClose) => {
		if (hasValueOrIsChanged(dateRangeFieldValue)) {
			let requestData = getDateRangeRequestData(dateRangeFieldValue);
			updateSummaryField(requestData, onClose);
		} else {
			onClose();
		}
	};

	const styles = {
		inputContent: {
			width: "150px",
			"&:hover": { borderBottom: "1px solid rgba(0, 0, 0, 0.2)" },
			fontSize: "14px",
			fontWeight: 500,
			color: theme.palette.secondary.contrastText,
			cursor: "pointer",
			opacity: "0.4",
		},
	};

	const modifiedDateRangeStructure = useMemo(() => {
		if (summaryField.value) {
			const { FROM, TO } = summaryField.value;
			return {
				[FROM?.id]: FROM?.value,
				[TO?.id]: TO?.value,
			};
		}
		return "";
	}, [summaryField]);

	const getDateRangeFieldLabel = (onFieldClicked) => {
		return (
			<Box onClick={onFieldClicked}>
				{summaryField.value ? (
					<Tooltip
						open={openToolTip}
						placement="bottom"
						title={getSummaryFieldData(summaryField) || ""}
					>
						<SummaryFieldName
							className={classes.summaryData}
							onMouseOver={handleOpenTooltip}
							onMouseLeave={() => setOpenToolTip(false)}
						>
							{getSummaryFieldData(summaryField)}
						</SummaryFieldName>
					</Tooltip>
				) : summaryField.config.readOnly ? (
					<SummaryFieldName style={{ opacity: 0.6 }}>
						-
					</SummaryFieldName>
				) : (
					<Typography sx={styles.inputContent}>
						Click To Enter
					</Typography>
				)}
			</Box>
		);
	};

	return (
		<React.Fragment>
			<FieldEdit field={summaryField} fieldLabel={getDateRangeFieldLabel}>
				<DateRangeFieldEditForm
					onSave={handleSave}
					field={summaryField}
					subFields={subFields}
					value={modifiedDateRangeStructure}
				/>
			</FieldEdit>
		</React.Fragment>
	);
}
