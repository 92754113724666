import { getRootKey } from "../../rootKey";

const rolesKey = "roles";

export const getRolesKey = () => {
	return [...getRootKey(), rolesKey];
};

export const getRoleTableDataKey = (fieldToSort) => {
	if (Object.keys(fieldToSort).length > 0) {
		return [...getRolesKey(), "roleTableData", fieldToSort];
	} else return [...getRolesKey(), "roleTableData"];
};

export const getRolesListKey = () => {
	return [...getRolesKey(), "rolesList"];
};

export const getRolesUserListKey = (id) => {
	return [...getRolesKey(), id];
};

export const getRolesDetailsKey = (id) => {
	return [...getRolesKey(), id];
};
