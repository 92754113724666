import {
	quotasAndForecastingIds,
	quotasAndForecastingPermissions,
	type,
} from "..";

export const GetQuotasAndForecastingConfigForPermission = (permissions) => {
	return [
		{
			id: quotasAndForecastingIds.viewQuotas,
			name: "View Quotas",
			access: permissions.viewQuotas.access,
			permissions: {
				type: type.radio,
				permission: [
					{
						id: quotasAndForecastingPermissions.allQuotas,
						name: "All Quotas",
						value: permissions.viewQuotas.scope === "allQuotas",
					},
					{
						id: quotasAndForecastingPermissions.teamQuotas,
						name: "Team Quotas",
						value: permissions.viewQuotas.scope === "teamQuotas",
					},
					{
						id: quotasAndForecastingPermissions.usersQuotas,
						name: "User’s Quotas",
						value: permissions.viewQuotas.scope === "usersQuotas",
					},
				],
			},
		},
		{
			id: quotasAndForecastingIds.createQuotas,
			name: "Create Quotas",
			access: permissions.createQuotas.access,
			accessDisable: !permissions.viewQuotas.access,
			permissions: {
				type: type.radio,
				permission: [
					{
						id: quotasAndForecastingPermissions.allQuotas,
						name: "All Quotas",
						value: permissions.createQuotas.scope === "allQuotas",
					},
					{
						id: quotasAndForecastingPermissions.usersQuotas,
						name: "User’s Quotas",
						value: permissions.createQuotas.scope === "usersQuotas",
					},
				],
			},
		},
		{
			id: quotasAndForecastingIds.editQuotas,
			name: "Edit Quotas",
			access: permissions.editQuotas.access,
			accessDisable: !permissions.viewQuotas.access,
			permissions: {
				type: type.radio,
				permission: [
					{
						id: quotasAndForecastingPermissions.allQuotas,
						name: "All Quotas",
						value: permissions.createQuotas.scope === "allQuotas",
					},
					{
						id: quotasAndForecastingPermissions.quotasCreatedbyUser,
						name: "Quotas Created by User",
						value:
							permissions.createQuotas.scope ===
							"quotasCreatedbyUser",
					},
				],
			},
		},
		{
			id: quotasAndForecastingIds.deleteQuotas,
			name: "Delete Quotas",
			access: permissions.deleteQuotas.access,
			accessDisable: !permissions.viewQuotas.access,
			permissions: {
				type: type.radio,
				permission: [
					{
						id: quotasAndForecastingPermissions.allQuotas,
						name: "All Quotas",
						value: permissions.deleteQuotas.scope === "allQuotas",
					},
					{
						id: quotasAndForecastingPermissions.quotasCreatedbyUser,
						name: "Quotas Created by User",
						value:
							permissions.deleteQuotas.scope ===
							"quotasCreatedbyUser",
					},
				],
			},
		},
		{
			id: quotasAndForecastingIds.recalculateQuotas,
			name: "Recalculate Quotas",
			access: permissions.recalculateQuotas.access,
			accessDisable: !permissions.viewQuotas.access,
		},
		{
			id: quotasAndForecastingIds.teamView,
			name: "Team View",
			access: permissions.teamView.access,
			permissions: {
				type: type.radio,
				permission: [
					{
						id: quotasAndForecastingPermissions.companyLevelAccess,
						name: "Company-Level Access",
						value:
							permissions.teamView.scope === "companyLevelAccess",
					},
					{
						id: quotasAndForecastingPermissions.teamLevelAccess,
						name: "Team-Level Access",
						value: permissions.teamView.scope === "teamLevelAccess",
					},
					{
						id: quotasAndForecastingPermissions.userLevelAccess,
						name: "User-Level Access",
						value: permissions.teamView.scope === "userLevelAccess",
					},
				],
			},
		},
		{
			id: quotasAndForecastingIds.hierarchyView,
			name: "Hierarchy View",
			access: permissions.hierarchyView.access,
			accessDisable: !permissions.viewQuotas.access,
			permissions: {
				type: type.radio,
				permission: [
					{
						id: quotasAndForecastingPermissions.companyLevelAccess,
						name: "Company-Level Access",
						value:
							permissions.hierarchyView.scope ===
							"companyLevelAccess",
					},
					{
						id: quotasAndForecastingPermissions.reportLevelAccess,
						name: "Report-Level Access",
						value:
							permissions.hierarchyView.scope ===
							"reportLevelAccess",
					},
				],
			},
		},
		{
			id: quotasAndForecastingIds.commitDeal,
			name: "Commit Deals",
			access: permissions.commitDeal.access,
			accessDisable: !permissions.viewQuotas.access,
		},
		{
			id: quotasAndForecastingIds.overrideCommitAndBestCaseValues,
			name: "Override Commit and Best Case Values",
			access: permissions.overrideCommitAndBestCaseValues.access,
			accessDisable: !permissions.viewQuotas.access,
		},
	];
};
