import { actionsIds, sourceIds, type } from "..";

export const GetActionsConfigForPermission = (permissions) => {
	return [
		{
			id: actionsIds.assignRecords,
			name: "Assign Records",
			access: permissions?.assignRecords.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: sourceIds.contacts,
						name: "Contacts",
						value: permissions?.assignRecords.contacts,
					},
					{
						id: sourceIds.companies,
						name: "Companies",
						value: permissions?.assignRecords.companies,
					},
					{
						id: sourceIds.deals,
						name: "Deals",
						value: permissions?.assignRecords.deals,
					},
					{
						id: sourceIds.products,
						name: "Products",
						value: permissions?.assignRecords.products,
					},
				],
			},
		},
		{
			id: actionsIds.bulkAssignRecords,
			name: "Bulk assign Records",
			access: permissions?.bulkAssignRecords.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: sourceIds.contacts,
						name: "Contacts",
						value: permissions?.bulkAssignRecords.contacts,
					},
					{
						id: sourceIds.companies,
						name: "Companies",
						value: permissions?.bulkAssignRecords.companies,
					},
					{
						id: sourceIds.deals,
						name: "Deals",
						value: permissions?.bulkAssignRecords.deals,
					},
					{
						id: sourceIds.products,
						name: "Products",
						value: permissions?.bulkAssignRecords.products,
					},
				],
			},
		},
		{
			id: actionsIds.cloneRecords,
			name: "Clone Records",
			access: permissions?.cloneRecords.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: sourceIds.contacts,
						name: "Contacts",
						value: permissions?.cloneRecords.contacts,
					},
					{
						id: sourceIds.companies,
						name: "Companies",
						value: permissions?.cloneRecords.companies,
					},
					{
						id: sourceIds.deals,
						name: "Deals",
						value: permissions?.cloneRecords.deals,
					},
					{
						id: sourceIds.products,
						name: "Products",
						value: permissions?.cloneRecords.products,
					},
				],
			},
		},
		{
			id: actionsIds.bulkUpdateRecords,
			name: "Bulk Update Records",
			access: permissions?.bulkUpdateRecords.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: sourceIds.contacts,
						name: "Contacts",
						value: permissions?.bulkUpdateRecords.contacts,
					},
					{
						id: sourceIds.companies,
						name: "Companies",
						value: permissions?.bulkUpdateRecords.companies,
					},
					{
						id: sourceIds.deals,
						name: "Deals",
						value: permissions?.bulkUpdateRecords.deals,
					},
					{
						id: sourceIds.products,
						name: "Products",
						value: permissions?.bulkUpdateRecords.products,
					},
				],
			},
		},
		{
			id: actionsIds.restoreRecords,
			name: "Restore Records",
			access: permissions?.restoreRecords.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: sourceIds.contacts,
						name: "Contacts",
						value: permissions?.restoreRecords.contacts,
					},
					{
						id: sourceIds.companies,
						name: "Companies",
						value: permissions?.restoreRecords.companies,
					},
					{
						id: sourceIds.deals,
						name: "Deals",
						value: permissions?.restoreRecords.deals,
					},
					{
						id: sourceIds.products,
						name: "Products",
						value: permissions?.restoreRecords.products,
					},
				],
			},
		},
		{
			id: actionsIds.mergeRecords,
			name: "Merge Records",
			access: permissions?.mergeRecords.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: sourceIds.contacts,
						name: "Contacts",
						value: permissions?.mergeRecords.contacts,
					},
					{
						id: sourceIds.companies,
						name: "Companies",
						value: permissions?.mergeRecords.companies,
					},
					{
						id: sourceIds.deals,
						name: "Deals",
						value: permissions?.mergeRecords.deals,
					},
				],
			},
		},
		{
			id: actionsIds.exportRecords,
			name: "Export Records",
			access: permissions?.exportRecords.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: sourceIds.contacts,
						name: "Contacts",
						value: permissions?.exportRecords.contacts,
					},
					{
						id: sourceIds.companies,
						name: "Companies",
						value: permissions?.exportRecords.companies,
					},
					{
						id: sourceIds.deals,
						name: "Deals",
						value: permissions?.exportRecords.deals,
					},
					{
						id: sourceIds.products,
						name: "Products",
						value: permissions?.exportRecords.products,
					},
					{
						id: sourceIds.activities,
						name: "Activities",
						value: permissions?.exportRecords.activities,
					},
				],
			},
		},
		{
			id: actionsIds.accessView,
			name: "Access View",
			access: permissions?.accessView.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: sourceIds.contacts,
						name: "Contacts",
						value: permissions?.accessView.contacts,
					},
					{
						id: sourceIds.companies,
						name: "Companies",
						value: permissions?.accessView.companies,
					},
					{
						id: sourceIds.deals,
						name: "Deals",
						value: permissions?.accessView.deals,
					},
					{
						id: sourceIds.products,
						name: "Products",
						value: permissions?.accessView.products,
					},
				],
			},
		},
		{
			id: actionsIds.shareView,
			name: "Share View",
			access: permissions?.shareView.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: sourceIds.contacts,
						name: "Contacts",
						value: permissions?.shareView.contacts,
					},
					{
						id: sourceIds.companies,
						name: "Companies",
						value: permissions?.shareView.companies,
					},
					{
						id: sourceIds.deals,
						name: "Deals",
						value: permissions?.shareView.deals,
					},
					{
						id: sourceIds.products,
						name: "Products",
						value: permissions?.shareView.products,
					},
				],
			},
		},
		{
			id: actionsIds.importRecords,
			name: "Import Records",
			access: permissions?.importRecords.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: sourceIds.contacts,
						name: "Contacts",
						value: permissions?.importRecords.contacts,
					},
					{
						id: sourceIds.companies,
						name: "Companies",
						value: permissions?.importRecords.companies,
					},
					{
						id: sourceIds.deals,
						name: "Deals",
						value: permissions?.importRecords.deals,
					},
					{
						id: sourceIds.products,
						name: "Products",
						value: permissions?.importRecords.products,
					},
					{
						id: sourceIds.activities,
						name: "Activities",
						value: permissions?.importRecords.activities,
					},
				],
			},
		},
		{
			id: actionsIds.limitForCreatingRecordsThroughImport,
			name: "Limit for Creating Record through import",
			access: permissions?.limitForCreatingRecordsThroughImport.access,
			permissions: {
				type: type.input,
				id: "permittedLimit",
				value: permissions?.limitForCreatingRecordsThroughImport
					.permittedLimit,
				placeHolder: "Records Per Day",
				noDataMessage: "No Limited Specified",
			},
		},
		{
			id: actionsIds.limitForUpdatingRecordsThroughImport,
			name: "Limit for Updating Record through import",
			access: permissions?.limitForUpdatingRecordsThroughImport.access,
			permissions: {
				type: type.input,
				id: "permittedLimit",
				value: permissions?.limitForUpdatingRecordsThroughImport
					.permittedLimit,
				placeHolder: "Records Per Day",
				noDataMessage: "No Limited Specified",
			},
		},
		{
			id: actionsIds.accessRecycleBin,
			name: "Access Recycle bin",
			access: permissions?.accessRecycleBin.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: sourceIds.contacts,
						name: "Contacts",
						value: permissions?.accessRecycleBin.contacts,
					},
					{
						id: sourceIds.companies,
						name: "Companies",
						value: permissions?.accessRecycleBin.companies,
					},
					{
						id: sourceIds.deals,
						name: "Deals",
						value: permissions?.accessRecycleBin.deals,
					},
					{
						id: sourceIds.products,
						name: "Products",
						value: permissions?.accessRecycleBin.products,
					},
				],
			},
		},
	];
};
