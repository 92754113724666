import React, { useMemo, useState } from "react";
import {
	Box,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import {
	DwarfButton,
	TableCellText,
	TableHeaderLabel,
} from "../../.././styles/twozo";
import DeleteDialog from "../.././Elements/DeleteDialog";
import Menu from "../.././Elements/Menu";
import { default as UserProfileImage } from "../../.././assets/images/contact/profilePicture.png";
import { getFormattedDateMonthYearAndTime } from "../../.././utils/DateUtils";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../.././utils/notification/notificationConfig";
import { notificationMessage } from "../../.././utils/notification/notificationMessages";
import { getIconByName } from "../../.././utils/icon";
import {
	useDeleteImport,
	useImportHistoryList,
	useImportHistoryDetailsList,
	useRevertImport,
	useStopImport,
} from "../../.././hooks/services/import";
import ConfirmDialog from "../.././Elements/ConfirmDialog";

export default function History(props) {
	const { continueToMapping, continueToPreview } = props;
	const theme = useTheme();

	// revert, stop, delete mutations
	const revertMutation = useRevertImport();
	const deleteMutation = useDeleteImport();
	const stopMutation = useStopImport();

	const [
		isRevertConfirmationDialogOpened,
		setIsRevertConfirmationDialogOpened,
	] = useState(false);
	const [
		isDeleteConfirmationDialogOpened,
		setIsDeleteConfirmationDialogOpened,
	] = useState(false);
	const [isStopConfirmationDialogOpened, setIsStopConfirmationDialogOpened] =
		useState(false);

	const [importDetailsMenuElement, setImportDetailsMenuElement] =
		useState(null);
	const isDetailsDialogOpened = Boolean(importDetailsMenuElement);

	// imported file state
	const importedFileState = {
		uploaded: "UPLOADED",
		fieldsMapped: "FIELDS_MAPPED",
	};

	// imported file status
	const importedFileStatus = {
		pending: "Pending",
		importing: "Importing",
		finished: "Finished",
	};

	const [importedFileId, setImportedFileId] = useState(null);
	const [importedFileObject, setImportedFileObject] = useState({});

	const { data: importedFileHistoryData } = useImportHistoryList();
	const { data: importedFileStatistics, isLoading } =
		useImportHistoryDetailsList(importedFileId);

	const openHistoryDetailDialog = (event, importedFileId) => {
		setImportDetailsMenuElement(event.currentTarget);
		setImportedFileId(importedFileId);
	};

	const closeHistoryDetailDialog = () => {
		setImportDetailsMenuElement(null);
	};

	const handleOpenRevertConfirmationDialog = () => {
		setIsRevertConfirmationDialogOpened(true);
	};

	const handleCloseRevertConfirmationDialog = () => {
		setIsRevertConfirmationDialogOpened(false);
	};

	const handleOpenDeleteConfirmationDialog = () => {
		setIsDeleteConfirmationDialogOpened(true);
	};

	const handleOpenStopConfirmationDialog = () => {
		setIsStopConfirmationDialogOpened(true);
	};

	const handleCloseDeleteConfirmationDialog = () => {
		setIsDeleteConfirmationDialogOpened(false);
	};

	const handleCloseStopConfirmationDialog = () => {
		setIsStopConfirmationDialogOpened(false);
	};

	const deleteImportedFile = (importedFileId) => {
		handleOpenDeleteConfirmationDialog();
		setImportedFileId(importedFileId);
	};

	const revertImportedFile = (importedFileId) => {
		handleOpenRevertConfirmationDialog();
		setImportedFileId(importedFileId);
	};

	const stopImportedFile = (historyData) => {
		setImportedFileObject(historyData);
		handleOpenStopConfirmationDialog();
	};

	const confirmToRevertImport = () => {
		revertMutation.mutate(importedFileId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.importReverted,
				});
				handleCloseRevertConfirmationDialog();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});
	};

	const confirmToStopImport = () => {
		stopMutation.mutate(importedFileObject.id, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.importStopped,
				});
				handleCloseStopConfirmationDialog();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});
	};

	const confirmToDeleteImport = () => {
		deleteMutation.mutate(importedFileId, {
			onSuccess: () => {
				enqueueSnackbar({
					title: "Success!",
					variant: notificationVariants.error,
					message: notificationMessage.importDeleted,
				});
				handleCloseDeleteConfirmationDialog();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleMappingOrPreview = (historyData) => {
		if (historyData.state === importedFileState.uploaded) {
			continueToMapping(historyData.id);
		} else if (historyData.state === importedFileState.fieldsMapped) {
			continueToPreview(historyData.id);
		}
	};

	const getStatusCellView = (historyData) => {
		switch (historyData?.status) {
			case importedFileStatus.pending:
				return displayPendingStatus(historyData);
			case importedFileStatus.finished:
				return (
					historyData?.revertAllowed &&
					displayFinishedStatus(historyData)
				);
			case importedFileStatus.importing:
				return displayImportingStatus(historyData);
			default:
				<></>;
		}
	};

	const displayPendingStatus = (historyData) => {
		return (
			<Stack direction="row" spacing={1}>
				<DwarfButton
					variant="contained"
					disableElevation
					onClick={() => handleMappingOrPreview(historyData)}
				>
					Continue
				</DwarfButton>

				<DwarfButton
					variant="contained"
					color="secondary"
					disableElevation
					onClick={() => deleteImportedFile(historyData.id)}
				>
					Discard
				</DwarfButton>
			</Stack>
		);
	};

	const displayFinishedStatus = (historyData) => {
		return (
			<TableCellText
				fontWeight={500}
				color={theme.palette.secondary.contrastText}
				onClick={() => revertImportedFile(historyData.id)}
			>
				Revert
			</TableCellText>
		);
	};

	const displayImportingStatus = (historyData) => {
		return (
			<Box>
				<DwarfButton
					variant="contained"
					color="secondary"
					disableElevation
					disabled={stopMutation.isLoading}
					onClick={() => stopImportedFile(historyData)}
				>
					Cancel
				</DwarfButton>
			</Box>
		);
	};

	const displayImportedFileDetail = (historyData) => {
		return (
			<TableCellText
				fontWeight={500}
				color={theme.palette.secondary.contrastText}
				onClick={(event) =>
					openHistoryDetailDialog(event, historyData.id)
				}
			>
				Details
			</TableCellText>
		);
	};

	const isSkipFileDownloadVisible = useMemo(() => {
		if (Array.isArray(importedFileStatistics)) {
			return importedFileStatistics.some((statistic) => {
				return statistic.name === "Skipped" && statistic.count > 0;
			});
		}
	}, [importedFileStatistics]);

	const handleDownloadSkipFile = () => {
		window.location.href = `/api/import/skipfile/download?id=${importedFileId}`;
	};

	return (
		<React.Fragment>
			{/* Revert menu */}
			<ConfirmDialog
				open={isRevertConfirmationDialogOpened}
				onCancel={handleCloseRevertConfirmationDialog}
				onConfirm={confirmToRevertImport}
				disableConfirmButton={revertMutation.isLoading}
				title="Are you sure you want to revert the changes?"
				subtitle="All the contacts imported through this file and its data will be deleted from all the references."
				confirmButtonText="Revert"
			/>

			{/* Stop import menu */}
			<ConfirmDialog
				open={isStopConfirmationDialogOpened}
				onCancel={handleCloseStopConfirmationDialog}
				onConfirm={confirmToStopImport}
				disableConfirmButton={stopMutation.isLoading}
				title="Are you sure you want to cancel import?"
				subtitle={`Canceling the ongoing import of ${importedFileObject?.attachment?.fileName} and reverting the data already imported from this file.`}
				confirmButtonText="Cancel and revert"
				cancelButtonText="Continue importing"
			/>

			{/* Discard import menu */}
			<DeleteDialog
				open={isDeleteConfirmationDialogOpened}
				onCancel={handleCloseDeleteConfirmationDialog}
				onDelete={confirmToDeleteImport}
				disableConfirmButton={deleteMutation.isLoading}
				title="Are you sure you want to discard this import?"
				subtitle="The file you have uploaded for importing will be permanently deleted."
				confirmButtonText="Discard"
			/>

			<Menu
				minWidth="200px"
				anchorEl={importDetailsMenuElement}
				open={isDetailsDialogOpened}
				onClose={closeHistoryDetailDialog}
				anchorOrigin={{
					vertical: "center",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "center",
					horizontal: "right",
				}}
			>
				{isLoading ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="100px"
					>
						<CircularProgress size="20px" />
					</Stack>
				) : (
					importedFileStatistics?.map(
						(statistic, statisticsIndex) => (
							<Stack
								key={statisticsIndex}
								direction="row"
								alignItems="center"
								justifyContent="space-between"
								px={2}
								py={1}
							>
								<Typography
									fontSize={13}
									color={
										statistic.count === 0
											? "rgba(0, 0, 0, 0.6)"
											: "#000"
									}
								>
									{statistic.name}
								</Typography>

								<Typography
									fontSize={13}
									color={
										statistic.count === 0
											? "rgba(0, 0, 0, 0.6)"
											: "#000"
									}
								>
									{statistic.count}
								</Typography>
							</Stack>
						)
					)
				)}

				{isSkipFileDownloadVisible && (
					<Box>
						<Divider />

						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							px={2}
							pt={1.5}
							pb={1}
							style={{
								cursor: "pointer",
							}}
							onClick={handleDownloadSkipFile}
						>
							<Typography
								fontSize="14px"
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
							>
								Download Skip File
							</Typography>
						</Stack>
					</Box>
				)}
			</Menu>

			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Stack justifyContent="center" alignItems="center" p={2}>
					<Typography fontSize={14}>
						Import sessions are kept for 30 days; however, reverting
						is only possible within the initial 48 hours following
						the completion of the import process. Imports can be
						reverted only by admins who have permission to delete
						the type of data that was imported (for example,
						permission to delete deals.)
					</Typography>
				</Stack>

				<Box
					mx={1}
					mt={0}
					mb={8}
					style={{
						flex: "1 1 auto",
						minHeight: "0px",
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
						overflowY: "auto",
					}}
				>
					<TableContainer>
						<Table sx={{ minWidth: 650 }} size="small">
							<TableHead>
								<TableRow>
									<TableCell>
										<TableHeaderLabel>
											Date & Time
										</TableHeaderLabel>
									</TableCell>

									<TableCell>
										<TableHeaderLabel>
											File Name
										</TableHeaderLabel>
									</TableCell>

									<TableCell>
										<TableHeaderLabel>
											User
										</TableHeaderLabel>
									</TableCell>

									<TableCell>
										<TableHeaderLabel>
											Type
										</TableHeaderLabel>
									</TableCell>

									<TableCell>
										<TableHeaderLabel>
											Status
										</TableHeaderLabel>
									</TableCell>

									<TableCell></TableCell>

									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!!importedFileHistoryData &&
								importedFileHistoryData.length > 0 ? (
									importedFileHistoryData.map((history) => (
										<TableRow key={history.id}>
											<TableCell>
												<TableCellText>
													{getFormattedDateMonthYearAndTime(
														history.createdTime
													)}
												</TableCellText>
											</TableCell>

											<TableCell>
												<TableCellText
													color={
														theme.palette.secondary
															.contrastText
													}
												>
													{
														history?.attachment
															?.fileName
													}
												</TableCellText>
											</TableCell>

											<TableCell>
												<Stack
													direction="row"
													alignItems="center"
													spacing={1}
												>
													<Box display="flex">
														<img
															alt="user"
															src={
																UserProfileImage
															}
															width="20px"
															height="20px"
														/>
													</Box>

													<TableCellText>
														{history?.user.name}
													</TableCellText>
												</Stack>
											</TableCell>

											<TableCell>
												{history.status ===
												"Finished" ? (
													<Stack
														direction="row"
														alignItems="center"
													>
														{history.sources.map(
															(source, index) => (
																<Stack
																	key={index}
																	direction="row"
																	alignItems="center"
																>
																	<Box display="flex">
																		<IconButton
																			size="small"
																			style={{
																				padding:
																					"small",
																			}}
																		>
																			{getIconByName(
																				source.toLowerCase()
																			)(
																				18,
																				18,
																				"#000",
																				0.6
																			)}
																		</IconButton>
																	</Box>

																	<Box
																		hidden={
																			history
																				.sources
																				.length -
																				1 ===
																			index
																		}
																		sx={{
																			opacity: 0.6,
																		}}
																	>
																		-
																	</Box>
																</Stack>
															)
														)}
													</Stack>
												) : null}
											</TableCell>

											<TableCell>
												<TableCellText>
													{history.status}
												</TableCellText>
											</TableCell>

											<TableCell>
												{history.status ===
													importedFileStatus.finished &&
													displayImportedFileDetail(
														history
													)}
											</TableCell>

											<TableCell>
												{getStatusCellView(history)}
											</TableCell>
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell
											colSpan={7}
											sx={{
												border: "none",
											}}
										>
											<Stack
												alignItems="center"
												justifyContent="center"
												style={{
													height: "52vh",
													width: "100%",
												}}
											>
												<Typography
													fontSize={14}
													fontWeight={400}
													style={{
														opacity: 0.6,
													}}
												>
													You have not imported any
													files yet.
												</Typography>
											</Stack>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		</React.Fragment>
	);
}
