import {
	Box,
	Button,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { TableHeaderLabel, twozoStyles } from "../../../../styles/twozo";
import ContactImage from "../../../../assets/images/contact/unknownContact.png";
import AddReviewDuplicates from "./ReviewDuplicates";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import {
	useDismissDuplicateContact,
	useDuplicateContactList,
} from "../../../../hooks/services/contact/contactDuplicates";
import ConfirmDialog from "../../ConfirmDialog";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import Can from "../../../Auth/Can";
import { PERMISSIONS } from "../../../../utils/Auth";
import CanNot from "../../../Auth/CanNot";
import UnAuthorized from "../../../Auth/UnAuthorized";

export default function DuplicateContact() {
	const classes = twozoStyles();
	const [isReviewDialogOpened, setIsReviewDialogOpened] = useState(false);
	const [primaryContact, setPrimaryContact] = useState({});
	const [isDismissDialogOpened, setIsDismissDialogOpened] = useState(false);

	const notificationTitle = "Success";

	const dismissDuplicateContactMutation = useDismissDuplicateContact();

	const { data: contactDuplicateList } = useDuplicateContactList();

	const toggleReviewDialog = (primaryContact) => {
		setIsReviewDialogOpened(
			(isReviewDialogOpened) => !isReviewDialogOpened
		);
		setPrimaryContact(primaryContact);
	};

	const openDismissDialog = (primaryContact) => {
		setIsDismissDialogOpened(true);
		setPrimaryContact(primaryContact);
	};

	const closeDismissDialog = () => {
		setIsDismissDialogOpened(false);
	};

	const dismissDuplicateContact = () => {
		dismissDuplicateContactMutation.mutate(
			{
				id: primaryContact.id,
				fieldName: primaryContact.email ? "emails" : "phones",
				value: primaryContact.email
					? primaryContact.email
					: primaryContact.phone,
			},
			{
				onSuccess: () => {
					closeDismissDialog();
					enqueueSnackbar({
						title: notificationTitle,
						variant: notificationVariants.error,
						message: notificationMessage.contactDismissMessage,
					});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: {
						boxShadow: "-3px 0px 14px rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
					},
				}}
				open={isReviewDialogOpened}
				onOpen={toggleReviewDialog}
				onClose={toggleReviewDialog}
				ModalProps={{
					BackdropProps: {
						style: { backgroundColor: "transparent" },
					},
				}}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddReviewDuplicates
						primaryContactId={primaryContact?.id}
						onClose={toggleReviewDialog}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<ConfirmDialog
				open={isDismissDialogOpened}
				onCancel={closeDismissDialog}
				onConfirm={dismissDuplicateContact}
				title="Are you sure you want to dismiss these contacts?"
				subtitle="The duplicates found will no longer be suggested for merge."
				confirmButtonText="Dismiss"
			/>

			<Can permission={PERMISSIONS.contact.mergeRecords}>
				<Box px={3}>
					<Typography fontSize={16} fontWeight={400}>
						Contacts with the same email or phone fields are
						considered duplicates.
					</Typography>
				</Box>

				<Box pt={1}>
					<TableContainer>
						<Table sx={{ minWidth: 650 }} size="small">
							<TableHead>
								<TableRow>
									<TableCell>
										<TableHeaderLabel ml={1}>
											Primary Contact
										</TableHeaderLabel>
									</TableCell>
									<TableCell>
										<TableHeaderLabel m={1}>
											Secondary Contact
										</TableHeaderLabel>
									</TableCell>
									<TableCell>
										<TableHeaderLabel ml={1}>
											Actions
										</TableHeaderLabel>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{contactDuplicateList?.map(
									(contactData, index) => (
										<TableRow key={index}>
											<TableCell>
												<Box p={1}>
													<Stack
														display="flex"
														alignItems="center"
														direction="row"
														spacing={2}
													>
														<img
															style={{
																borderRadius:
																	"100%",
															}}
															src={ContactImage}
															alt="contactImage"
															width="48px"
															height="48px"
														/>
														<Stack>
															<Typography
																fontSize={14}
															>
																{
																	contactData
																		?.primary
																		?.fullName
																}
															</Typography>
															<Typography
																color="rgba(0, 0, 0, 0.6)"
																fontSize={14}
															>
																{
																	contactData
																		?.primary
																		?.email
																}
																{
																	contactData
																		?.primary
																		?.phone
																}
															</Typography>
														</Stack>
													</Stack>
												</Box>
											</TableCell>
											<TableCell>
												<Box p={1}>
													<Stack
														display="flex"
														alignItems="center"
														direction="row"
														spacing={2}
													>
														<img
															style={{
																borderRadius:
																	"100%",
															}}
															src={ContactImage}
															alt="contactImage"
															width="48px"
															height="48px"
														/>
														<Stack>
															<Typography
																fontSize={14}
															>
																{
																	contactData
																		?.secondary
																		?.fullName
																}
															</Typography>
															<Typography
																color="rgba(0, 0, 0, 0.6)"
																fontSize={14}
															>
																{
																	contactData
																		?.secondary
																		?.email
																}
																{
																	contactData
																		?.secondary
																		?.phone
																}
															</Typography>
														</Stack>
													</Stack>
												</Box>
											</TableCell>
											<TableCell>
												<Stack
													p={1}
													direction="row"
													spacing={2}
													alignItems="center"
												>
													<Button
														variant="contained"
														disableElevation
														onClick={() =>
															toggleReviewDialog(
																contactData?.primary
															)
														}
													>
														Review
													</Button>
													<Button
														variant="contained"
														color="secondary"
														disableElevation
														onClick={() =>
															openDismissDialog(
																contactData.primary
															)
														}
													>
														Dismiss
													</Button>
												</Stack>
											</TableCell>
										</TableRow>
									)
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Can>

			<CanNot permission={PERMISSIONS.contact.mergeRecords}>
				<Stack height="50vh">
					<UnAuthorized />
				</Stack>
			</CanNot>
		</React.Fragment>
	);
}
