import { getRootKey } from "../../rootKey";

const teamsKey = "teams";

// teams query key
export const getTeamsKey = () => {
	return [...getRootKey(), teamsKey];
};

// query keys
export const teamsListKey = [...getTeamsKey(), "teamList"];

export const teamTableDataKey = [...getTeamsKey(), "teamTableData"];

export const getTeamTableDataKey = (fieldToSort) => {
	if (Object.keys(fieldToSort).length > 0) {
		return [...getTeamsKey(), "teamTableData", fieldToSort];
	} else return [...getTeamsKey(), "teamTableData"];
};

// query key methods
export const getAllTeamUsersListKey = (teamId) => {
	return [...getTeamsKey(), String(teamId), "teamUserList"];
};

export const getTeamUsersListKey = (teamId, isManager) => {
	return [...getAllTeamUsersListKey(teamId), String(isManager)];
};

export const getTeamDataKey = (teamId) => {
	return [...getTeamsKey(), String(teamId), "teamData"];
};
