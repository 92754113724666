import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import { Tooltip, hexToRgba, twozoStyles } from "../../../styles/twozo";
import SummaryMenu from "./Menu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as CompanyIcon } from "../../../assets/icons/company";
import { default as ThumbsUpIcon } from "../../../assets/icons/thumbsUp";
import { default as ThumbsDownIcon } from "../../../assets/icons/thumbsDown";
import { default as EyeIcon } from "../../../assets/icons/eye";
import { default as EyeOffIcon } from "../../../assets/icons/eyeOff";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import DealTimeline from "./Timeline";
import DealConversations from "./Conversations";
import DealActivities from "./Activities";
import DealFiles from "./Files";
import DealNotes from "./Notes";
import DealRelatedContacts from "./RelatedContacts";
import AddActivity from "../../Summary/Activities/AddActivity";
import Menu from "../../Elements/Menu";
import DealStagesTimeline from "./DealStagesTimeline";
import Overview from "./Overview";
import SummaryCardView from "../../Elements/SummaryCardView";
import EditDeal from "../EditDeal";
import { default as SingleContactIcon } from "../../../assets/icons/singleContact";
import { default as CloseIcon } from "../../../assets/icons/close";
import { default as EditIcon } from "../../../assets/icons/edit";
import {
	getDealCompletedActivityKey,
	getDealToDoActivityKey,
} from "../../../utils/queryKeys";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { modules } from "../../../utils/common/ModulesName";
import DealSummaryLoader from "../../Elements/Loader/SummaryLoader/DealSummaryLoader";
import { useModuleName } from "../../../hooks/modules";
import {
	useDealSummaryData,
	usePartialUpdateDeal,
} from "../../../hooks/services/deal";
import { summaryViews } from "../../../utils/summaryUtils";
import { dealState } from "../../../utils/dealUtils";
import { useActivityTypeList } from "../../../hooks/services/activityType";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";
import { useDealFieldPermissions } from "../../../hooks/modules/auth/deal";
import FieldPermissionContextProvider from "../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import DealSummaryProfileEdit from "./DealSummaryProfileEdit";
import { SummaryContextProvider } from "../../Summary/SummaryContext/SummaryContextProvider";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { useAuth } from "../../../hooks/auth";
import TooltipComponent from "../../Elements/TooltipComponent";

export default function DealsSummary() {
	let { dealId } = useParams();
	const parsedDealId = parseInt(dealId);
	const theme = useTheme();
	const classes = twozoStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const { getModuleName } = useModuleName();
	const { isUserAllowedFor } = useAuth();

	// permission
	const hasEditPermission = isUserAllowedFor(PERMISSIONS.deal.edit);

	const updateMutation = usePartialUpdateDeal(parsedDealId);

	const DealsStagesTimelineRef = useRef(null);
	const detailsCardContainerRef = useRef(null);
	const profileContainerRef = useRef(null);

	const [view, setView] = useState(summaryViews.overview);
	const [openAddActivityDialog, setOpenAddActivityDialog] = useState(false);
	const [
		hideDetailsCardContainerLeftScrollButton,
		setHideDetailsCardContainerLeftScrollButton,
	] = useState(true);
	const [
		hideDetailsCardContainerRightScrollButton,
		setHideDetailsCardContainerRightScrollButton,
	] = useState(false);
	const [filterTimelineMenuElement, setFilterTimelineMenuElement] =
		useState(null);
	const isTimelineFilterMenuOpened = Boolean(filterTimelineMenuElement);
	const [filterActivitiesMenuElement, setFilterActivitiesMenuElement] =
		useState(null);
	const isActivitiesFilterMenuOpened = Boolean(filterActivitiesMenuElement);
	const [isEditFormDialogOpen, setIsEditFormDialogOpen] = useState(false);
	const [summaryFields, setSummaryFields] = useState([]);
	const [summarySubFields, setSummarySubFields] = useState([]);
	const [profile, setProfile] = useState({});
	const dealToDoActivityKey = getDealToDoActivityKey(parsedDealId);
	const dealCompletedActivityKey = getDealCompletedActivityKey(parsedDealId);
	const [timelineIdsToFilter, setTimelineIdsToFilter] = useState([]);
	const [activityTypeIdsToFilter, setActivityTypeIdsToFilter] = useState([]);
	const [hideEmptyFields, setHideEmptyFields] = useState(false);
	const [isProfileEditIconVisible, setIsProfileEditIconVisible] =
		useState(false);

	const [profileMenuElement, setProfileMenuElement] = useState(null);
	const isProfileEditMenuOpened = Boolean(profileMenuElement);

	const onMouseOverProfile = () => {
		setIsProfileEditIconVisible(true);
	};

	const onMouseOutProfile = () => {
		setIsProfileEditIconVisible(false);
	};

	const timelineFilterList = [
		{
			id: "calls",
			name: "Calls",
		},
		{
			id: "emails",
			name: "Emails",
		},
		{
			id: "notes",
			name: "Notes",
		},
		{
			id: "activity",
			name: "Activities",
		},
		{
			id: "file",
			name: "Files",
		},
		{
			id: "updates",
			name: "Updates",
		},
	];
	const viewName = getModuleName(modules.DEAL);
	const dealFieldPermissions = useDealFieldPermissions();

	const selectedTimelineFilterCount =
		timelineIdsToFilter.length > 0 ? timelineIdsToFilter.length : "";
	const selectedActivityFilterCount =
		activityTypeIdsToFilter.length > 0
			? activityTypeIdsToFilter.length
			: "";

	const isActiveActivityType = true;
	const { data: activityTypeList } =
		useActivityTypeList(isActiveActivityType);

	const {
		status: dealSummaryStatus,
		data: dealSummaryData,
		isLoading: isDealSummaryLoading,
	} = useDealSummaryData(dealId, hideEmptyFields);

	useEffect(() => {
		if (dealSummaryStatus === "success") {
			if (dealSummaryData.profile) {
				setProfile(dealSummaryData.profile);
			}
			if (dealSummaryData.subFields) {
				setSummarySubFields(dealSummaryData.subFields);
			}
			if (dealSummaryData.summary) {
				setSummaryFields(dealSummaryData.summary);
			}
		}
	}, [dealSummaryStatus, dealSummaryData]);

	const contactId = profile?.primaryContact?.value?.id;
	const companyId = profile?.company?.value?.id;
	const isDealOpen =
		profile?.status?.value === dealState.open &&
		profile?.rottingDays?.value;
	const isDealWon = profile?.status?.value === dealState.won;
	const isDealLost = profile?.status?.value === dealState.lost;

	const toggleFilterTimelineMenu = (timelineFilterId) => {
		if (timelineIdsToFilter.includes(timelineFilterId)) {
			setTimelineIdsToFilter((timelineIdsToFilter) =>
				timelineIdsToFilter.filter((filterId) => {
					return filterId !== timelineFilterId;
				})
			);
		} else {
			setTimelineIdsToFilter([...timelineIdsToFilter, timelineFilterId]);
		}
	};

	const toggleFilterActivitiesMenu = (activityTypeId) => {
		if (activityTypeIdsToFilter.includes(activityTypeId)) {
			setActivityTypeIdsToFilter((activityTypeIdsToFilter) =>
				activityTypeIdsToFilter.filter((id) => {
					return id !== activityTypeId;
				})
			);
		} else {
			setActivityTypeIdsToFilter([
				...activityTypeIdsToFilter,
				activityTypeId,
			]);
		}
	};

	const toggleAddActivityDialog = () => {
		setOpenAddActivityDialog(
			(openAddCompanyDialog) => !openAddCompanyDialog
		);
	};

	const handleViewToggleButtonGroup = (_, type) => {
		if (type) {
			setView(type);
		}
	};

	const openFilterTimelineMenu = (event) => {
		setFilterTimelineMenuElement(event.currentTarget);
	};

	const closeFilterTimelineMenu = () => {
		setFilterTimelineMenuElement(null);
	};

	const openFilterActivitiesMenu = (event) => {
		setFilterActivitiesMenuElement(event.currentTarget);
	};

	const closeFilterActivitiesMenu = () => {
		setFilterActivitiesMenuElement(null);
	};

	const scroll = (scrollOffset) => {
		const { scrollLeft, scrollWidth, clientWidth } =
			detailsCardContainerRef.current;
		if (scrollLeft + clientWidth + scrollOffset >= scrollWidth) {
			setHideDetailsCardContainerRightScrollButton(true);
		} else if (scrollLeft + clientWidth + scrollOffset <= clientWidth) {
			setHideDetailsCardContainerLeftScrollButton(true);
		} else {
			setHideDetailsCardContainerRightScrollButton(false);
			setHideDetailsCardContainerLeftScrollButton(false);
		}

		detailsCardContainerRef.current.scrollLeft += scrollOffset;
	};

	useEffect(() => {
		if (detailsCardContainerRef.current) {
			const { scrollLeft, scrollWidth, clientWidth } =
				detailsCardContainerRef.current;
			if (scrollLeft <= 0) {
				setHideDetailsCardContainerLeftScrollButton(false);
			}
			if (scrollLeft + clientWidth >= scrollWidth) {
				setHideDetailsCardContainerRightScrollButton(false);
			}
		}
	}, [hideEmptyFields]);

	const toggleEditFormDialog = () => {
		setIsEditFormDialogOpen((openEditFormDialog) => !openEditFormDialog);
	};

	const handleClearTimelineFilter = () => {
		setTimelineIdsToFilter([]);
		closeFilterTimelineMenu();
	};

	const handleClearActivityFilter = () => {
		setActivityTypeIdsToFilter([]);
		closeFilterActivitiesMenu();
	};

	const navigateToContactDetails = () => {
		const contactId = profile?.primaryContact?.value?.id;
		navigate(`/contacts/${contactId}`);
	};
	const navigateToCompanyDetails = () => {
		navigate(`/companies/${companyId}`);
	};

	const navigateAllDealPage = () => {
		const allDealPagePath = location?.state?.prevPath
			? location.state.prevPath
			: "/deals";
		navigate(allDealPagePath);
	};

	const toggleFieldVisibility = () => {
		setHideEmptyFields((hideEmptyFields) => !hideEmptyFields);
	};

	const getHoverredEyeIconContent = () => {
		return hideEmptyFields ? "Hide Empty Fields" : "Show Empty Fields";
	};

	const openProfileEditMenu = () => {
		setProfileMenuElement(profileContainerRef.current);
	};

	const closeProfileEditMenu = () => {
		setProfileMenuElement(null);
	};

	const updateSummaryField = (summaryData, onClose) => {
		updateMutation.mutate(summaryData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			{/* profile edit menu */}
			<Menu
				open={isProfileEditMenuOpened}
				onClose={closeProfileEditMenu}
				anchorEl={profileMenuElement}
			>
				<DealSummaryProfileEdit
					profile={profile}
					dealId={parsedDealId}
					onClose={closeProfileEditMenu}
					subFields={summarySubFields}
				/>
			</Menu>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditFormDialogOpen}
				onOpen={toggleEditFormDialog}
				onClose={toggleEditFormDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditDeal dealId={dealId} onClose={toggleEditFormDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddActivityDialog}
				onOpen={toggleAddActivityDialog}
				onClose={toggleAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={toggleAddActivityDialog}
						dealId={parsedDealId}
						companyId={companyId}
						contactIds={[contactId]}
						toDoActivityKey={dealToDoActivityKey}
						completedActivityKey={dealCompletedActivityKey}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				minWidth="200px"
				anchorEl={filterTimelineMenuElement}
				open={isTimelineFilterMenuOpened}
				onClose={closeFilterTimelineMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{timelineFilterList.map((timelineFilterData) => (
					<MenuItem
						key={timelineFilterData.id}
						onClick={() =>
							toggleFilterTimelineMenu(timelineFilterData.id)
						}
						style={{ height: "40px" }}
					>
						<Stack
							direction="row"
							spacing={1.5}
							alignItems="center"
						>
							<Checkbox
								checked={timelineIdsToFilter.includes(
									timelineFilterData.id
								)}
							/>

							<Typography fontSize={13}>
								{timelineFilterData.name}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<Menu
				minWidth="200px"
				anchorEl={filterActivitiesMenuElement}
				open={isActivitiesFilterMenuOpened}
				onClose={closeFilterActivitiesMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{activityTypeList?.map((activityType) => (
					<MenuItem
						key={activityType.id}
						onClick={() =>
							toggleFilterActivitiesMenu(activityType.id)
						}
						style={{ height: "40px" }}
					>
						<Stack
							direction="row"
							spacing={1.5}
							alignItems="center"
						>
							<Checkbox
								checked={activityTypeIdsToFilter.includes(
									activityType?.id
								)}
							/>

							<Typography fontSize={13}>
								{activityType.name}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			{isDealSummaryLoading ? (
				<DealSummaryLoader moduleName={`All ${viewName}`} />
			) : (
				<Box>
					<Box className={classes.menuBar}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							height="100%"
							px={1}
						>
							<Stack
								direction="row"
								spacing={1.5}
								alignItems="center"
							>
								<Box
									style={{ cursor: "pointer" }}
									onClick={navigateAllDealPage}
								>
									<Typography
										fontWeight={600}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{`All ${viewName}`}
									</Typography>
								</Box>

								<Box
									style={{
										transform: "rotate(-90deg)",
									}}
								>
									{DropDownIcon(16, 16)}
								</Box>

								<TooltipComponent title={profile?.title?.value}>
									<Typography
										style={{
											fontWeight: 500,
											maxWidth: "390px",
										}}
										noWrap
									>
										{profile?.title?.value}
									</Typography>
								</TooltipComponent>
							</Stack>

							<SummaryMenu
								dealId={parsedDealId}
								toggleEditFormDialog={toggleEditFormDialog}
								contactId={contactId}
								dealStatus={profile?.status?.value}
							/>
						</Stack>
					</Box>

					<Box
						px={3}
						onMouseOver={onMouseOverProfile}
						onMouseLeave={onMouseOutProfile}
					>
						<Stack
							direction="row"
							alignItems="flex-start"
							spacing={3}
						>
							<Box
								sx={{
									backgroundColor: hexToRgba(
										theme.palette.primary.main,
										0.12
									),
									borderRadius: "8px",
									px: 2,
									py: 1,
								}}
							>
								<Typography
									fontSize={18}
									fontWeight={500}
									color={theme.palette.secondary.contrastText}
									style={{ cursor: "pointer" }}
								>
									{profile.dealValue?.value?.CURRENCY?.symbol}{" "}
									{profile.dealValue?.value?.PRICE?.value}
								</Typography>
							</Box>

							<Stack>
								<Stack
									direction="row"
									spacing={1}
									alignItems="center"
								>
									<TooltipComponent
										title={profile?.title?.value}
									>
										<Typography
											style={{
												fontSize: 18,
												fontWeight: 600,
												cursor: "pointer",
												maxWidth: "442px",
											}}
											noWrap
										>
											{profile?.title?.value}
										</Typography>
									</TooltipComponent>

									{isDealOpen ? (
										<Box
											sx={{
												backgroundColor: hexToRgba(
													theme.palette.error.main,
													0.1
												),
												padding: "4px",
												borderRadius: "6px",
											}}
										>
											<Typography
												fontWeight={500}
												fontSize={13}
												color={theme.palette.error.main}
											>
												{`Rottings for ${profile.rottingDays.value} Days`}
											</Typography>
										</Box>
									) : null}

									{isDealWon ? (
										<Stack
											direction="row"
											spacing={0.6}
											alignItems="center"
											sx={{
												backgroundColor:
													theme.palette.primary.main,
												padding: "4px",
												borderRadius: "6px",
											}}
										>
											<Box display="flex">
												{ThumbsUpIcon(16, 16, "#fff")}
											</Box>

											<Typography
												fontWeight={500}
												fontSize={13}
												color="#fff"
											>
												Won
											</Typography>
										</Stack>
									) : null}

									{isDealLost ? (
										<Stack
											direction="row"
											spacing={0.6}
											alignItems="center"
											sx={{
												backgroundColor:
													theme.palette.error.main,
												padding: "4px",
												borderRadius: "6px",
											}}
										>
											<Box display="flex">
												{ThumbsDownIcon(16, 16, "#fff")}
											</Box>

											<Typography
												fontWeight={500}
												fontSize={13}
												color="#fff"
											>
												Lost
											</Typography>
										</Stack>
									) : null}

									<Box display="flex" width="22px">
										{isProfileEditIconVisible &&
										hasEditPermission ? (
											<IconButton
												style={{ padding: 0 }}
												onClick={openProfileEditMenu}
											>
												{EditIcon(
													19,
													19,
													theme.palette.primary.main
												)}
											</IconButton>
										) : null}
									</Box>
								</Stack>

								<Stack
									ref={profileContainerRef}
									direction="row"
									spacing={2}
									color={theme.palette.secondary.contrastText}
								>
									<Can permission={PERMISSIONS.company.view}>
										<Stack
											direction="row"
											alignItems="center"
											spacing={0.5}
											style={{ cursor: "pointer" }}
											onClick={navigateToCompanyDetails}
										>
											{CompanyIcon(
												16,
												16,
												theme.palette.primary.main
											)}

											<TooltipComponent
												title={
													profile?.company?.value
														?.name
												}
											>
												<Typography
													style={{
														fontSize: 15,
														fontWeight: 500,
														maxWidth: "367px",
													}}
													noWrap
												>
													{profile?.company?.value
														? profile?.company
																?.value?.name
														: "Add Company"}
												</Typography>
											</TooltipComponent>
										</Stack>
									</Can>

									<Can permission={PERMISSIONS.contact.view}>
										<Stack
											direction="row"
											alignItems="center"
											spacing={0.5}
											style={{ cursor: "pointer" }}
											onClick={navigateToContactDetails}
										>
											{SingleContactIcon(
												16,
												16,
												theme.palette.primary.main
											)}

											<TooltipComponent
												title={
													profile?.primaryContact
														?.value?.fullName
												}
											>
												<Typography
													style={{
														fontSize: 14,
														fontWeight: 500,
														maxWidth: "369px",
													}}
													noWrap
												>
													{profile?.primaryContact
														?.value
														? profile
																?.primaryContact
																?.value
																?.fullName
														: null}
												</Typography>
											</TooltipComponent>
										</Stack>
									</Can>
								</Stack>
							</Stack>
						</Stack>
					</Box>

					<Box pt={2} px={1}>
						<DealStagesTimeline
							dealId={parsedDealId}
							ref={DealsStagesTimelineRef}
						/>
					</Box>

					<Box
						style={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						{!hideDetailsCardContainerLeftScrollButton && (
							<Stack alignItems="center" justifyContent="center">
								<IconButton
									onClick={() => scroll(-280)}
									style={{
										padding: "4px",
									}}
								>
									<Box style={{ transform: "rotate(90deg)" }}>
										{DropDownIcon(16, 16, "#666")}
									</Box>
								</IconButton>
							</Stack>
						)}

						<Box
							style={{
								flex: "1 1 auto",
								minWidth: 0,
								overflowX: "hidden",
								scrollBehavior: "smooth",
							}}
							ref={detailsCardContainerRef}
						>
							<Stack direction="row" spacing={2} p={2}>
								<FieldPermissionContextProvider
									value={dealFieldPermissions}
									source={modules.DEAL}
								>
									<SummaryContextProvider
										hasEditPermission={hasEditPermission}
										entityId={parsedDealId}
										source={modules.DEAL}
										updateSummaryField={updateSummaryField}
									>
										<SummaryCardView
											summaryFields={summaryFields}
											summarySubFields={summarySubFields}
										/>
									</SummaryContextProvider>
								</FieldPermissionContextProvider>
							</Stack>
						</Box>

						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							spacing={1}
							px={1}
						>
							<Tooltip
								title={getHoverredEyeIconContent()}
								placement="top"
							>
								<IconButton
									style={{ padding: "4px" }}
									onClick={toggleFieldVisibility}
								>
									{hideEmptyFields
										? EyeOffIcon(16, 16, "#666")
										: EyeIcon(16, 16, "#666")}
								</IconButton>
							</Tooltip>

							{!hideDetailsCardContainerRightScrollButton && (
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="center"
									spacing={1}
								>
									<Divider
										orientation="vertical"
										style={{ height: "28px" }}
									/>

									<IconButton
										onClick={() => scroll(280)}
										style={{ padding: "4px" }}
									>
										<Box
											style={{
												transform: "rotate(-90deg)",
											}}
										>
											{DropDownIcon(16, 16, "#666")}
										</Box>
									</IconButton>
								</Stack>
							)}
						</Stack>
					</Box>

					<Box px={2}>
						<Divider />
					</Box>

					<Box p={1}>
						<Grid
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="stretch"
							spacing={1}
						>
							<Grid item xs={12} md={9}>
								<Box className={classes.detailsContainer}>
									<Stack direction="row" spacing={3}>
										<ToggleButtonGroup
											value={view}
											onChange={
												handleViewToggleButtonGroup
											}
										>
											<ToggleButton
												value={summaryViews.overview}
											>
												Overview
											</ToggleButton>
											<ToggleButton
												value={summaryViews.timeline}
											>
												Timeline
											</ToggleButton>
											<ToggleButton
												value={
													summaryViews.conversations
												}
											>
												Conversations
											</ToggleButton>
											<ToggleButton
												value={summaryViews.activities}
											>
												Activities
											</ToggleButton>
										</ToggleButtonGroup>

										{view === summaryViews.timeline && (
											<ButtonGroup
												variant="contained"
												disableElevation
												color="secondary"
												sx={{
													"& .MuiButtonGroup-grouped:not(:last-child)":
														{
															borderRight: "none",
															borderRadius:
																!selectedTimelineFilterCount >
																0
																	? "8px"
																	: "8px 0px 0px 8px",
														},
												}}
											>
												<Button
													onClick={
														openFilterTimelineMenu
													}
													endIcon={DropDownIcon(
														16,
														16,
														theme.palette.secondary
															.contrastText
													)}
												>
													Filter Timeline{" "}
													{selectedTimelineFilterCount >
													0
														? `(${selectedTimelineFilterCount})`
														: ""}
												</Button>

												<Divider
													orientation="vertical"
													sx={{
														display:
															!selectedTimelineFilterCount >
																0 && "none",
														height: "95%",
													}}
												/>

												<Button
													onClick={
														handleClearTimelineFilter
													}
													sx={{
														display:
															!selectedTimelineFilterCount >
																0 && "none",
													}}
												>
													{CloseIcon(
														19,
														19,
														theme.palette.secondary
															.contrastText
													)}
												</Button>
											</ButtonGroup>
										)}

										{view === summaryViews.activities && (
											<Stack direction="row" spacing={2}>
												<Button
													variant="contained"
													startIcon={AddIcon(
														16,
														16,
														theme.palette.secondary
															.contrastText
													)}
													color="secondary"
													onClick={
														toggleAddActivityDialog
													}
													disableElevation
												>
													Activity
												</Button>

												<ButtonGroup
													variant="contained"
													disableElevation
													color="secondary"
													sx={{
														"& .MuiButtonGroup-grouped:not(:last-child)":
															{
																borderRight:
																	"none",
																borderRadius:
																	selectedActivityFilterCount >
																	0
																		? "8px 0px 0px 8px"
																		: "8px",
															},
													}}
												>
													<Button
														onClick={
															openFilterActivitiesMenu
														}
														endIcon={DropDownIcon(
															16,
															16,
															theme.palette
																.secondary
																.contrastText
														)}
													>
														Filter Activities{" "}
														{selectedActivityFilterCount >
														0
															? `(${selectedActivityFilterCount})`
															: ""}
													</Button>

													<Divider
														orientation="vertical"
														sx={{
															display:
																!selectedActivityFilterCount >
																	0 && "none",
															height: "95%",
														}}
													/>

													<Button
														onClick={
															handleClearActivityFilter
														}
														sx={{
															display:
																!selectedActivityFilterCount >
																	0 && "none",
															padding: 0,
														}}
													>
														{CloseIcon(
															19,
															19,
															theme.palette
																.secondary
																.contrastText
														)}
													</Button>
												</ButtonGroup>
											</Stack>
										)}
									</Stack>

									<Box pt={1} mx={-1}>
										{(function () {
											switch (view) {
												case summaryViews.overview:
													return (
														<Overview
															dealId={
																parsedDealId
															}
														/>
													);
												case summaryViews.timeline:
													return (
														<DealTimeline
															dealId={
																parsedDealId
															}
															timelineIdsToFilter={
																timelineIdsToFilter
															}
														/>
													);
												case summaryViews.conversations:
													return (
														<DealConversations
															dealId={
																parsedDealId
															}
														/>
													);
												case summaryViews.activities:
													return (
														<DealActivities
															dealId={
																parsedDealId
															}
															activityTypeIdsToFilter={
																activityTypeIdsToFilter
															}
														/>
													);
												default:
													return null;
											}
										})()}
									</Box>
								</Box>
							</Grid>

							<Grid item xs={12} md={3}>
								<Stack spacing={1}>
									<Box className={classes.detailsContainer}>
										<DealNotes dealId={parsedDealId} />
									</Box>

									<Box className={classes.detailsContainer}>
										<DealFiles dealId={parsedDealId} />
									</Box>

									<Can permission={PERMISSIONS.contact.view}>
										<Box
											className={classes.detailsContainer}
										>
											<DealRelatedContacts
												dealId={parsedDealId}
											/>
										</Box>
									</Can>
								</Stack>
							</Grid>
						</Grid>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
