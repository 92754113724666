import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	CircularProgress,
	MenuItem,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import { default as AddIcon } from "../../../../../../.././assets/icons/add";
import { default as DropDownIcon } from "../../../../../../.././assets/icons/dropDownCentered";
import ImportFieldList from "../../../ImportCustomField/ImportFieldList";
import { useModuleList } from "../../../../../../.././hooks/services/common";
import {
	useFieldTypeList,
	useImportFieldsList,
	useImportModuleList,
} from "../../../../../../.././hooks/services/import";
import { getIconByName } from "../../../../../../.././utils/icon";
import ToggleButtonGroup from "../../../../../.././Elements/ToggleButtonGroup";
import MenuSearchBar from "../../../../../.././Elements/MenuSearchBar";

export default function FieldOption(props) {
	const {
		onClickMappedField,
		closeImportFieldsMenu,
		getFilteredFieldsOrSubFields,
	} = props;
	const [sourceType, setSourceType] = useState("Contact");

	// query call
	const { data: fieldTypeData } = useFieldTypeList();
	const { data: moduleListData } = useModuleList();
	const { data: modulesList } = useImportModuleList();
	const {
		status: fieldsStatus,
		data: fieldsData,
		isLoading,
	} = useImportFieldsList(sourceType);

	const theme = useTheme();
	const [isSubFieldOpen, setIsSubFieldOpen] = useState(false);
	const [selectedSubFieldIndex, setSelectedSubFieldIndex] = useState(null);
	const [fieldData, setFieldData] = useState([]);
	const [subFields, setSubFields] = useState([]);
	const [filteredFields, setFilteredFields] = useState([]);
	const [newFieldsCreated, setNewFieldsCreated] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [isCustomFieldCreationEnabled, setIsCustomFieldCreationEnabled] =
		useState(false);

	const getSourceId = () => {
		let module = moduleListData?.find(
			(module) =>
				module.sourceName.toLowerCase() === sourceType.toLowerCase()
		);
		if (module) {
			return module?.sourceId;
		}
	};

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setSourceType(type);
		}
		setSearchInput("");
		setNewFieldsCreated([]);
	};

	const handleSearchField = (event) => {
		const { value } = event.target;

		if (value !== "") {
			let result = fieldData.filter((field) => {
				return field.name.toLowerCase().startsWith(value.toLowerCase());
			});
			setFilteredFields(result);
		} else {
			setFilteredFields(fieldData);
		}
		setSearchInput(value);
	};

	useEffect(() => {
		if (fieldsStatus === "success") {
			if (fieldsData.fields) {
				let filteredFields = getFilteredFieldsData(fieldsData.fields);
				setFieldData(filteredFields);
				setFilteredFields(filteredFields);
			}

			if (fieldsData.subFields) {
				let filteredSubFields = getFilteredSubFieldsData(
					fieldsData.subFields
				);
				setSubFields(filteredSubFields);
			}
			setIsCustomFieldCreationEnabled(fieldsData.isRegisteredSource);
		}
	}, [fieldsStatus, fieldsData, sourceType]);

	const getFilteredFieldsData = (fields) => {
		let isField = true;
		let filteredFields = getFilteredFieldsOrSubFields(
			fields,
			sourceType,
			isField
		);

		return filteredFields;
	};

	const getFilteredSubFieldsData = (subFields) => {
		let filteredSubFields = getFilteredFieldsOrSubFields(subFields);

		return filteredSubFields;
	};

	const handleMappingFieldData = (field, isSubField, subField) => {
		let selectedFieldObject = {
			field: field,
			source: sourceType,
			subField: subField,
			isSubField: isSubField,
		};

		onClickMappedField(selectedFieldObject);
		closeImportFieldsMenu();
	};

	const isFieldCreationEnabled = () => {
		return newFieldsCreated.length > 0;
	};

	const handleCustomField = () => {
		if (isFieldCreationEnabled()) {
			return;
		}

		setNewFieldsCreated((newFieldsCreated) => [
			...newFieldsCreated,
			{
				sourceId: getSourceId(),
				fieldName: "",
				id: "new_field" + (newFieldsCreated.length + 1),
				type: `${fieldTypeData[0].name}`,
				isFieldNotSaved: true,
				config: {},
			},
		]);
	};

	const handleOpenSubField = () => {
		setIsSubFieldOpen((isSubFieldOpen) => !isSubFieldOpen);
	};

	const handleClickFieldOrSubField = (field, index) => {
		if (Array.isArray(subFields[field.type])) {
			handleOpenSubField();
			setSelectedSubFieldIndex(index);
		} else {
			handleMappingFieldData(field);
		}
	};

	const updateNewFieldsCreated = (modifiedNewFieldsCreated) => {
		setNewFieldsCreated(modifiedNewFieldsCreated);
	};

	const isSubFieldSelected = (index) => {
		return isSubFieldOpen && selectedSubFieldIndex === index;
	};

	const fieldsWithSubfields = useMemo(() => {
		return filteredFields.filter((filteredField) =>
			subFields[filteredField?.type]
				? subFields[filteredField.type].length > 0
					? filteredField
					: null
				: filteredField
		);
	}, [filteredFields, subFields]);

	const isFilteredFieldsNotEmpty =
		!!fieldsWithSubfields && fieldsWithSubfields.length > 0;

	return (
		<React.Fragment>
			<Box pt={1}>
				<Box style={{ textAlign: "center" }}>
					<ToggleButtonGroup
						value={sourceType}
						onChange={handleToggleButtonGroup}
					>
						{modulesList?.map(
							(module) =>
								module.isEnabled && (
									<ToggleButton
										value={module.sourceName}
										key={module.sourceId}
									>
										{getIconByName(
											module.sourceName.toLowerCase()
										)(
											20,
											20,
											sourceType === module.sourceName
												? "#fff"
												: "#666666"
										)}
									</ToggleButton>
								)
						)}
					</ToggleButtonGroup>
				</Box>

				<Box p={0.5} pt={1}>
					<MenuSearchBar
						value={searchInput}
						onChange={handleSearchField}
					/>
				</Box>
			</Box>

			{isCustomFieldCreationEnabled && isFilteredFieldsNotEmpty && (
				<MenuItem
					style={{
						minHeight: "40px",
						paddingLeft: "24px",
						opacity: isFieldCreationEnabled() > 0 && 0.6,
					}}
					onClick={handleCustomField}
				>
					{AddIcon(20, 20, theme.palette.secondary.contrastText)}

					<Typography
						fontSize={13}
						pl={1}
						color={theme.palette.secondary.contrastText}
					>
						Custom Field
					</Typography>
				</MenuItem>
			)}

			<Box sx={{ maxHeight: "190px", overflowY: "auto" }}>
				{!!newFieldsCreated && newFieldsCreated.length > 0 && (
					<ImportFieldList
						sourceType={sourceType}
						fieldTypeData={fieldTypeData}
						newFieldsCreated={newFieldsCreated}
						updateNewFieldsCreated={updateNewFieldsCreated}
					/>
				)}

				{isLoading ? (
					<Stack
						justifyContent="center"
						alignItems="center"
						height="150px"
						width="100%"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : isFilteredFieldsNotEmpty ? (
					fieldsWithSubfields.map((field, index) => (
						<Box key={field.id}>
							<MenuItem
								style={{ minHeight: "40px" }}
								onClick={() =>
									handleClickFieldOrSubField(field, index)
								}
							>
								<Stack
									key={index}
									direction="row"
									alignItems="center"
									width="350px"
								>
									<Typography
										fontSize={13}
										pl={2}
										pr={0.7}
										noWrap
									>
										{field?.name}
									</Typography>

									{Array.isArray(subFields[field.type]) ? (
										<Box
											display="flex"
											style={{
												transform:
													isSubFieldSelected(index) &&
													"rotate(180deg)",
											}}
										>
											{DropDownIcon(16, 16, "#666666")}
										</Box>
									) : null}

									<Typography
										hidden={!field.isCustomField}
										pl={1}
										style={{
											fontSize: "12px",
											color: "rgb(46, 168, 113)",
										}}
									>
										( Custom Field )
									</Typography>
								</Stack>
							</MenuItem>

							{isSubFieldSelected(index)
								? Array.isArray(subFields[field.type]) &&
									subFields[field.type].map((subField) => (
										<MenuItem
											key={subField.id}
											sx={{ height: "40px" }}
											onClick={() =>
												handleMappingFieldData(
													field,
													true,
													subField
												)
											}
										>
											<Typography
												fontSize={13}
												pl={5}
												pr={1}
											>
												{subField?.name}
											</Typography>
										</MenuItem>
									))
								: null}
						</Box>
					))
				) : (
					<Stack
						justifyContent="center"
						alignItems="center"
						height="200px"
						width="100%"
						fontSize={14}
					>
						No results found
					</Stack>
				)}
			</Box>
		</React.Fragment>
	);
}
