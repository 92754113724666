import { Typography, useTheme } from "@mui/material";
import {
	SummaryFieldName,
	Tooltip,
	twozoStyles,
} from "../../../../../../../styles/twozo";
import { useMemo, useState } from "react";
import Menu from "../../../../../Menu";
import AddressFieldEditForm from "../../../../../FieldEditFormComponents/AddressFieldEditForm";
import { useSummaryContext } from "../../../../../../Summary/SummaryContext";

export default function AddressFieldValue(params) {
	const { summaryField, subFields } = params;

	// summary context
	const { updateSummaryField, entityId, hasEditPermission } =
		useSummaryContext();

	const theme = useTheme();
	const classes = twozoStyles();
	const [openToolTip, setOpenToolTip] = useState(false);

	const [addressMenuElement, setAddressMenuElement] = useState(null);
	const openAddressMenu = Boolean(addressMenuElement);

	const handleOpenTooltip = (event) => {
		setOpenToolTip(event.target.scrollWidth > event.target.clientWidth);
	};

	const openEditForm = (event) => {
		if (!hasEditPermission) {
			return;
		}

		setAddressMenuElement(event.currentTarget);
	};

	const closeAddressMenu = () => {
		setAddressMenuElement(null);
	};

	const modifiedAddressStructure = useMemo(() => {
		const { value } = summaryField;

		if (!value) {
			return { id: entityId };
		}

		const { ADDRESS_LINE1, ADDRESS_LINE2, CITY, COUNTRY, ZIPCODE, STATE } =
			value;

		return {
			[CITY?.id]: CITY?.value,
			[STATE?.id]: STATE?.value,
			[ZIPCODE?.id]: ZIPCODE?.value,
			[COUNTRY?.id]: COUNTRY?.value,
			[ADDRESS_LINE1?.id]: ADDRESS_LINE1?.value,
			[ADDRESS_LINE2?.id]: ADDRESS_LINE2?.value,
		};
	}, [summaryField, entityId]);

	const getAddressLabel = () => {
		return ` ${
			summaryField?.value?.ADDRESS_LINE1.value
				? summaryField.value?.ADDRESS_LINE1?.value
				: ""
		} ${
			summaryField?.value?.ADDRESS_LINE2.value
				? ", " + summaryField?.value?.ADDRESS_LINE2?.value
				: ""
		}`;
	};

	const getAddressFieldLabel = () => {
		return summaryField?.value && getAddressLabel() ? (
			<Tooltip
				title={getAddressLabel()}
				open={openToolTip}
				placement="bottom"
			>
				<SummaryFieldName
					className={classes.summaryData}
					onMouseOver={handleOpenTooltip}
					onMouseLeave={() => setOpenToolTip(false)}
					onClick={openEditForm}
				>
					{getAddressLabel()}
				</SummaryFieldName>
			</Tooltip>
		) : summaryField.config.readOnly ? (
			<SummaryFieldName style={{ opacity: 0.6 }}>-</SummaryFieldName>
		) : (
			<Typography
				width="150px"
				sx={{
					"&:hover": {
						borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
					},
				}}
				fontSize="14px"
				fontWeight={500}
				style={{
					color: theme.palette.secondary.contrastText,
					opacity: "0.4",
				}}
				onClick={openEditForm}
			>
				Click To Add
			</Typography>
		);
	};

	const getAddressFieldRequest = (addressData) => {
		return {
			[summaryField?.fieldId]: {
				...addressData,
			},
			id: entityId,
		};
	};

	const handleSave = (addressData) => {
		let requestData = getAddressFieldRequest(addressData);
		updateSummaryField(requestData, closeAddressMenu);
	};

	return (
		<>
			{getAddressFieldLabel()}

			<Menu
				open={openAddressMenu}
				anchorEl={addressMenuElement}
				onClose={closeAddressMenu}
				sx={{ marginTop: "3px", minWidth: "400px" }}
			>
				<AddressFieldEditForm
					subFields={subFields}
					field={summaryField}
					onClose={closeAddressMenu}
					value={modifiedAddressStructure}
					onSave={handleSave}
				/>
			</Menu>
		</>
	);
}
