import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	createTeam,
	deleteTeam,
	getTeamData,
	getTeamUsersList,
	getTeamsList,
	getTeamsTableData,
	updateTeam,
} from "../../../../api/userManagement/teams/teamsApi";
import {
	getAllTeamUsersListKey,
	getTeamDataKey,
	getTeamTableDataKey,
	getTeamUsersListKey,
	teamsListKey,
} from "../../../../utils/queryKeys/userManagement/teams";

const useTeamsTableData = (fieldToSort) => {
	return useQuery(
		getTeamTableDataKey(fieldToSort),
		() => getTeamsTableData(fieldToSort),
		{
			select: (data) => data.teams,
		}
	);
};

const useTeamsList = () => {
	return useQuery(teamsListKey, () => getTeamsList(), {
		select: (teamList) => teamList?.options,
	});
};

const useTeamUsersList = ({ teamId, isManager }) => {
	let teamUsersListKey = getTeamUsersListKey(teamId, isManager);
	return useQuery(
		teamUsersListKey,
		() => getTeamUsersList(teamId, isManager),
		{
			select: (data) => data.users,
			enabled: !!teamId,
		}
	);
};

const useTeamData = (teamId) => {
	let teamDataKey = getTeamDataKey(teamId);
	return useQuery(teamDataKey, () => getTeamData(teamId), {
		select: (data) => data.team,
		enabled: !!teamId,
	});
};

const useInvalidateTeamsList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getTeamTableDataKey());
	};
};

const useInvalidateTeamUsersList = (teamId) => {
	let queryClient = useQueryClient();
	let teamUsersListKey = getAllTeamUsersListKey(teamId);
	return () => {
		queryClient.invalidateQueries(teamUsersListKey);
	};
};

const useInvalidateTeamData = (teamId) => {
	let queryClient = useQueryClient();
	let teamDataKey = getTeamDataKey(teamId);
	return () => {
		queryClient.invalidateQueries(teamDataKey);
	};
};

const useCreateTeam = () => {
	let invalidateTeamsList = useInvalidateTeamsList();
	return useMutation(createTeam, {
		onSuccess: () => {
			invalidateTeamsList();
		},
	});
};

const useUpdateTeam = (teamId) => {
	let invalidateTeamsList = useInvalidateTeamsList();
	let invalidateTeamData = useInvalidateTeamData(teamId);
	let invalidatePartialUsersList = useInvalidateTeamUsersList(teamId);
	return useMutation(updateTeam, {
		onSuccess: () => {
			invalidateTeamsList();
			invalidateTeamData();
			invalidatePartialUsersList();
		},
	});
};

const useDeleteTeam = () => {
	let invalidateTeamsList = useInvalidateTeamsList();
	return useMutation(deleteTeam, {
		onSuccess: () => {
			invalidateTeamsList();
		},
	});
};

export {
	useTeamsTableData,
	useTeamUsersList,
	useCreateTeam,
	useUpdateTeam,
	useDeleteTeam,
	useTeamData,
	useTeamsList,
};
