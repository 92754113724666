import { apiClient } from "../..";
import { getMailUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";

export const getPlaceHolderSources = async () => {
	let requestData = getPlaceHolderSourcesRequest();
	return await postEmailTemplateApi(requestData);
};

export const getPlaceHolderFields = async (sourceName) => {
	let requestData = getPlaceHolderFieldsRequest(sourceName);
	return await postEmailTemplateApi(requestData);
};

export const createTemplate = async (createTemplateData) => {
	let requestData = getCreateTemplateRequest(createTemplateData);
	return await postEmailTemplateApi(requestData);
};

export const updateTemplate = async (updateTemplateData) => {
	let requestData = getUpdateTemplateRequest(updateTemplateData);
	return await postEmailTemplateApi(requestData);
};

export const cloneEmailTemplate = async (templateId) => {
	let requestData = getCloneEmailTemplateRequest(templateId);
	return await postEmailTemplateApi(requestData);
};

export const deleteEmailTemplate = async (templateId) => {
	let requestData = getDeleteEmailTemplateRequest(templateId);
	return await postEmailTemplateApi(requestData);
};

export const bulkDeleteEmailTemplate = async (templateIds) => {
	let requestData = getBulkDeleteEmailTemplateRequest(templateIds);
	return await postEmailTemplateApi(requestData);
};

export const changeEmailTemplateToFolder = async ({
	sourceId,
	destinationId,
	templateIds,
}) => {
	let requestData = getChangeEmailTemplateToFolderRequest(
		sourceId,
		destinationId,
		templateIds
	);
	return await postEmailTemplateApi(requestData);
};

export const getEmailTemplateData = async (templateId) => {
	let requestData = getEmailTemplateDataRequest(templateId);
	return await postEmailTemplateApi(requestData);
};

const getPlaceHolderSourcesRequest = () => {
	return {
		type: "getPlaceHolderSources",
		data: {},
	};
};

const getPlaceHolderFieldsRequest = (sourceName) => {
	return {
		type: "getPlaceHolderFields",
		data: {
			source: sourceName,
		},
	};
};

const getCreateTemplateRequest = (createTemplateData) => {
	return {
		type: "create",
		data: {
			...createTemplateData,
		},
	};
};

const getUpdateTemplateRequest = (updateTemplateData) => {
	return {
		type: "update",
		data: {
			...updateTemplateData,
		},
	};
};

const getCloneEmailTemplateRequest = (templateId) => {
	return {
		type: "clone",
		data: {
			id: templateId,
		},
	};
};

const getDeleteEmailTemplateRequest = (templateId) => {
	return {
		type: "delete",
		data: {
			id: templateId,
		},
	};
};

const getBulkDeleteEmailTemplateRequest = (templateIds) => {
	return {
		type: "bDelete",
		data: {
			ids: templateIds,
		},
	};
};

const getChangeEmailTemplateToFolderRequest = (
	sourceId,
	destinationId,
	templateIds
) => {
	return {
		type: "changeFolder",
		data: {
			id: sourceId,
			targetFolder: destinationId,
			templates: templateIds,
		},
	};
};

const getEmailTemplateDataRequest = (templateId) => {
	return {
		type: "get",
		data: {
			id: templateId,
		},
	};
};

const postEmailTemplateApi = async (requestData) => {
	let emailTemplateUrl = getEmailTemplateUrl();
	let response = await apiClient.post(emailTemplateUrl, requestData);
	assertError(response);
	return response.data.data;
};

const getEmailTemplateUrl = () => {
	return getMailUrl() + "/template/mgmt";
};
