import React from "react";
import { TableCellText } from "../../../../styles/twozo";
import { getStatusProperties } from "../../../../utils/ActivitiesUtils";
import { useTheme } from "@mui/material";

export default function Text(props) {
	const { data, rowState } = props;
	const theme = useTheme();

	if (!data) {
		return null;
	}

	return (
		<React.Fragment>
			<TableCellText
				color={getStatusProperties(rowState, theme).textColor}
				style={{
					textDecorationLine:
						getStatusProperties(rowState).textDecorationLine,
				}}
			>
				{data}
			</TableCellText>
		</React.Fragment>
	);
}
