import React, { useState } from "react";
import {
	Box,
	Divider,
	IconButton,
	Stack,
	ToggleButton,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../.././assets/icons/close";
import ToggleButtonGroup from ".././Elements/ToggleButtonGroup";
import Import from "./Import";
import History from "./History";
import { useModuleName } from "../../hooks/modules";

const styles = {
	toggleComponent: {
		flex: "1 1 auto",
		minHeight: 0,
	},
};

//import type
const viewTypes = {
	import: "import",
	history: "history",
};

export default function ImportModule(props) {
	const { onClose, moduleName } = props;
	const theme = useTheme();
	const [importedFileStateId, setImportedFileStateId] = useState(null);
	const [activeStep, setActiveStep] = useState(0);
	const [view, setView] = useState(viewTypes.import);

	const { getModuleName } = useModuleName();
	const importModuleName = getModuleName(moduleName);

	const handleViewChange = (_, type) => {
		if (type) {
			setView(type);
		}
	};

	const handleActiveStep = (activeStep) => {
		setActiveStep(activeStep);
	};

	const continueToMapping = (importedFileId) => {
		setView(viewTypes.import);
		setImportedFileStateId(importedFileId);
		setActiveStep(1);
	};

	const continueToPreview = (importedFileId) => {
		setView(viewTypes.import);
		setImportedFileStateId(importedFileId);
		setActiveStep(2);
	};

	return (
		<React.Fragment>
			<Stack
				justifyContent="flex-start"
				alignItems="stretch"
				style={{
					height: "100%",
				}}
			>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					p={2}
				>
					<Box>
						<ToggleButtonGroup
							value={view}
							onChange={handleViewChange}
						>
							<ToggleButton value={viewTypes.import}>
								Import {importModuleName}
							</ToggleButton>

							<ToggleButton value={viewTypes.history}>
								History
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>

					<Box
						style={{
							position: "absolute",
							right: theme.spacing(3),
						}}
					>
						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(25, 25, theme.palette.primary.main)}
						</IconButton>
					</Box>
				</Stack>

				<Divider />

				<>
					<Box
						style={styles.toggleComponent}
						hidden={view === viewTypes.history}
					>
						<Import
							onClose={onClose}
							activeStep={activeStep}
							moduleName={moduleName}
							handleActiveStep={handleActiveStep}
							importedFileStateId={importedFileStateId}
						/>
					</Box>

					<Box
						style={styles.toggleComponent}
						hidden={view === viewTypes.import}
					>
						<History
							continueToMapping={continueToMapping}
							continueToPreview={continueToPreview}
						/>
					</Box>
				</>
			</Stack>
		</React.Fragment>
	);
}
