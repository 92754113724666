import { useGroupedFormFieldsData } from "../../../hooks/services/common/field";
import { removeFieldsWithEmptyValues } from "../../../utils/common";
import GroupFieldForm from "../../Elements/GroupFieldForm";

export default function GroupFieldEdit(params) {
	const { moduleName, onSave, onClose, fieldId, entityId } = params;

	const { data: groupFieldData, isLoading } = useGroupedFormFieldsData(
		moduleName,
		entityId,
		fieldId
	);

	const getGroupFieldRequest = (data) => {
		return {
			...data,
			id: entityId,
		};
	};

	const handleSave = (data) => {
		removeFieldsWithEmptyValues(data);
		let requestData = getGroupFieldRequest(data);
		onSave(requestData, onClose);
	};

	return (
		<>
			<GroupFieldForm
				fieldId={fieldId}
				onSave={handleSave}
				onClose={onClose}
				formData={groupFieldData}
				moduleName={moduleName}
				isGroupFieldDataLoading={isLoading}
			/>
		</>
	);
}
