import React, { useRef, useState } from "react";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import InfoIcon from "../../../assets/icons/info";
import CloseIcon from "../../../assets/icons/close";
import EmailView from "./EmailView";
import ThreadEmailView from "./ThreadEmailView";
import Popper from "@mui/material/Popper";
import MailInfo from "./MailInfo";

export default function ViewEmail(props) {
	const {
		mailData,
		onClose,
		threadId,
		mailListName,
		disableViewActions,
		viewerMode,
	} = props;
	const theme = useTheme();
	const mailViewRef = useRef();
	const [mailSubject, setMailSubject] = useState("");
	const [mailInfoElement, setMailInfoElement] = React.useState(null);
	const openMailInfo = Boolean(mailInfoElement);

	const isSingleThread = mailData?.emails?.length === 1;
	const singleThreadData = isSingleThread ? mailData?.emails?.[0] : "";

	const toggleMailInfo = () => {
		if (!disableViewActions) {
			setMailInfoElement(mailInfoElement ? null : mailViewRef.current);
		}
	};

	const handleMailSubject = (mailSubject) => {
		setMailSubject(mailSubject);
	};

	return (
		<React.Fragment>
			<Popper
				open={openMailInfo}
				anchorEl={mailInfoElement}
				placement="left-start"
				style={{ zIndex: theme.zIndex.drawer + 1 }}
			>
				<Box
					style={{
						width: "340px",
						height: "65vh",
						border: `1px solid ${theme.palette.divider}`,
						borderRadius: "8px 0px 0px 8px",
						backgroundColor: "#F4F5F5",
						overflowY: "auto",
					}}
					py={2}
					px={1}
					mt={2}
				>
					<MailInfo />
				</Box>
			</Popper>

			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
				}}
				pb={1}
				ref={mailViewRef}
			>
				{/*Header*/}
				<Box p={2}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={2}
					>
						<Typography
							fontWeight={600}
							sx={{
								opacity: disableViewActions ? 0.6 : 1,
								wordBreak: "break-word",
							}}
						>
							{mailSubject ? mailSubject : "(no subject)"}
						</Typography>

						<Stack direction="row" alignItems="center">
							{singleThreadData?.messageCategory !==
							"SCHEDULED" ? (
								<IconButton
									onClick={toggleMailInfo}
									sx={{
										opacity: disableViewActions ? 0.6 : 1,
									}}
								>
									{InfoIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>
							) : null}

							<IconButton
								onClick={onClose}
								style={{ padding: "0px" }}
							>
								{CloseIcon(22, 22, theme.palette.primary.main)}
							</IconButton>
						</Stack>
					</Stack>
				</Box>

				<Box
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "auto",
						opacity: disableViewActions ? 0.6 : 1,
					}}
				>
					{isSingleThread ? (
						<EmailView
							mailData={singleThreadData}
							handleMailSubject={handleMailSubject}
							disableViewActions={disableViewActions}
							onMailViewerClose={onClose}
							threadId={threadId}
							mailListName={mailListName}
							viewerMode={viewerMode}
						/>
					) : (
						<ThreadEmailView
							mails={mailData?.emails}
							handleMailSubject={handleMailSubject}
							disableViewActions={disableViewActions}
							threadId={threadId}
							mailListName={mailListName}
							viewerMode={viewerMode}
						/>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}
