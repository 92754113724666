import React, { useState } from "react";
import ToolBar from "../../../EmailComposer/EmailComposerUI/ToolBar";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalAutoLinkPlugin from "../../../EmailComposer/EmailComposerUI/Plugin/AutoLinkPlugin";
import LexicalClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { Box, Stack, useTheme } from "@mui/material";
import PlaceHoldersMenu from "../../../EmailComposer/EmailComposerUI/ToolBar/PlaceHoldersMenu";
import HtmlPlugin from "../../../EmailComposer/EmailComposerUI/Plugin/HtmlPlugin";

export default function TemplateInput(props) {
	const { initialTemplateContent } = props;
	const theme = useTheme();

	const [isPlaceHolderMenuOpened, setIsPlaceHolderMenuOpened] =
		useState(false);

	const openPlaceHolderMenu = () => {
		setIsPlaceHolderMenuOpened(true);
	};

	const closePlaceHolderMenu = () => {
		setIsPlaceHolderMenuOpened(false);
	};

	return (
		<React.Fragment>
			{/*Rich Text Editor*/}
			<Box>
				<ToolBar
					hiddenTemplateMenu={true}
					onClickPlaceHolder={openPlaceHolderMenu}
				/>
				<Stack direction="row">
					<Box flexGrow={1}>
						<RichTextPlugin
							contentEditable={
								<ContentEditable
									style={{
										height: "340px",
										padding: "12px",
										overflowY: "auto",
										outline: "none",
										fontSize: "14px",
										fontFamily: "Lexend",
									}}
								/>
							}
							ErrorBoundary={LexicalErrorBoundary}
						/>
						<TabIndentationPlugin />
						<HistoryPlugin />
						<LexicalAutoLinkPlugin />
						<LexicalClickableLinkPlugin />
						<HtmlPlugin initialHtml={initialTemplateContent} />
					</Box>
					<Box
						hidden={!isPlaceHolderMenuOpened}
						sx={{
							width: "200px",
							borderLeft: `1px solid ${theme.palette.divider}`,
							marginBottom: "-52px",
						}}
					>
						<PlaceHoldersMenu
							closePlaceHolderMenu={closePlaceHolderMenu}
						/>
					</Box>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
