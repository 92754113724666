import React from "react";
import AddForm from "../AddForm";

export default function AddUser(props) {
	const { onClose, selectedUserFilterId } = props;

	return (
		<React.Fragment>
			<AddForm
				onClose={onClose}
				isEditable={true}
				selectedUserFilterId={selectedUserFilterId}
			/>
		</React.Fragment>
	);
}
