import { getRootKey } from "../rootKey";

export const getDropdownKey = () => {
	return [...getRootKey(), "dropdownData"]; // Return the dropdown query key
};

// query keys

export const activityTypeIconKey = [...getRootKey(), "activityTypeIcon"];

export const modulesListKey = [...getRootKey(), "modulesList"];

// query key methods
export const getTableKey = (moduleName, filter) => {
	if (
		filter &&
		typeof filter === "object" &&
		Object.keys(filter)?.length > 0
	) {
		return [...getRootKey(), moduleName, "table", filter]; // Return the query key when the filter is applied
	}

	return [...getRootKey(), moduleName, "table"]; // Return the query key when the filter is not applied
};

export const getFormFieldsKey = (moduleName, view) => {
	return [...getRootKey(), moduleName, "formFields", view];
};

export const getUpdateFieldListKey = (moduleName) => {
	return [...getRootKey(), moduleName, "updateFieldList"];
};

export const getGroupedFormFieldsKey = (moduleName, id) => {
	return [...getRootKey(), moduleName, "groupedField", String(id)];
};

export const getGroupedFormFieldsDataKey = (source, entityId, fieldId) => {
	return [...getAllGroupFormFieldsDataKey(source, entityId), String(fieldId)];
};

export const getAllGroupFormFieldsDataKey = (source, entityId) => {
	return [...getRootKey(), source, String(entityId), "groupedFieldData"];
};

export const getDropdownFieldTypeKey = (sourceId) => {
	return [...getRootKey(), String(sourceId), "fieldType"];
};

export const getActivityDataKey = (activityId) => {
	return [...getRootKey(), String(activityId), "details"];
};

export const getDropdownKeyByFieldId = (fieldId) => {
	return [...getDropdownKey(), String(fieldId)];
};

export const getDependentDropdownKeyByFieldId = (
	fieldId,
	dependentValue = ""
) => {
	return [
		...getDropdownKeyByFieldId(fieldId),
		"dependent",
		String(dependentValue),
	];
};

export const getDropdownKeyByFieldName = (fieldName) => {
	return [...getDropdownKey(), fieldName]; // Return the dropdown query key by field name
};

export const getSalesOwnerDropdownKey = () => {
	return [...getDropdownKeyByFieldName("userList")];
};

export const getTagsDropdownKey = () => {
	return [...getDropdownKeyByFieldName("tagsList")];
};
