import { getRootKey } from "../../rootKey";

const territoryKey = "territory";

// territory query key
export const getTerritoryKey = () => {
	return [...getRootKey(), territoryKey];
};

// query keys
export const territoryListKey = [...getTerritoryKey(), "territoryList"];

export const getTerritoryListKey = (fieldToSort) => {
	if (Object.keys(fieldToSort).length > 0) {
		return [...getTerritoryKey(), "territoryList", fieldToSort];
	} else return [...getTerritoryKey(), "territoryList"];
};

export const territoryDropdownListKey = [
	...getTerritoryKey(),
	"territoryDropdownList",
];

// query key methods
export const getTerritoryUserListKey = (territoryId) => {
	return [...getTerritoryKey(), String(territoryId), "territoryUsersList"];
};

export const getTerritoryDataKey = (territoryId) => {
	return [...getTerritoryKey(), String(territoryId), "territoryData"];
};

export const getTeamUsersAndUsersListKey = () => {
	return [...getTerritoryKey(), "teamUsersAndUsersList"];
};
