import { getTableUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import { apiClient } from "..";

export const getTableData = async (table, config = {}, criteriaList) => {
	const url = getTableUrl(table);
	const tableRequestData = getTableRequestData(config, criteriaList);
	return await getTableDataApi(url, tableRequestData);
};

const getTableDataApi = async (url, requestData) => {
	let response = await apiClient.post(url, requestData);
	assertError(response);
	return response.data.data.table;
};

const getTableRequestData = (tableConfig, criteriaList) => {
	return {
		userId: 51,
		...tableConfig,
		...criteriaList,
	};
};
