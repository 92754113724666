import React, { useState } from "react";
import {
	SummaryFieldName,
	Tooltip,
	twozoStyles,
} from "../../../../../../../styles/twozo";
import { useSummaryContext } from "../../../../../../Summary/SummaryContext";
import SalesOwnerEditComponent from "../../../../../FieldEditFormComponents/SalesOwnerEditComponent";
import FieldEdit from "../../../../../FieldEdit";
import { Box, Typography, useTheme } from "@mui/material";

export default function SalesOwnerFieldValue(props) {
	const { summaryField } = props;

	const theme = useTheme();
	const classes = twozoStyles();

	// Summary Context:-
	const { updateSummaryField, entityId } = useSummaryContext();

	const [openToolTip, setOpenToolTip] = useState(false);

	const handleOpenTooltip = (event) => {
		setOpenToolTip(event.target.scrollWidth > event.target.clientWidth);
	};

	const styles = {
		inputContent: {
			width: "150px",
			"&:hover": { borderBottom: "1px solid rgba(0, 0, 0, 0.2)" },
			fontSize: "14px",
			fontWeight: 500,
			color: theme.palette.secondary.contrastText,
			cursor: "pointer",
			opacity: "0.4",
		},
	};

	const getSalesOwnerFieldLabel = (onFieldClicked) => {
		return summaryField?.value?.name ? (
			<Box onClick={onFieldClicked}>
				<Tooltip
					open={openToolTip}
					placement="bottom"
					title={summaryField?.value?.name}
				>
					<SummaryFieldName
						className={classes.summaryData}
						onMouseOver={handleOpenTooltip}
						onMouseLeave={() => setOpenToolTip(false)}
						noWrap
					>
						{summaryField?.value?.name}
					</SummaryFieldName>
				</Tooltip>
			</Box>
		) : summaryField.config.readOnly ? (
			<SummaryFieldName style={{ opacity: 0.6 }}>-</SummaryFieldName>
		) : (
			<Typography sx={styles.inputContent}>Click To Add</Typography>
		);
	};

	const getSalesOwnerRequestData = (selectedOwnerFieldValue) => {
		return {
			...selectedOwnerFieldValue,
			id: entityId,
		};
	};

	const hasValueOrIsChanged = (selectedOwnerFieldValue) => {
		return (
			selectedOwnerFieldValue[summaryField?.fieldId] !==
				summaryField?.value?.id &&
			!!selectedOwnerFieldValue[summaryField?.fieldId]
		);
	};

	const handleUpdateOwnerField = (selectedOwnerFieldValue, onClose) => {
		if (hasValueOrIsChanged(selectedOwnerFieldValue)) {
			let requestData = getSalesOwnerRequestData(selectedOwnerFieldValue);
			updateSummaryField(requestData, onClose);
		} else {
			onClose();
		}
	};

	return (
		<React.Fragment>
			<FieldEdit
				field={summaryField}
				fieldLabel={getSalesOwnerFieldLabel}
			>
				<SalesOwnerEditComponent
					onSave={handleUpdateOwnerField}
					value={summaryField?.value?.id}
					field={summaryField}
				/>
			</FieldEdit>
		</React.Fragment>
	);
}
