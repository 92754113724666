import { useMutation, useQuery } from "@tanstack/react-query";
import { currentUserKey } from "../../../utils/queryKeys/userProfile";
import {
	changePassword,
	getCurrentUserDetails,
} from "../../../api/auth/authApi";

const useCurrentUserDetails = () => {
	return useQuery(currentUserKey, () => getCurrentUserDetails(), {
		select: (userdetails) => userdetails.data,
	});
};

const useChangePassword = () => {
	return useMutation(changePassword);
};

export { useCurrentUserDetails, useChangePassword };
