import React, { useEffect, useMemo, useState } from "react";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import MenuSearchBar from "../../../../../MenuSearchBar";
import { default as UnKnownImage } from "../../../../../../../assets/images/contact/unknownContact.png";
import { useUserList } from "../../../../../../../hooks/services/userManagement/user";

export default function SalesOwnerBaseComponent({
	onChange,
	value,
	inputRef,
	clearErrors,
	field,
	errors,
}) {
	const [selectedUserId, setSelectedUserId] = useState("");
	const [searchValue, setSearchValue] = useState("");

	const { data: userList } = useUserList();

	useEffect(() => {
		if (value) {
			setSelectedUserId(value);
		} else {
			let defaultUserData = userList?.find(
				(user) => user?.config?.isLoggedInUser
			);
			setSelectedUserId(defaultUserData?.value);
			onChange(defaultUserData?.value);
		}
	}, [value, userList]);

	const handleOwnerInputChange = (event) => {
		const { value } = event.target;
		setSelectedUserId(value);
		onChange(value);
		clearErrors(field.id);
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const selectedUserName = useMemo(() => {
		if (!selectedUserId) {
			return "";
		}
		const selectedUser = userList?.find(
			(user) => user.value === selectedUserId
		);
		return selectedUser ? selectedUser.name : "";
	}, [selectedUserId, userList]);

	const getFilteredUserList = () => {
		if (searchValue.trim()) {
			return userList?.filter((user) =>
				//filter for search
				user.name.toLowerCase().startsWith(searchValue.toLowerCase())
			);
		}
		return userList;
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					{userList?.length === 0
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Select
				size="small"
				fullWidth
				value={selectedUserId}
				inputRef={inputRef}
				onChange={(event) => handleOwnerInputChange(event)}
				MenuProps={{
					autoFocus: false,
					PaperProps: {
						style: {
							maxHeight: 300,
							marginTop: "4px",
							borderRadius: "8px",
						},
					},
				}}
				displayEmpty
				renderValue={() => (
					<Typography
						fontSize={14}
						color={selectedUserId ? "#000" : "rgba(0, 0, 0, 0.6)"}
					>
						{selectedUserId
							? selectedUserName
							: field?.placeHolder || "Select"}
					</Typography>
				)}
				onOpen={() => setSearchValue("")}
				error={!!errors[field.id]}
			>
				<Box
					p={0.5}
					marginTop="-8px"
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "#fff",
					}}
				>
					<MenuSearchBar
						value={searchValue}
						onChange={handleSearchValue}
					/>
				</Box>
				{getFilteredUserList()?.length > 0
					? getFilteredUserList()?.map((user) => (
							<MenuItem
								value={user.value}
								key={user.value}
								style={{ height: "40px" }}
							>
								<Stack
									direction="row"
									spacing={1}
									alignItems="center"
								>
									<img
										src={UnKnownImage}
										alt="owner_image"
										height="24px"
										width="24px"
									/>

									<Typography fontSize={14}>
										{user.name}
									</Typography>
								</Stack>
							</MenuItem>
						))
					: renderEmptyView()}
			</Select>
		</React.Fragment>
	);
}
