import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { default as AttachmentIcon } from "../../../../assets/icons/attachment";
import Subject from "../../EmailComposer/EmailComposerUI/Subject";
import TemplateInput from "./TemplateInput";
import TemplateTagsInput from "./TemplateTagsInput";
import { useTemplateTagsDropdownList } from "../../../../hooks/services/mail/templateTags";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from "@lexical/html";
import { parseHTML } from "../../../../utils/EmailUtils/parseHTML";
import { twozoStyles } from "../../../../styles/twozo";

export default function AddTemplate(props) {
	const {
		onCloseEmailTemplateDrawer,
		onSaveEmailTemplate,
		templateFormData,
		isEditMode,
		isSaving,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const [editor] = useLexicalComposerContext();

	const [templateName, setTemplateName] = useState("");
	const [subject, setSubject] = useState("");
	const [selectedTemplateTags, setSelectedTemplateTags] = useState([]);

	const { data: templateTags } = useTemplateTagsDropdownList();
	const iconColor = "#666666";

	useEffect(() => {
		if (templateFormData) {
			if (templateFormData?.tags) {
				setSelectedTemplateTags(
					templateFormData.tags?.map((tag) => tag.name)
				);
			}
			if (templateFormData?.subject) {
				setSubject(templateFormData?.subject);
			}
			setTemplateName(templateFormData?.name);
		}
	}, [templateFormData]);

	const handleTemplateNameChange = (event) => {
		const { value } = event.target;
		setTemplateName(value.trimStart());
	};

	const handleSubjectChange = (event) => {
		const { value } = event.target;
		setSubject(value.trimStart());
	};

	const handleSelectTags = (values) => {
		setSelectedTemplateTags(values);
	};

	const getTagIdByName = (tagName) => {
		let tagData = templateTags?.find(
			(templateTag) => templateTag?.name === tagName
		);
		if (tagData) {
			return tagData.id;
		}
	};

	const getFormatedTemplateTags = () => {
		let formatedTemplateTags = [];
		for (let templateTag of selectedTemplateTags) {
			let templateTagId = getTagIdByName(templateTag);
			if (templateTagId) {
				formatedTemplateTags.push({ id: templateTagId });
			} else {
				formatedTemplateTags.push({ name: templateTag });
			}
		}
		return formatedTemplateTags;
	};

	const getTemplateContent = () => {
		let editorState = editor.getEditorState();
		let htmlBodyContent = editorState.read(() => {
			return parseHTML($generateHtmlFromNodes(editor), classes);
		});

		return htmlBodyContent;
	};

	const handleSaveEmailTemplate = () => {
		let templateFormData = {
			name: templateName,
			tags: getFormatedTemplateTags(),
			subject: subject,
			content: getTemplateContent(),
		};

		onSaveEmailTemplate(templateFormData);
	};

	const getFormHeaderLabel = () => {
		if (isEditMode) {
			return "Edit Email Template";
		} else {
			return "New Email Template";
		}
	};

	return (
		<React.Fragment>
			{isSaving ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<Stack sx={{ height: "100%" }}>
					<Box p={2}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<Typography fontWeight={600}>
								{getFormHeaderLabel()}
							</Typography>

							<IconButton
								onClick={onCloseEmailTemplateDrawer}
								size="small"
								style={{ padding: 0 }}
							>
								{CloseIcon(25, 25, theme.palette.primary.main)}
							</IconButton>
						</Stack>
					</Box>

					<Divider />

					<Box
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
						}}
					>
						<Box p={2}>
							<Stack spacing={2}>
								<Stack spacing={0.5}>
									<Typography fontSize={13} fontWeight={500}>
										Email Template Name
									</Typography>

									<TextField
										value={templateName}
										onChange={handleTemplateNameChange}
									/>
								</Stack>

								<Stack spacing={0.5}>
									<Typography fontSize={13} fontWeight={500}>
										Tags
									</Typography>

									<TemplateTagsInput
										tagsList={templateTags || []}
										handleSelectTags={handleSelectTags}
										selectedTags={selectedTemplateTags}
									/>
								</Stack>
							</Stack>
						</Box>

						<Divider />

						<Stack direction="row" px={2} alignItems="center">
							<Typography fontSize={14}>Sub :</Typography>

							<Box flexGrow={1}>
								<Subject
									value={subject}
									onChange={handleSubjectChange}
								/>
							</Box>
						</Stack>

						<Divider />

						<Box>
							<TemplateInput
								initialTemplateContent={
									templateFormData?.content
								}
							/>
						</Box>

						<Box p={2} display="flex" style={{ cursor: "pointer" }}>
							{AttachmentIcon(20, 20, iconColor)}
						</Box>
					</Box>

					<Divider />

					<Box p={2}>
						<Button
							variant="contained"
							disableElevation
							onClick={handleSaveEmailTemplate}
							disabled={isSaving}
						>
							{isEditMode ? "Update" : "Save"}
						</Button>
					</Box>
				</Stack>
			)}
		</React.Fragment>
	);
}
