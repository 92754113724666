import {
	Box,
	Checkbox,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { default as DragIcon } from "../../../../../../.././assets/icons/drag";
import { default as AddIcon } from "../../../../../../.././assets/icons/add";
import { default as CloseIcon } from "../../../../../../.././assets/icons/close";
import Menu from "../../../../../.././Elements/Menu";
import { default as DropDownIcon } from "../../../../../../.././assets/icons/dropDown";
import { useSelectReducer } from "../../../../../../Settings/DataFields/Field/FieldContext/SelectReducer";
import { Tooltip } from "../../../../../../../styles/twozo";

export const MultiselectField = (props) => {
	const { multiselectChoices, updateMultiselectChoices } = props;
	const theme = useTheme();

	// select reducer
	const {
		selectState,
		onCreateSelectChoices,
		onResetSelectChoices,
		onChoiceCreationError,
		onDuplicateChoiceError,
		errorOnCharactersLimitExceeded,
	} = useSelectReducer();

	const [multiselectChoiceHovered, setMultiselectChoiceHovered] =
		useState(null);
	const [editableChoiceIndex, setEditableChoiceIndex] = useState(null);
	const [isNewOptionCreated, setIsNewOptionCreated] = useState(false);
	const [isChoiceTooltipOpened, setIsChoiceTooltipOpened] = useState(false);
	const [hoveredChoiceIndex, setHoveredChoiceIndex] = useState(null);

	const [multiselectChoicesElement, setMultiselectChoicesElement] =
		useState(null);
	const openMultiselectChoicesMenu = Boolean(multiselectChoicesElement);

	const hasError =
		selectState.hasCreationError ||
		selectState.hasDuplicateError ||
		selectState.isLimitExceeded;

	const onMouseOverMultiselectChoice = (index) => {
		setMultiselectChoiceHovered(index);
	};

	const onMouseOutMultiselectChoice = () => {
		setMultiselectChoiceHovered(null);
	};

	const editMultiselectChoiceLabel = (index) => {
		if (hasError || isNewOptionCreated) {
			return;
		}
		if (isDuplicateOption()) {
			onDuplicateChoiceError();
		} else {
			setEditableChoiceIndex(index);
		}
	};

	const setMultiselectChoiceLabel = (index, value) => {
		const updatedOptionName = multiselectChoices.map(
			(choice, choiceIndex) => {
				if (choiceIndex === index) {
					return { ...choice, name: value };
				}

				return choice;
			}
		);

		let maxChoiceLength = 126;
		if (value.trim().length > maxChoiceLength) {
			errorOnCharactersLimitExceeded();
		} else {
			updateMultiselectChoices(updatedOptionName);
			updateMultiselectStateOnOptionEdit(value);
			setEditableChoiceIndex(index);
		}
	};

	const updateMultiselectStateOnOptionEdit = (value) => {
		if (value !== "") {
			onResetSelectChoices();
		} else {
			onCreateSelectChoices();
		}
	};

	const setMultiselectChoiceChecked = (index, value, choice) => {
		if (hasError || !choice?.name?.trim()) {
			return;
		}

		const modifiedMultiselectChoices = multiselectChoices.map(
			(choice, choiceIndex) => {
				if (choiceIndex === index) {
					return { ...choice, checked: value };
				}

				return choice;
			}
		);

		modifiedMultiselectChoices.forEach((element) => {
			if (element.checked) {
				element.isDefault = true;
			} else {
				delete element.isDefault;
			}
		});
		updateMultiselectChoices(modifiedMultiselectChoices);
	};

	const isDuplicateOption = () => {
		const uniqueChoices = new Set();
		for (const choice of multiselectChoices) {
			const choiceName = choice.name.trim().toLowerCase();
			if (choiceName !== "" && uniqueChoices.has(choiceName)) {
				return true;
			}
			uniqueChoices.add(choiceName);
		}

		return false;
	};

	const getFilteredEmptyOptions = () => {
		let filteredEmptyChoices = multiselectChoices.filter((choice) => {
			return choice.name !== "";
		});

		return filteredEmptyChoices;
	};

	const isEmptyOption = () => {
		return getFilteredEmptyOptions()?.length < 0;
	};

	const handleMultiselectChoiceValidation = () => {
		if (isMultiselectOptionsValid()) {
			addMultiselectChoice();
		}
	};

	const isMultiselectOptionsValid = () => {
		if (hasError) {
			return;
		}

		if (selectState.isCreateMode) {
			onChoiceCreationError();
		} else {
			return validateAndDispatchMultiselectState();
		}
	};

	const validateAndDispatchMultiselectState = () => {
		if (isEmptyOption()) {
			onChoiceCreationError();
			return false;
		} else if (isDuplicateOption()) {
			onDuplicateChoiceError();
			return false;
		}

		return true;
	};

	const removeMultiselectChoice = (index) => {
		if (isOptionRemovable(index)) {
			const updatedMultiselectChoices = [...multiselectChoices];
			updateMultiselectChoices(updatedMultiselectChoices);
			updatedMultiselectChoices.splice(index, 1);
			onResetSelectChoices();
			setIsNewOptionCreated(false);
			setEditableChoiceIndex(null);
		}
	};

	const addMultiselectChoice = () => {
		let modifiedMultiselectChoices = [];
		let emptyChoice = {
			id: `id ${modifiedMultiselectChoices.length}`,
			checked: false,
			name: "",
		};

		if (multiselectChoices.length < 1) {
			modifiedMultiselectChoices = [
				...multiselectChoices,
				{
					id: "id 0",
					checked: false,
					name: "",
				},
				{
					id: "id 1",
					checked: false,
					name: "",
				},
			];
		} else {
			modifiedMultiselectChoices = [...multiselectChoices, emptyChoice];
		}
		setIsNewOptionCreated(true);
		updateMultiselectChoices(modifiedMultiselectChoices);
		setEditableChoiceIndex(multiselectChoices.length);
		onCreateSelectChoices();
	};

	const validateOnFieldClose = (_, reason) => {
		if (selectState.hasDuplicateError || selectState.isLimitExceeded) {
			return;
		}

		if (reason === "backdropClick") {
			if (isDuplicateOption()) {
				onDuplicateChoiceError();
				return;
			}
			handleCloseMultiselectChoicesMenu();
		}
	};

	const reorderChoice = (choice, startIndex, endIndex) => {
		const result = Array.from(choice);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onChoiceDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const updatedMultiselectChoices = reorderChoice(
			multiselectChoices,
			result.source.index,
			result.destination.index
		);
		updateMultiselectChoices(updatedMultiselectChoices);
	};

	const handleOpenMultiselectChoicesMenu = (event) => {
		setMultiselectChoicesElement(event.currentTarget);
	};

	const handleCloseMultiselectChoicesMenu = () => {
		setMultiselectChoicesElement(null);
		setEditableChoiceIndex(null);
		setIsNewOptionCreated(false);
		onResetSelectChoices();
		updateMultiselectChoices(getFilteredEmptyOptions());
	};

	const isOptionRemovable = (choiceIndex) => {
		let defaultChoices = 2;

		if (multiselectChoices.length > defaultChoices) {
			if (!hasError && !isNewOptionCreated) {
				return true;
			}

			return editableChoiceIndex === choiceIndex;
		}
	};

	const hasMultiselectChoiceError = (choice) => {
		let defaultChoicesCount = 2;
		let maxChoiceLength = 126;

		if (multiselectChoices.length === defaultChoicesCount && hasError) {
			if (selectState.isLimitExceeded) {
				return choice.name.length >= maxChoiceLength;
			}
			return choice.name === "" || isDuplicateOption();
		}

		return hasError;
	};

	const handleOpenChoiceTooltip = (event, index) => {
		setIsChoiceTooltipOpened(
			event.target.scrollWidth > event.target.clientWidth
		);
		setHoveredChoiceIndex(index);
	};

	return (
		<React.Fragment>
			<Box>
				<Box
					pl={1}
					pr={0.5}
					py={0.2}
					sx={{
						backgroundColor: (theme) =>
							theme.palette.secondary.main,
						borderRadius: "3px",
					}}
					onClick={handleOpenMultiselectChoicesMenu}
				>
					<Stack
						direction="row"
						spacing={0.5}
						style={{ cursor: "pointer" }}
					>
						<Typography fontSize={12}>
							{multiselectChoices.length}{" "}
							{multiselectChoices.length > 1
								? "Choices"
								: "Choice"}
						</Typography>

						{DropDownIcon(
							18,
							18,
							theme.palette.secondary.contrastText
						)}
					</Stack>
				</Box>

				<Menu
					minWidth="260px"
					anchorEl={multiselectChoicesElement}
					open={openMultiselectChoicesMenu}
					onClose={validateOnFieldClose}
				>
					<DragDropContext
						onDragEnd={(result) => onChoiceDragEnd(result)}
					>
						<Droppable droppableId="multiselect-choices">
							{(provided) => (
								<div
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									{multiselectChoices.map((choice, index) => (
										<Draggable
											draggableId={index.toString()}
											index={index}
											key={index}
										>
											{(provided) => (
												<Box
													ref={provided.innerRef}
													{...provided.draggableProps}
												>
													<Box
														px={1}
														display="flex"
														alignItems="center"
														key={choice.id}
														style={{
															minHeight: "40px",
															backgroundColor:
																multiselectChoiceHovered ===
																index
																	? "#F4F5F5"
																	: "#fff",
														}}
														onMouseOver={() =>
															onMouseOverMultiselectChoice(
																index
															)
														}
														onMouseOut={() =>
															onMouseOutMultiselectChoice()
														}
													>
														<Stack
															direction="row"
															justifyContent="space-between"
															alignItems="center"
															width="100%"
														>
															<Stack
																direction="row"
																alignItems="center"
															>
																<Stack
																	direction="row"
																	alignItems="center"
																	pr={1}
																>
																	<Box
																		display="flex"
																		{...provided.dragHandleProps}
																	>
																		{DragIcon(
																			20,
																			20
																		)}
																	</Box>

																	<Checkbox
																		checked={
																			choice.checked
																		}
																		onChange={(
																			_,
																			value
																		) =>
																			setMultiselectChoiceChecked(
																				index,
																				value,
																				choice
																			)
																		}
																		disableRipple
																	/>
																</Stack>

																{choice.name ===
																	"" ||
																index ===
																	editableChoiceIndex ? (
																	<TextField
																		variant="standard"
																		sx={{
																			width: "90%",
																		}}
																		value={
																			choice.name
																		}
																		onChange={(
																			event
																		) =>
																			setMultiselectChoiceLabel(
																				index,
																				event
																					.target
																					.value
																			)
																		}
																		InputProps={{
																			style: {
																				fontSize: 13,
																			},
																		}}
																		fullWidth
																		autoFocus
																		error={hasMultiselectChoiceError(
																			choice
																		)}
																		helperText={
																			hasMultiselectChoiceError(
																				choice
																			) &&
																			selectState.errorMessage
																		}
																	/>
																) : (
																	<Box
																		onClick={() =>
																			editMultiselectChoiceLabel(
																				index
																			)
																		}
																	>
																		<Tooltip
																			title={
																				choice.name
																			}
																			placement="top"
																			open={
																				isChoiceTooltipOpened &&
																				hoveredChoiceIndex ===
																					index
																			}
																		>
																			<Typography
																				noWrap
																				fontSize={
																					13
																				}
																				onMouseOver={(
																					event
																				) =>
																					handleOpenChoiceTooltip(
																						event,
																						index
																					)
																				}
																				onMouseOut={() => {
																					setIsChoiceTooltipOpened(
																						false
																					);
																					setHoveredChoiceIndex(
																						null
																					);
																				}}
																				sx={{
																					width: "220px",
																				}}
																			>
																				{
																					choice.name
																				}
																			</Typography>
																		</Tooltip>
																	</Box>
																)}
															</Stack>
															<Box
																display="flex"
																onClick={() =>
																	removeMultiselectChoice(
																		index
																	)
																}
																sx={{
																	cursor: isOptionRemovable(
																		index
																	)
																		? "pointer"
																		: "not-allowed",
																	mr: 1,
																	ml: 1,
																}}
															>
																{CloseIcon(
																	20,
																	20,
																	"#000000",
																	0.6
																)}
															</Box>
														</Stack>
													</Box>
												</Box>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>

					<Box
						onClick={handleMultiselectChoiceValidation}
						style={{
							minHeight: "40px",
							cursor: hasError ? "not-allowed" : "pointer",
							opacity: hasError ? 0.6 : 1,
						}}
						ml={4}
						pt={1.5}
					>
						<Stack direction="row" spacing={2}>
							{AddIcon(
								20,
								20,
								theme.palette.secondary.contrastText
							)}
							<Typography
								fontSize={13}
								color={theme.palette.secondary.contrastText}
							>
								Add Choice
							</Typography>
						</Stack>
					</Box>
				</Menu>
			</Box>
		</React.Fragment>
	);
};
