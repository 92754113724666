import React, { useState } from "react";
import {
	Box,
	IconButton,
	Stack,
	Typography,
	MenuItem,
	Slider,
	useTheme,
	MenuList,
	CircularProgress,
} from "@mui/material";
import { default as MoreIcon } from "../../../assets/icons/more";
import { default as MailIcon } from "../../../assets/icons/mail";
import { default as SentMailIcon } from "../../../assets/icons/mailSent";
import { default as PlayIcon } from "../../../assets/icons/play";
import { default as AttachmentIcon } from "../../../assets/icons/attachment";
import { default as ImageIcon } from "../../../assets/icons/image";
import { default as FileIcon } from "../../../assets/icons/files";
import { default as CallIcon } from "../../../assets/icons/call";
import Menu from "../../Elements/Menu";
import EmailComposerDrawer from "../../Mail/EmailComposer/EmailComposerDrawer";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { twozoStyles } from "../../../styles/twozo";
import { useConversationList } from "../../../hooks/services/conversations";
import {
	getDateDifference,
	getFormattedDateMonthYearAndTime,
} from "../../../utils/DateUtils";
import { getFormattedFileSize } from "../../../utils/fileSizeUtils";
import {
	useUpdateMailStateForConversation,
	useUpdateScheduledMail,
} from "../../../hooks/services/mail";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import DeleteDialog from "../../Elements/DeleteDialog";
import ConfirmDialog from "../../Elements/ConfirmDialog";
import ConversationViewer from "./ConversationViewer";

const styles = {
	callPlaybackContainer: {
		width: "350px",
		border: "1px solid rgba(0, 0, 0, 0.1)",
		borderRadius: "8px",
		padding: "8px 16px",
		marginTop: "4px",
	},
	callPlaybackProgressBar: {
		"& .MuiSlider-rail": {
			opacity: 0.5,
			backgroundColor: "#bfbfbf",
		},
	},
	greyedText: {
		fontSize: "13px",
		color: "rgba(0, 0, 0, 0.6)",
	},
};

export default function Conversations(props) {
	const { sourceName, entityId } = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const [conversationMenuElement, setConversationMenuElement] =
		useState(null);
	const isConversationMenuOpened = Boolean(conversationMenuElement);
	const [attachmentMenuElement, setAttachmentMenuElement] = useState(null);
	const isAttachmentMenuOpened = Boolean(attachmentMenuElement);
	const [isEmailComposerOpened, setIsEmailComposerOpened] = useState(false);
	const [isConversationViewerOpened, setIsConversationViewerOpened] =
		useState(false);
	const [hoveredConversationId, setHoveredConversationId] = useState("");
	const [conversationMenuOptions, setConversationMenuOptions] = useState([]);
	const [attachmentData, setAttachmentData] = useState([]);
	const [selectedMailId, setSelectedMailId] = useState("");
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [
		isUnlinkConversationDialogOpened,
		setIsUnlinkConversationDialogOpened,
	] = useState(false);
	const [isScheduledMail, setIsScheduledMail] = useState(false);
	const [openedThreadId, setOpenedThreadId] = useState("");

	const { data: conversationList, isLoading: isConversationListLoading } =
		useConversationList(sourceName, entityId);

	const updateMailStateMutation = useUpdateMailStateForConversation(
		sourceName,
		entityId
	);
	const updateScheduledMailMutation = useUpdateScheduledMail(
		sourceName,
		entityId
	);

	let disableDeleteButton =
		updateMailStateMutation.isLoading ||
		updateScheduledMailMutation.isLoading;
	const conversationTypes = {
		call: "CALL",
		mail: "EMAIL",
	};

	const mailTypes = {
		outboundMail: "OUTBOUND_MAIL",
		inboundMail: "INBOUND_MAIL",
	};

	const imageExtension = ["png", "jpg", "jpeg"];

	const openReplyEmailComposer = () => {
		closeConversationMenu();
		setIsEmailComposerOpened(true);
	};

	const closeReplyEmailComposer = () => {
		setIsEmailComposerOpened(false);
	};

	const openAttachmentMenu = (event, attachments) => {
		setAttachmentMenuElement(event.currentTarget);
		setAttachmentData(attachments);
	};

	const closeAttachmentMenu = () => {
		setAttachmentMenuElement(null);
	};

	const onMouseOverConversation = (conversationId) => {
		setHoveredConversationId(conversationId);
	};

	const onMouseOutConversation = () => {
		setHoveredConversationId("");
	};

	const openConversationsMenu = (event, conversation) => {
		setIsScheduledMail(!!conversation?.data?.scheduledTime);
		setConversationMenuElement(event.currentTarget);
		setSelectedMailId(conversation?.data?.threadId);

		const menuOptions = getConversationMenuOptions(conversation);
		setConversationMenuOptions(menuOptions);
	};

	const closeConversationMenu = () => {
		setConversationMenuElement(null);
	};

	const handleDeleteMail = () => {
		if (isScheduledMail) {
			handleDeleteScheduledMail(selectedMailId);
		} else {
			let deleteMailRequestData = {
				ids: [selectedMailId],
				isActive: false,
			};
			updateMailStateMutation.mutate(deleteMailRequestData, {
				onSuccess: () => {
					closeDeleteDialog();
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.somethingWentWrong,
					});
				},
			});
		}
	};

	const updateUnreadMailState = (mailId) => {
		let unreadMailRequestData = {
			ids: [mailId],
			isRead: false,
		};
		updateMailStateMutation.mutate(unreadMailRequestData, {
			onSuccess: () => {
				closeConversationMenu();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleCancelScheduledMail = (mailId) => {
		let updateScheduledMailRequestData = {
			mailIds: [mailId],
			isCancelled: true,
		};
		updateScheduledMailMutation.mutate(updateScheduledMailRequestData, {
			onSuccess: () => {
				closeConversationMenu();
				closeDeleteDialog();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleDeleteScheduledMail = (mailId) => {
		let updateScheduledMailRequestData = {
			mailIds: [mailId],
			isDeleted: true,
		};
		updateScheduledMailMutation.mutate(updateScheduledMailRequestData, {
			onSuccess: () => {
				closeDeleteDialog();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const openDeleteDialog = (mailId) => {
		setIsDeleteDialogOpened(true);
		setConversationMenuElement(null);
		setSelectedMailId(mailId);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const openUnlinkConversationDialog = () => {
		setIsUnlinkConversationDialogOpened(true);
		setConversationMenuElement(null);
	};

	const closeUnlinkConversationDialog = () => {
		setIsUnlinkConversationDialogOpened(false);
	};

	const optionsForHighlightMails = [
		{
			id: "reply",
			name: "Reply",
			action: openReplyEmailComposer,
		},
		{
			id: "mark as unread",
			name: "Mark As Unread",
			action: updateUnreadMailState,
		},
		{
			id: "forward",
			name: "Forward",
			action: closeConversationMenu,
		},
		{
			id: "delete",
			name: "Delete",
			action: openDeleteDialog,
		},
		{
			id: "unlink conversation from the deal",
			name: "Unlink Conversation from the deal",
			action: closeConversationMenu,
		},
	];

	const mailOptions = [
		{
			id: "reply",
			name: "Reply",
			action: openReplyEmailComposer,
		},
		{
			id: "forward",
			name: "Forward",
			action: closeConversationMenu,
		},
		{
			id: "delete",
			name: "Delete",
			action: openDeleteDialog,
		},
		{
			id: "unlink this conversation",
			name: "Unlink this Conversation",
			action: openUnlinkConversationDialog,
		},
	];

	const draftMailOptions = [
		{
			id: "delete",
			name: "Delete",
			action: openDeleteDialog,
		},
	];

	const scheduledMailOptions = [
		{
			id: "cancel Send",
			name: "Cancel Send",
			action: handleCancelScheduledMail,
		},
		{
			id: "delete",
			name: "Delete",
			isScheduled: true,
			action: openDeleteDialog,
		},
	];

	const getConversationMenuOptions = (conversation) => {
		if (conversation?.data?.scheduledTime) {
			return scheduledMailOptions;
		} else if (conversation?.data?.isDraft) {
			return draftMailOptions;
		} else if (conversation?.data?.highlights) {
			return optionsForHighlightMails;
		} else {
			return mailOptions;
		}
	};

	const openConversationViewer = () => {
		setIsConversationViewerOpened(true);
	};

	const closeConversationViewer = () => {
		setIsConversationViewerOpened(false);
	};

	const handleConversationMail = (conversation) => {
		if (conversation.data.isDraft) {
			setIsEmailComposerOpened(true);
		} else {
			openConversationViewer();
			setOpenedThreadId(conversation?.data?.threadId);
		}
	};

	const getConversationIcon = (conversation) => {
		if (conversation.type === conversationTypes.call) {
			return CallIcon(24, 24);
		} else if (conversation.type === conversationTypes.mail) {
			if (conversation.category === mailTypes.outboundMail) {
				return SentMailIcon(24, 24);
			}
			return MailIcon(24, 24);
		}
	};

	return (
		<React.Fragment>
			{/*Delete Dialog*/}
			<DeleteDialog
				title="Are you sure you want to delete this conversation?"
				subtitle="Deleting it will move the conversation to the trash folder."
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteMail}
				disableDeleteButton={disableDeleteButton}
			/>

			{/*Unlink Conversation Dialog*/}
			<ConfirmDialog
				title="Are you sure you want to unlink this conversation?"
				subtitle="Doing this will unlink all emails included in this conversation."
				confirmButtonText="Unlink"
				open={isUnlinkConversationDialogOpened}
				onCancel={closeUnlinkConversationDialog}
				onConfirm={() => {}}
			/>

			{/*Attachment Menu*/}
			<Menu
				minWidth="320px"
				anchorEl={attachmentMenuElement}
				open={isAttachmentMenuOpened}
				onClose={closeAttachmentMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				style={{
					marginTop: "4px",
				}}
			>
				<MenuList sx={{ padding: "0px" }}>
					{attachmentData.map((attachment) => (
						<MenuItem
							key={attachment.id}
							style={{ height: "40px" }}
						>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-between"
								spacing={2}
								width="100%"
							>
								<Stack
									direction="row"
									alignItems="center"
									spacing={2}
									style={{
										overflow: "hidden",
									}}
								>
									<Box display="flex">
										{imageExtension.includes(
											attachment.fileName.split(".")[1]
										)
											? ImageIcon(
													20,
													20,
													"rgba(0, 0, 0, 0.6)"
												)
											: FileIcon(
													20,
													20,
													"rgba(0, 0, 0, 0.6)"
												)}
									</Box>

									<Typography fontSize={13}>
										{attachment.fileName}
									</Typography>
								</Stack>

								<Typography
									fontSize={13}
									color="rgba(0, 0, 0, 0.6)"
								>
									{getFormattedFileSize(attachment.fileSize)}
								</Typography>
							</Stack>
						</MenuItem>
					))}
				</MenuList>
			</Menu>

			<Menu
				minWidth="256px"
				anchorEl={conversationMenuElement}
				open={isConversationMenuOpened}
				onClose={closeConversationMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				{conversationMenuOptions.map((conversationOption) => (
					<MenuItem
						key={conversationOption.id}
						style={{ height: "40px" }}
						onClick={() =>
							conversationOption.action(
								selectedMailId,
								conversationOption?.isScheduled
							)
						}
					>
						<Typography
							color={theme.palette.secondary.contrastText}
							fontSize={13}
							fontWeight={500}
						>
							{conversationOption.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<CustomSwipeableDrawer
				anchor="right"
				BackdropProps={{ invisible: true }}
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isConversationViewerOpened}
				onOpen={openConversationViewer}
				onClose={closeConversationViewer}
				disableSwipeToOpen
			>
				<Box sx={{ width: "45vw", height: "100vh" }}>
					<Box className={classes.mailViewCard}>
						<ConversationViewer
							onClose={closeConversationViewer}
							mailId={openedThreadId}
							sourceName={sourceName}
							entityId={entityId}
						/>
					</Box>
				</Box>
			</CustomSwipeableDrawer>

			<Box overflow="auto">
				<Box hidden={!isEmailComposerOpened}>
					<EmailComposerDrawer onClose={closeReplyEmailComposer} />
				</Box>

				{isConversationListLoading ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="50vh"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : conversationList?.length > 0 ? (
					<Box>
						{conversationList?.map((conversation) => (
							<Stack
								key={conversation.id}
								onMouseOver={() =>
									onMouseOverConversation(conversation.id)
								}
								onMouseOut={onMouseOutConversation}
								direction="row"
								justifyContent="space-between"
								style={{
									borderBottom:
										"1px solid rgba(0, 0, 0, 0.1)",
									backgroundColor:
										hoveredConversationId ===
										conversation.id
											? "#FAFAFA"
											: "#fff",
									cursor: "pointer",
								}}
								py={2}
								spacing={2}
							>
								<Stack
									direction="row"
									spacing={3}
									style={{
										borderLeft: conversation?.highlights
											? `3px solid ${theme.palette.primary.main}`
											: "3px solid transparent",
										paddingLeft: "16px",
										width: "100%",
									}}
									onClick={() =>
										handleConversationMail(conversation)
									}
								>
									<Box>
										<Stack
											sx={{
												width: "40px",
												height: "40px",
												alignItems: "center",
												justifyContent: "center",
												backgroundColor:
													theme.palette.secondary
														.main,
												borderRadius: "100%",
											}}
										>
											{getConversationIcon(conversation)}
										</Stack>
									</Box>

									<Stack spacing={1}>
										<Stack direction="row" spacing={1}>
											<Typography
												fontSize={13}
												fontWeight={500}
											>
												{conversation.participant}
											</Typography>

											<Typography
												style={styles.greyedText}
											>
												&#8226;
											</Typography>

											<Typography
												style={styles.greyedText}
											>
												{conversation?.data
													?.scheduledTime
													? `Scheduled for ${getFormattedDateMonthYearAndTime(
															conversation?.data
																?.scheduledTime
														)}`
													: `${getFormattedDateMonthYearAndTime(
															conversation?.timeStamp
														)}
														${getDateDifference(conversation?.timeStamp)}`}
											</Typography>
											{conversation.openedCount ? (
												<Stack
													direction="row"
													spacing={1}
												>
													<Typography
														style={
															styles.greyedText
														}
													>
														&#8226;
													</Typography>

													<Typography
														style={
															styles.greyedText
														}
													>
														{`Opened(${conversation.openedCount})`}
													</Typography>
												</Stack>
											) : null}
										</Stack>

										<Stack>
											<Typography fontSize={14}>
												{conversation.data.subject}
											</Typography>

											<Stack direction="row" spacing={1}>
												{conversation.data.isDraft ? (
													<Typography
														fontSize={14}
														color={
															theme.palette.error
																.main
														}
													>
														[Draft]
													</Typography>
												) : null}
												<Typography
													fontSize={14}
													color={"rgba(0, 0, 0, 0.6)"}
												>
													{conversation.data.message}
												</Typography>
											</Stack>
											{conversation.isRecord ? (
												<Box
													style={
														styles.callPlaybackContainer
													}
												>
													<Stack
														direction="row"
														alignItems="center"
														spacing={1.5}
													>
														<Box display="flex">
															{PlayIcon(
																20,
																20,
																"#000"
															)}
														</Box>

														<Slider
															value={50}
															size="small"
															sx={
																styles.callPlaybackProgressBar
															}
														/>

														<Typography
															fontSize={12}
														>
															01:35
														</Typography>
													</Stack>
												</Box>
											) : null}
										</Stack>
									</Stack>
								</Stack>

								<Stack
									direction="row"
									spacing={2}
									justifyContent="center"
									alignItems="center"
								>
									{conversation?.data?.hasAttachment ? (
										<Box>
											<IconButton
												style={{ padding: "4px" }}
												onClick={(event) =>
													openAttachmentMenu(
														event,
														conversation?.data
															?.attachments
													)
												}
											>
												{AttachmentIcon(
													20,
													20,
													theme.palette.primary.main
												)}
											</IconButton>
										</Box>
									) : null}
									<Box pr={1}>
										<IconButton
											size="small"
											onClick={(event) =>
												openConversationsMenu(
													event,
													conversation
												)
											}
										>
											{MoreIcon(20, 20, "#000000", 0.6)}
										</IconButton>
									</Box>
								</Stack>
							</Stack>
						))}
					</Box>
				) : (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="20vh"
					>
						<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
							No conversations found
						</Typography>
					</Stack>
				)}
			</Box>
		</React.Fragment>
	);
}
