import { Box } from "@mui/material";
import { Tooltip } from "../../../styles/twozo";
import { useState } from "react";

export default function TooltipComponent(params) {
	const {
		children,
		title,
		// possible placement values:-
		// -----------------------------
		// |'bottom-end'
		// | 'bottom-start'
		// | 'bottom'
		// | 'left-end'
		// | 'left-start'
		// | 'left'
		// | 'right-end'
		// | 'right-start'
		// | 'right'
		// | 'top-end'
		// | 'top-start'
		// | 'top'
		placement,
		horizontalDisplacement = 0,
		verticalDisplacement = 0,
	} = params;

	const [isTooltipOpened, setIsTooltipOpened] = useState(false);

	const onOpenTooltip = (event) => {
		setIsTooltipOpened(event.target.scrollWidth > event.target.clientWidth);
	};

	const onCloseTooltip = () => {
		setIsTooltipOpened(false);
	};

	return (
		<Tooltip
			title={title || ""}
			placement={placement}
			open={isTooltipOpened}
			PopperProps={{
				modifiers: [
					{
						name: "offset",
						options: {
							offset: [
								horizontalDisplacement,
								verticalDisplacement,
							],
						},
					},
				],
			}}
		>
			<Box
				onMouseOver={onOpenTooltip}
				onMouseLeave={onCloseTooltip}
				sx={{ display: "inline-block" }}
			>
				{children}
			</Box>
		</Tooltip>
	);
}
