import React, { useState } from "react";
import {
	Box,
	CircularProgress,
	IconButton,
	MenuItem,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import { default as RightArrowIcon } from "../../../../../../assets/icons/dropDownRight";
import ToggleButtonGroup from "../../../../../Elements/ToggleButtonGroup";
import {
	usePlaceHolderFields,
	usePlaceHolderSources,
} from "../../../../../../hooks/services/mail/emailTemplate";
import { getIconByName } from "../../../../../../utils/icon";
import { modules } from "../../../../../../utils/common/ModulesName";
import MenuSearchBar from "../../../../../Elements/MenuSearchBar";

export default function PlaceHoldersMenu(props) {
	const { closePlaceHolderMenu, handleSelectPlaceHolder } = props;
	const theme = useTheme();

	const [sourceType, setSourceType] = useState(modules.CONTACT);
	const [searchValue, setSearchValue] = useState("");

	const { data: placeHolderSources } = usePlaceHolderSources();
	const { data: placeHolderFields, isLoading: isPlaceHoldersFieldsLoading } =
		usePlaceHolderFields(sourceType);

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setSourceType(type);
		}
	};

	const handleSearchField = (event) => {
		const { value } = event.target;
		setSearchValue(value.trim());
	};

	const getFilteredFields = () => {
		if (searchValue) {
			let filteredFields = placeHolderFields?.fields?.filter((field) => {
				return field.displayName
					.toLowerCase()
					.startsWith(searchValue.toLowerCase());
			});
			return filteredFields;
		}
		return placeHolderFields?.fields;
	};

	const renderEmptyView = () => {
		return (
			<Stack
				justifyContent="center"
				alignItems="center"
				height="250px"
				width="100%"
			>
				<Typography fontSize={14}>No results found</Typography>
			</Stack>
		);
	};

	const onClose = () => {
		setSearchValue("");
		closePlaceHolderMenu();
		setSourceType(modules.CONTACT);
	};

	const onSelectfield = (selectedField) => {
		onClose();
		let sourceId = placeHolderFields?.sourceId;
		handleSelectPlaceHolder(sourceId, selectedField);
	};

	return (
		<React.Fragment>
			<Stack>
				<Stack p={0.8} pt={1.2} direction="row" spacing={4}>
					<IconButton onClick={onClose} style={{ padding: "0px" }}>
						{RightArrowIcon(20, 20, theme.palette.primary.main)}
					</IconButton>

					<Typography fontSize={13} fontWeight={500}>
						Placeholders
					</Typography>
				</Stack>

				<Box style={{ textAlign: "center" }}>
					<ToggleButtonGroup
						value={sourceType}
						onChange={handleToggleButtonGroup}
					>
						{placeHolderSources?.map((source) => (
							<ToggleButton
								value={source.sourceName}
								key={source.sourceId}
							>
								{getIconByName(source.sourceName.toLowerCase())(
									20,
									20,
									sourceType === source.sourceName
										? "#fff"
										: "#666666"
								)}
							</ToggleButton>
						))}
					</ToggleButtonGroup>
				</Box>

				<Box p={0.5} style={{ textAlign: "center" }}>
					<MenuSearchBar
						value={searchValue}
						onChange={handleSearchField}
					/>
				</Box>

				<Box sx={{ height: "260px", overflowY: "auto" }}>
					{isPlaceHoldersFieldsLoading ? (
						<Stack
							justifyContent="center"
							alignItems="center"
							height="250px"
							width="100%"
						>
							<CircularProgress size="25px" />
						</Stack>
					) : getFilteredFields()?.length > 0 ? (
						getFilteredFields()?.map((field) => (
							<MenuItem
								key={field.id}
								style={{
									minHeight: "40px",
								}}
								onClick={() => onSelectfield(field)}
							>
								<Typography fontSize={13}>
									{field.displayName}
								</Typography>
							</MenuItem>
						))
					) : (
						renderEmptyView()
					)}
				</Box>
			</Stack>
		</React.Fragment>
	);
}
