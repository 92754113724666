import React, { useState } from "react";
import { Box, Checkbox, Grid, Stack, Typography } from "@mui/material";
import { TableCellText, Tooltip } from "../../../../.././styles/twozo";
import Field from "./Field";
import { styles } from "../../Styles";

const style = {
	fieldContainer: {
		backgroundColor: "#fff",
		border: "1px solid rgba(0, 0, 0, 0.1)",
		borderRadius: "8px",
		minHeight: "42px",
		display: "flex",
		alignItems: "center",
	},
};

export default function ColumnList(props) {
	const { headers, fieldsMappingData, updateFieldsMapping } = props;

	const [isColumnHeaderTooltipOpened, setIsColumnHeaderTooltipOpened] =
		useState(false);
	const [columnHeaderIndex, setColumnHeaderIndex] = useState(null);

	const handleRemoveMappedField = (index) => {
		const updatedFieldsMappingData = fieldsMappingData?.map(
			(fieldData, fieldIndex) => {
				if (index === fieldIndex) {
					if (fieldData.isMapped) {
						const { isMapped, headerIndex } = fieldData;
						let modifiedFieldData = { isMapped, headerIndex };
						return { ...modifiedFieldData, isMapped: false };
					}

					return { ...fieldData, isMapped: true };
				}

				return fieldData;
			}
		);
		updateFieldsMapping(updatedFieldsMappingData);
	};

	const handleOpenTooltip = (event, index) => {
		let isColumnHeaderTooltipOpened =
			event.target.clientWidth < event.target.scrollWidth;
		setIsColumnHeaderTooltipOpened(isColumnHeaderTooltipOpened);
		setColumnHeaderIndex(index);
	};

	// return true when the column is mapped with the field
	const isFieldMapped = (index) => {
		if (!!fieldsMappingData && fieldsMappingData.length > 0) {
			if (fieldsMappingData[index]?.isMapped) {
				return true;
			}
		}
		return false;
	};

	const onClickMappedField = (field, fieldIndex) => {
		let userSelectedFieldData = getUserSelectedFieldData(field, fieldIndex);

		const updatedFieldsMappingData = fieldsMappingData.map(
			(mappingField, index) => {
				if (fieldIndex === index) {
					return { ...userSelectedFieldData };
				}

				return mappingField;
			}
		);
		updateFieldsMapping(updatedFieldsMappingData);
	};

	const getUserSelectedFieldData = (field, index) => {
		if (!field.isSubField) {
			return getUserSelectedFieldWithoutSubField(
				index,
				field.field,
				field.source
			);
		}

		return getUserSelectedFieldWithSubField(
			index,
			field.field,
			field.subField,
			field.source
		);
	};

	const getUserSelectedFieldWithoutSubField = (index, field, sourceType) => {
		return {
			field: field.id,
			name: field.name,
			source: sourceType,
			headerIndex: index,
			isMapped: true,
		};
	};

	const getUserSelectedFieldWithSubField = (
		index,
		field,
		subField,
		sourceType
	) => {
		return {
			field: field.id,
			name: subField.name,
			source: sourceType,
			subType: subField.id,
			headerIndex: index,
			isMapped: true,
		};
	};

	const getFilteredFieldsOrSubFields = (fields, sourceType, isField) => {
		if (isField) {
			let filteredFields = fields?.filter(
				(dataField) =>
					!fieldsMappingData.some(
						(mappingField) =>
							mappingField.source === sourceType &&
							!mappingField.subType &&
							dataField?.id === mappingField?.field
					)
			);

			return filteredFields;
		} else {
			let filteredSubFields = Object.fromEntries(
				Object.entries(fields).map(([key, value]) => [
					key,
					value?.filter(
						(subField) =>
							!fieldsMappingData.some(
								(mappingField) =>
									mappingField?.subType === subField?.id
							)
					),
				])
			);

			return filteredSubFields;
		}
	};

	return (
		<React.Fragment>
			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={0.5}
			>
				<Grid item xs={12} md={6}>
					<Typography style={styles.heading} pl={1}>
						Columns
					</Typography>
				</Grid>

				<Grid item xs={12} md={6}>
					<Typography style={styles.heading} pl={1}>
						Fields
					</Typography>
				</Grid>

				{headers.map((headerData, headerIndex) => (
					<React.Fragment key={headerIndex}>
						<Grid item xs={12} md={6}>
							<Box
								style={style.fieldContainer}
								px={1}
								onMouseOver={(event) =>
									handleOpenTooltip(event, headerIndex)
								}
								onMouseLeave={() =>
									setIsColumnHeaderTooltipOpened(false)
								}
							>
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
									width="100%"
								>
									<Checkbox
										checked={isFieldMapped(headerIndex)}
										onChange={() =>
											handleRemoveMappedField(headerIndex)
										}
									/>
									<Tooltip
										title={headerData}
										placement="top"
										open={
											isColumnHeaderTooltipOpened &&
											columnHeaderIndex === headerIndex
										}
									>
										<TableCellText noWrap>
											{headerData}
										</TableCellText>
									</Tooltip>
								</Stack>
							</Box>
						</Grid>

						<Grid item xs={12} md={6}>
							<Field
								field={fieldsMappingData[headerIndex]}
								getFilteredFieldsOrSubFields={
									getFilteredFieldsOrSubFields
								}
								onClickMappedField={(field) =>
									onClickMappedField(field, headerIndex)
								}
							/>
						</Grid>
					</React.Fragment>
				))}
			</Grid>
		</React.Fragment>
	);
}
