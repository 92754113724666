import React, { useState, useEffect } from "react";
import {
	Box,
	CircularProgress,
	Divider,
	IconButton,
	InputAdornment,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as SendIcon } from "../../../assets/icons/send";
import { default as MoreIcon } from "../../../assets/icons/more";
import { default as DropDownIcon } from "../../../assets/icons/dropDown";
import { ColoredOutlinedInput, TextButton } from "../../../styles/twozo";
import UnKnownImage from "../../../assets/images/contact/unknownContact.png";
import {
	createNote,
	updateNote,
	deleteNote,
	getNotes,
} from "../../../api/note/noteApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Menu from "../../Elements/Menu";
import {
	getDateMonthAndYearFormat,
	getHourAndMinuteFormat,
} from "../../../utils/DateUtils";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";

export default function Notes(props) {
	const theme = useTheme();
	const queryClient = useQueryClient();
	const {
		notesKey,
		timeLineKey,
		source,
		entityId,
		summaryKey,
		isSwipeableSummary,
	} = props;
	const [selectedNoteData, setSelectedNoteData] = useState({});
	const [openNoteMenuElement, setOpenNoteMenuElement] = useState(null);
	const isNoteMenuOpened = Boolean(openNoteMenuElement);
	const [hoveredNoteId, setHoveredNoteId] = useState(null);
	const [noteId, setNoteId] = useState("");
	const countLimit = 100;
	const data = {
		createdBy: "Sawaraj",
		contactImage: UnKnownImage,
	};

	const [noteInput, setNoteInput] = useState("");
	// true indicates it is add note, false indicates it is edit note
	const [isAddNoteMode, setIsAddNoteMode] = useState(true);
	const [notes, setNotes] = useState([]);
	const successNotificationTitle = "Success!";

	const {
		status: noteApiStatus,
		isLoading: isLoadingNotesData,
		data: noteApiData,
	} = useQuery(
		notesKey,
		() => getNotes({ source: source, entityId: entityId }),
		{
			enabled: !!entityId,
			staleTime: 30000,
		}
	);

	useEffect(() => {
		if (noteApiStatus === "success") {
			setNotes(noteApiData?.notes || []);
		}
	}, [noteApiData, noteApiStatus]);

	const createOrUpdateNote = (selectedNoteData) => {
		if (isAddNoteMode && !!noteInput) {
			createMutation.mutate({
				note: noteInput,
				source: source,
				entityId: entityId,
			});
		} else if (!isAddNoteMode && !!noteInput) {
			updateMutation.mutate({
				note: noteInput,
				source: source,
				id: selectedNoteData.id,
				entityId: entityId,
			});
		}
	};

	const createMutation = useMutation(
		async (createdNote) => createNote(createdNote),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(notesKey);
				queryClient.invalidateQueries(timeLineKey);
				setNoteInput("");
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.notesAdded,
				});
				if (summaryKey) {
					queryClient.invalidateQueries(summaryKey);
				}
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		}
	);

	const updateMutation = useMutation(
		async (updatedData) => updateNote(updatedData),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(timeLineKey);
				queryClient.invalidateQueries(notesKey);
				if (summaryKey) {
					queryClient.invalidateQueries(summaryKey);
				}
				setNoteInput("");
				setIsAddNoteMode(true);
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.notesUpdated,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		}
	);

	const deleteNoteData = (id) => {
		deleteMutation.mutate(id);
	};

	const deleteMutation = useMutation(async (id) => deleteNote(id), {
		onSuccess: () => {
			queryClient.invalidateQueries(timeLineKey);
			queryClient.invalidateQueries(notesKey);
			if (summaryKey) {
				queryClient.invalidateQueries(summaryKey);
			}
			setNoteInput("");
			setIsAddNoteMode(true);
			enqueueSnackbar({
				variant: notificationVariants.error,
				title: successNotificationTitle,
				message: notificationMessage.notesDeleted,
			});
		},
		onError: () => {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.errorMessage,
			});
		},
	});

	const onMouseOverNote = (id) => {
		setHoveredNoteId(id);
	};
	const onMouseOutNote = () => {
		if (!isNoteMenuOpened) {
			setHoveredNoteId(null);
		}
	};

	const openNoteMenu = (event, noteData) => {
		setSelectedNoteData(noteData);
		setOpenNoteMenuElement(event.currentTarget);
	};

	const closeNoteMenu = () => {
		setHoveredNoteId(null);
		setOpenNoteMenuElement(null);
	};

	const handleChange = (event) => {
		setNoteInput(event.target.value);
	};

	const handleCreateEditNote = () => {
		createOrUpdateNote(selectedNoteData);
	};

	const handleDeleteNote = (id) => {
		deleteNoteData(id);
		setOpenNoteMenuElement(null);
	};

	const handleEditNote = () => {
		setOpenNoteMenuElement(null);
		setNoteInput(selectedNoteData.note);
		setIsAddNoteMode(false);
	};

	const handleSeeMore = (noteData) => {
		setNoteId(noteData.id);
	};
	const isMoreIconVisible = (noteId) => {
		return hoveredNoteId === noteId && isAddNoteMode;
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="100px"
				anchorEl={openNoteMenuElement}
				open={isNoteMenuOpened}
				onClose={closeNoteMenu}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				sx={{ transform: "translateX(40px)" }}
				style={{
					marginTop: "2px",
				}}
			>
				<MenuItem onClick={handleEditNote}>
					<Typography
						sx={{ fontWeight: 500, fontSize: 13 }}
						color={theme.palette.secondary.contrastText}
					>
						Edit
					</Typography>
				</MenuItem>
				<MenuItem onClick={() => handleDeleteNote(selectedNoteData.id)}>
					<Typography
						sx={{ fontWeight: 500, fontSize: 13 }}
						color={theme.palette.secondary.contrastText}
					>
						Delete
					</Typography>
				</MenuItem>
			</Menu>
			<Box>
				<Typography
					fontSize={14}
					fontWeight={600}
					p={1}
					hidden={isSwipeableSummary}
				>
					Notes
				</Typography>

				<ColoredOutlinedInput
					placeholder="Add Notes Here..."
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								size="small"
								onClick={handleCreateEditNote}
								disabled={
									createMutation.isLoading ||
									updateMutation.isLoading
								}
							>
								{SendIcon(
									20,
									20,
									theme.palette.primary.main,
									"2"
								)}
							</IconButton>
						</InputAdornment>
					}
					value={noteInput}
					onChange={handleChange}
					maxRows={4}
					multiline
					fullWidth
				/>

				{isLoadingNotesData ? (
					<Stack alignItems="center" p={5}>
						<CircularProgress size="30px" />
					</Stack>
				) : (
					<Box
						mx={-1}
						mt={0.5}
						sx={{
							maxBlockSize: "300px",
							overflowY: "auto",
						}}
					>
						{notes?.map((noteData, index) => (
							<Box key={noteData.id}>
								<Stack
									direction="row"
									spacing={1}
									onMouseOver={() =>
										onMouseOverNote(noteData.id)
									}
									onMouseOut={onMouseOutNote}
									sx={{
										backgroundColor:
											hoveredNoteId === noteData.id
												? "#F4F5F5"
												: null,
									}}
									px={2}
									py={1.5}
								>
									<img
										src={data.contactImage}
										style={{ marginTop: ".5px" }}
										alt="profile"
										width="23px"
										height="23px"
									/>

									<Stack width="100%">
										<Stack
											direction="row"
											alignItems="center"
											justifyContent="space-between"
										>
											<Stack
												direction="row"
												spacing={1}
												alignItems="center"
											>
												<Typography fontSize={13}>
													{noteData?.owner?.name}
												</Typography>

												<Typography fontSize={13}>
													&#8226;
												</Typography>

												<Typography
													fontSize={13}
													color={"rgba(0, 0, 0, 0.6)"}
												>
													{getDateMonthAndYearFormat(
														noteData.updatedTime
													)}
													,{" "}
													{getHourAndMinuteFormat(
														noteData.updatedTime
													)}
												</Typography>
											</Stack>
											<Box
												display="flex"
												sx={{ height: "23px" }}
											>
												{isMoreIconVisible(
													noteData.id
												) && (
													<IconButton
														onClick={(event) =>
															openNoteMenu(
																event,
																noteData
															)
														}
														size="small"
														sx={{
															backgroundColor:
																selectedNoteData?.id ===
																	noteData.id &&
																isNoteMenuOpened
																	? theme
																			.palette
																			.secondary
																			.main
																	: null,
															"&:hover": {
																backgroundColor:
																	theme
																		.palette
																		.secondary
																		.main,
															},
														}}
													>
														{MoreIcon(
															18,
															18,
															theme.palette
																.primary.main
														)}
													</IconButton>
												)}
											</Box>
										</Stack>

										{noteData.note.length <= countLimit ? (
											<Typography
												component="pre"
												fontSize={13}
												whiteSpace="break-spaces"
												sx={{ wordBreak: "break-word" }}
											>
												{noteData.note}
											</Typography>
										) : (
											<Stack spacing={1}>
												<Typography
													component="pre"
													fontSize={13}
													whiteSpace="break-spaces"
													sx={{
														wordBreak: "break-word",
													}}
												>
													{noteData.id === noteId
														? noteData.note
														: noteData.note.slice(
																0,
																countLimit
															)}
												</Typography>

												{noteData.id === noteId ? (
													<TextButton
														size="small"
														sx={{
															width: "85px",
															fontWeight: 500,
														}}
														endIcon={
															<Box
																style={{
																	transform:
																		"rotate(180deg)",
																}}
															>
																{DropDownIcon(
																	16,
																	16,
																	theme
																		.palette
																		.primary
																		.main
																)}
															</Box>
														}
														onClick={() =>
															handleSeeMore("")
														}
													>
														See Less
													</TextButton>
												) : (
													<TextButton
														size="small"
														sx={{
															width: "85px",
															fontWeight: 500,
														}}
														endIcon={DropDownIcon(
															16,
															16,
															theme.palette
																.primary.main
														)}
														onClick={() =>
															handleSeeMore(
																noteData
															)
														}
													>
														See More
													</TextButton>
												)}
											</Stack>
										)}
									</Stack>
								</Stack>

								{notes.length - 1 !== index ? (
									<Box>
										<Divider
											sx={{
												margin: "0px 16px",
												opacity: hoveredNoteId
													? 0
													: null,
											}}
										/>
									</Box>
								) : null}
							</Box>
						))}
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}
