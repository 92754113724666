import React, { useRef, useState } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { TableCellText } from "../../../../../.././styles/twozo";
import { default as DropdownIcon } from "../../../../../.././assets/icons/dropDownCentered";
import { Tooltip } from "../../../../../.././styles/twozo";
import Menu from "../../../../.././Elements/Menu";
import FieldOption from "./FieldOption";

export default function Field(props) {
	const { field, onClickMappedField, getFilteredFieldsOrSubFields } = props;

	const theme = useTheme();
	const [fieldMenuElement, setFieldMenuElement] = useState(null);
	const isImportFieldMenuOpened = Boolean(fieldMenuElement);
	const [isFieldTooltipOpened, setIsFieldTooltipOpened] = useState(false);
	const menuContainerRef = useRef(null);

	const style = {
		fieldContainer: {
			backgroundColor: "#fff",
			border: `1px solid ${
				isImportFieldMenuOpened
					? theme.palette.primary.main
					: "rgba(0, 0, 0, 0.1)"
			}`,
			borderRadius: "8px",
			minHeight: "42px",
			display: "flex",
			alignItems: "center",
			cursor: "pointer",
		},
	};

	const getFieldLabel = () => {
		if (field?.isMapped && !!field?.name) {
			return field.name;
		}

		return (
			<Typography
				variant="div"
				style={{ opacity: 0.6, fontSize: "14px" }}
			>
				- Select -
			</Typography>
		);
	};

	const openFieldOptionMenu = (event) => {
		setFieldMenuElement(event.currentTarget);
	};

	const handleOpenTooltip = (event) => {
		setIsFieldTooltipOpened(
			event.target.clientWidth < event.target.scrollWidth
		);
	};

	const closeImportFieldsMenu = () => {
		setFieldMenuElement(null);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="480px"
				anchorEl={fieldMenuElement}
				open={isImportFieldMenuOpened}
				onClose={closeImportFieldsMenu}
				container={menuContainerRef.current}
				style={{
					marginTop: "4px",
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				sx={{
					"& .MuiMenu-list": {
						paddingTop: 0,
					},
					transform: "translateX(-55px)",
				}}
				PaperProps={{
					style: {
						height: "50%",
						overflowY: "auto",
						marginLeft: "15px",
					},
				}}
			>
				{isImportFieldMenuOpened && (
					<FieldOption
						onClickMappedField={onClickMappedField}
						closeImportFieldsMenu={closeImportFieldsMenu}
						getFilteredFieldsOrSubFields={
							getFilteredFieldsOrSubFields
						}
					/>
				)}
			</Menu>

			<Box
				style={style.fieldContainer}
				px={1.5}
				onClick={openFieldOptionMenu}
				onMouseOver={handleOpenTooltip}
				onMouseLeave={() => setIsFieldTooltipOpened(false)}
			>
				<Tooltip
					title={getFieldLabel()}
					open={isFieldTooltipOpened}
					placement="top"
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						width="100%"
					>
						<TableCellText noWrap>{getFieldLabel()}</TableCellText>

						<Box
							style={{
								transform:
									isImportFieldMenuOpened && "rotate(180deg)",
							}}
						>
							{DropdownIcon(14, 14, "#666666")}
						</Box>
					</Stack>
				</Tooltip>
			</Box>
		</React.Fragment>
	);
}
