import React, { useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	MenuItem,
	MenuList,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DropdownIcon } from "../../../.././assets/icons/dropDownCentered";
import { TableCellText, TableHeaderLabel } from "../../../.././styles/twozo";
import Menu from "../../.././Elements/Menu";
import {
	useImportUploadedFile,
	usePreviewList,
} from "../../../.././hooks/services/import";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../.././utils/notification/notificationConfig";
import { notificationMessage } from "../../../.././utils/notification/notificationMessages";

export default function PreviewAndFinish(props) {
	const {
		updatedMappingId,
		goNextStep,
		assignIdOnImport,
		goPreviousStep,
		importedFileStateId,
	} = props;

	const theme = useTheme();
	const [isMergeOrMultipleRecords, setIsMergeOrMultipleRecords] =
		useState(true);
	const [mergeOptionElement, setMergeOptionElement] = useState(null);
	const isMergeOptionMenuOpened = Boolean(mergeOptionElement);

	const getUpdatedMappingIdOrReadFileId = () => {
		if (updatedMappingId) {
			return updatedMappingId;
		}

		return importedFileStateId;
	};
	const updatedMappingIdOrReadFileId = getUpdatedMappingIdOrReadFileId();

	const importMutation = useImportUploadedFile();
	const { data: previewData, isLoading } = usePreviewList(
		updatedMappingIdOrReadFileId
	);

	const openMergeOptionMenu = (event) => {
		setMergeOptionElement(event.currentTarget);
	};

	const closeMergeOptionMenu = () => {
		setMergeOptionElement(null);
	};

	const setMergeContactOption = (mergeContact) => {
		setIsMergeOrMultipleRecords(mergeContact);
		closeMergeOptionMenu();
	};

	const handleImport = () => {
		let requestData = getImportRequest();
		importMutation.mutate(requestData, {
			onSuccess: (data) => {
				assignIdOnImport(data.id);
				goNextStep();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});
	};

	const getImportRequest = () => {
		let requestData = {};
		requestData.mergeDuplicates = isMergeOrMultipleRecords;
		requestData.id = updatedMappingIdOrReadFileId;

		return requestData;
	};

	const handleBackToMapping = () => {
		goPreviousStep();
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="350px"
				anchorEl={mergeOptionElement}
				open={isMergeOptionMenuOpened}
				onClose={closeMergeOptionMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				style={{
					marginTop: "4px",
				}}
				sx={{
					"& .MuiMenu-list": {
						paddingTop: "5px",
					},
				}}
			>
				<MenuList sx={{ padding: "0px" }}>
					<MenuItem
						onClick={() => setMergeContactOption(true)}
						selected={isMergeOrMultipleRecords}
					>
						<Box px={2} py={0.5}>
							<Typography fontSize={13}>Merge Data</Typography>

							<Typography
								fontSize={13}
								color={"rgba(0, 0, 0, 0.6)"}
							>
								One Record for the duplicated data
							</Typography>
						</Box>
					</MenuItem>

					<MenuItem
						onClick={() => setMergeContactOption(false)}
						selected={!isMergeOrMultipleRecords}
					>
						<Box px={2} py={0.5}>
							<Typography fontSize={13}>
								Create Multiple Records
							</Typography>

							<Typography
								fontSize={13}
								color={"rgba(0, 0, 0, 0.6)"}
							>
								Separated records for the duplicated data
							</Typography>
						</Box>
					</MenuItem>
				</MenuList>
			</Menu>

			{isLoading ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="60vh"
					spacing={1}
				>
					<CircularProgress />
				</Stack>
			) : (
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
					}}
				>
					<Box
						style={{
							flex: "1 1 auto",
							overflowY: "auto",
							minHeight: "0px",
						}}
					>
						<Box
							style={{
								display: "flex",
								flexDirection: "column",
								height: "100%",
								width: "100%",
							}}
						>
							<Box
								sx={{
									backgroundColor:
										theme.palette.secondary.main,
									borderRadius: "8px",
									m: 1,
									p: 2,
								}}
							>
								<Stack
									direction="row"
									justifyContent="space-between"
									spacing={2}
								>
									<Stack>
										<Typography
											fontSize={14}
											fontWeight={500}
										>
											What to do with duplicates if found?
										</Typography>

										<Typography
											fontSize={14}
											color={"rgba(0, 0, 0, 0.6)"}
										>
											Duplicates are checked only for
											contacts and companies.{" "}
											<span
												style={{
													color: theme.palette
														.secondary.contrastText,
													cursor: "pointer",
												}}
											>
												Learn More
											</span>
										</Typography>
									</Stack>

									<Stack direction="row" spacing={3}>
										<Divider
											orientation="vertical"
											flexItem
										/>

										<Stack
											direction="row"
											alignItems="center"
											justifyContent="space-between"
											spacing={2}
											style={{
												minWidth: "330px",
												cursor: "pointer",
											}}
											onClick={openMergeOptionMenu}
										>
											<Box>
												<Typography
													fontSize={14}
													fontWeight={500}
													color={
														theme.palette.secondary
															.contrastText
													}
												>
													{isMergeOrMultipleRecords
														? "Merge Data"
														: "Create Multiple Records"}
												</Typography>

												<Typography
													fontSize={14}
													color={"rgba(0, 0, 0, 0.6)"}
												>
													{isMergeOrMultipleRecords
														? "One record for the duplicated data"
														: "Separated records for the duplicated data"}
												</Typography>
											</Box>

											{DropdownIcon(16, 16, "#666")}
										</Stack>
									</Stack>
								</Stack>
							</Box>

							<Box
								mt={2}
								style={{
									flex: "1 1 auto",
									overflowY: "auto",
									paddingTop: "10px",
								}}
							>
								<Stack direction="column" spacing={1}>
									{previewData?.map((preview, index) => (
										<Box key={index}>
											<Typography
												fontSize={15}
												fontWeight={600}
												px={2}
												pb={1}
											>
												{preview?.source}
											</Typography>

											<Box
												style={{
													border: "1px solid rgba(0, 0, 0, 0.1)",
													borderRadius: "8px",
													flex: "1 1 auto",
													overflow: "auto",
													minHeight: "0px",
												}}
											>
												<TableContainer>
													<Table size="small">
														<TableHead>
															<TableRow>
																{preview?.headers?.map(
																	(
																		header,
																		index
																	) => (
																		<TableCell
																			key={
																				index
																			}
																			style={{
																				minWidth:
																					"250px",
																			}}
																		>
																			<Stack
																				direction="row"
																				spacing={
																					0.5
																				}
																				alignItems="center"
																			>
																				<TableHeaderLabel>
																					{
																						header
																					}
																				</TableHeaderLabel>
																			</Stack>
																		</TableCell>
																	)
																)}
															</TableRow>
														</TableHead>
														<TableBody>
															{
																// shown only three rows for each source
																preview?.rows
																	.slice(0, 3)
																	.map(
																		(
																			row,
																			index
																		) => (
																			<TableRow
																				key={
																					index
																				}
																			>
																				{row.map(
																					(
																						rowData,
																						rowIndex
																					) => (
																						<TableCell
																							key={
																								rowIndex
																							}
																							style={{
																								borderBottom:
																									index ===
																										preview
																											?.rows
																											.length -
																											(preview
																												?.rows
																												.length -
																												2) &&
																									"none",
																							}}
																						>
																							<TableCellText
																								sx={{
																									whiteSpace:
																										"nowrap",
																								}}
																							>
																								{rowData
																									? rowData
																									: "-"}
																							</TableCellText>
																						</TableCell>
																					)
																				)}
																			</TableRow>
																		)
																	)
															}
														</TableBody>
													</Table>
												</TableContainer>
											</Box>
										</Box>
									))}
								</Stack>
							</Box>
						</Box>
					</Box>

					<Box>
						<Stack
							direction="row"
							justifyContent="space-between"
							p={2}
						>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => handleBackToMapping()}
								disableElevation
							>
								Back
							</Button>

							<Button
								variant="contained"
								onClick={handleImport}
								disableElevation
								disabled={importMutation.isLoading}
							>
								Start Importing
							</Button>
						</Stack>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
