import { leadGenerationIds, permissionTypes, type } from "..";

export const GetLeadGenerationConfigForPermission = (permissions) => {
	return [
		{
			id: leadGenerationIds.webFormTracking,
			name: "Web Form Tracking",
			access: permissions.webFormTracking.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: permissionTypes.view,
						name: "View",
						value: permissions.webFormTracking.view,
						disabled: !permissions.webFormTracking.access,
					},
					{
						id: permissionTypes.create,
						name: "Create",
						value: permissions.webFormTracking.create,
						disabled: !permissions.webFormTracking.access,
					},
					{
						id: permissionTypes.edit,
						name: "Edit",
						value: permissions.webFormTracking.edit,
						disabled: !permissions.webFormTracking.access,
					},
					{
						id: permissionTypes.delete,
						name: "Delete",
						value: permissions.webFormTracking.delete,
						disabled: !permissions.webFormTracking.access,
					},
				],
			},
		},
	];
};
