import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import UserManagementDialog from "../../../../Elements/UserManagementDialog";
import {
	useDeactivateUserMutation,
	useUserList,
} from "../../../../../hooks/services/userManagement/user";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { default as UserProfileImage } from "../../../../../assets/images/contact/unknownContact.png";

export default function DeactivateDialog(props) {
	const { open, onClose, userId, selectedUserFilterId, ...others } = props;

	const { data: users, status: userStatus } = useUserList();

	const deactivateUserMutation =
		useDeactivateUserMutation(selectedUserFilterId);

	const [userList, setUserList] = useState([]);
	const [selectedUserId, setSelectedUserId] = useState();
	const [selectedUserName, setSelectedUserName] = useState("");

	useEffect(() => {
		setSelectedUserId(userId);
	}, [userId]);

	useEffect(() => {
		if (userStatus === "success" && users.length > 0 && userId) {
			const updatedUserList = users?.map((user) => {
				if (user.value === userId) {
					return {
						...user,
						name: "Same user (Leave as is)",
					};
				} else return user;
			});

			setUserList(updatedUserList);
		}
	}, [userStatus, users, userId]);

	const onChange = (event) => {
		setSelectedUserId(event.target.value);
	};

	const deactivateUser = (userId, selectedUserId) => {
		const deactivateUserRequestData = {
			userId: userId,
		};

		if (userId !== selectedUserId) {
			deactivateUserRequestData.targetUserId = selectedUserId;
		}

		deactivateUserMutation.mutate(deactivateUserRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.userDeactivated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const selectUserToTransfer = (user) => {
		setSelectedUserName(user.name);
	};

	return (
		<React.Fragment>
			<UserManagementDialog open={open} onClose={onClose} {...others}>
				<Box px={3} pb={3}>
					<Typography fontSize={13} fontWeight={500} pb={0.5}>
						Assign to
					</Typography>

					<Box>
						<Select
							size="small"
							sx={{
								width: "100%",
							}}
							onChange={onChange}
							value={selectedUserId}
						>
							{userList?.map((user) => (
								<MenuItem
									value={user.value}
									key={user.value}
									style={{ minHeight: "40px" }}
									onClick={() => selectUserToTransfer(user)}
									disabled={!user.config.isActive}
								>
									<Stack
										direction="row"
										spacing={2}
										alignItems="center"
									>
										{userId !== user.value && (
											<img
												alt="user"
												src={UserProfileImage}
												width="20px"
												height="20px"
											/>
										)}

										<Typography fontSize={14}>
											{user.name}
										</Typography>
									</Stack>
								</MenuItem>
							))}
						</Select>
					</Box>

					<Typography fontSize={13} fontWeight={400} pt={0.5}>
						{userId === selectedUserId
							? "Assignment of records remain unchanged, if same user is selected."
							: `All the records will be re-assigned to ${selectedUserName}`}
					</Typography>

					<Box pt={2}>
						<Stack
							direction="row"
							spacing={2}
							justifyContent="flex-end"
						>
							<Button
								onClick={onClose}
								variant="contained"
								color="secondary"
								disableElevation
							>
								Cancel
							</Button>

							<Button
								variant="contained"
								color="error"
								disableElevation
								onClick={() =>
									deactivateUser(userId, selectedUserId)
								}
							>
								Deactivate
							</Button>
						</Stack>
					</Box>
				</Box>
			</UserManagementDialog>
		</React.Fragment>
	);
}
