import React, { useEffect, useState } from "react";
import {
	Box,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../../../assets/icons/close";
import { default as AddIcon } from "../../../../../../../assets/icons/add";
import { TextButton } from "../../../../../../../styles/twozo";
import CurrencyMenu from "../../../../../../Elements/CurrencyMenu";
import { useCurrenyList } from "../../../../../../../hooks/services/common/currency";

export default function PriceTagsBaseComponent({
	onChange,
	value,
	subField,
	error,
}) {
	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;

		if (!!subField && Array.isArray(subField)) {
			subField.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const theme = useTheme();
	const currencyField = getSubFieldObject("CURRENCY");
	const unitPriceField = getSubFieldObject("UNIT_PRICE");
	const taxField = getSubFieldObject("TAX");
	const discountField = getSubFieldObject("DISCOUNT");

	const [priceData, setPriceData] = useState([
		{
			[currencyField.id]: "",
			[unitPriceField.id]: "",
			[taxField.id]: "",
			[discountField.id]: "",
		},
	]);
	const [selectedCurrencies, setSelectedCurrencies] = useState([]);

	const [selectedSubFieldId, setSelectedSubFieldId] = useState(null);

	const { data: currencyOptions } = useCurrenyList();

	const styles = {
		tableContainer: {
			backgroundColor: theme.palette.primary.main + "20",
			borderRadius: "8px",
			p: 1,
		},
		tableHead: {
			fontSize: 13,
			fontWeight: 500,
			color: "#000",
			opacity: "0.6",
		},
		tableCell: {
			fontSize: 14,
		},
		editableTableCell: {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
		},
	};

	useEffect(() => {
		if (!!value && Array.isArray(value)) {
			setPriceData(value);
		}
	}, [value]);

	const addPrice = () => {
		setSelectedSubFieldId(`currency_${priceData.length}`);
		setPriceData([...priceData, getNewDefaultSinglePriceUnit()]);
	};

	const getNewDefaultSinglePriceUnit = () => {
		return {
			[currencyField.id]: "",
			[unitPriceField.id]: "",
			[taxField.id]: "",
			[discountField.id]: "",
		};
	};

	const handleTableCell = (event) => {
		setSelectedSubFieldId(event.currentTarget.id);
	};

	const handleInputChange = (event, index, subFieldId) => {
		const { value } = event.target;
		priceData[index][subFieldId] = value;
		setPriceData(priceData);
		onChange(priceData);
	};

	const handleDeletePrice = (index) => {
		setPriceData((priceData) => {
			priceData.splice(index, 1);
			return [...priceData];
		});

		setSelectedCurrencies((selectedCurrencies) => {
			selectedCurrencies.splice(index, 1);
			return [...selectedCurrencies];
		});
		onChange(priceData);
	};

	const getCurrencyOptionsForPriceTag = (priceData) => {
		return currencyOptions?.filter(
			(currency) =>
				currency.id === priceData[currencyField.id] ||
				!selectedCurrencies.includes(currency.id)
		);
	};

	const onCurrencyInputChange = (currency, index) => {
		let oldCurrencyId = priceData[index][currencyField.id];
		let newCurrencyId = currency.id;

		//remove previous selected currency
		setSelectedCurrencies((selectedCurrencies) =>
			selectedCurrencies.filter((currency) => currency !== oldCurrencyId)
		);
		//add newly selected currency
		selectedCurrencies[index] = newCurrencyId;
		setSelectedCurrencies([...selectedCurrencies]);

		priceData[index][currencyField.id] = newCurrencyId;
		setPriceData([...priceData]);
		onChange(priceData);
	};

	return (
		<React.Fragment>
			<Grid item xs={12}>
				<Box sx={styles.tableContainer}>
					<TableContainer>
						<Table sx={{ width: "100%" }} size="small">
							<TableHead>
								<TableRow>
									<TableCell style={{ width: "25%" }}>
										<Typography sx={styles.tableHead}>
											Currency
										</Typography>
									</TableCell>

									<TableCell
										align="right"
										style={{ width: "25%" }}
									>
										<Typography sx={styles.tableHead}>
											Unit Price
										</Typography>
									</TableCell>

									<TableCell
										align="right"
										style={{ width: "22%" }}
									>
										<Typography sx={styles.tableHead}>
											Tax %
										</Typography>
									</TableCell>

									<TableCell
										align="right"
										style={{ width: "22%" }}
									>
										<Typography sx={styles.tableHead}>
											Discount
										</Typography>
									</TableCell>

									<TableCell sx={{ width: "6%" }}></TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{priceData.map((data, index) => (
									<TableRow key={index}>
										<TableCell
											id={`currency_${index}`}
											onClick={(event) =>
												handleTableCell(event)
											}
											sx={{
												borderBottom:
													selectedSubFieldId ===
														`currency_${index}` &&
													styles.editableTableCell,
												padding: "0px",
											}}
										>
											<CurrencyMenu
												selectedCurrency={
													data[currencyField.id]
												}
												currencyOptions={getCurrencyOptionsForPriceTag(
													data
												)}
												onCurrencyInputChange={
													onCurrencyInputChange
												}
												index={index}
												error={error}
											/>
										</TableCell>

										<TableCell
											align="right"
											id={`unitPrice_${index}`}
											onClick={(event) =>
												handleTableCell(event)
											}
											sx={{
												py: "0px",
												borderBottom:
													selectedSubFieldId ===
														`unitPrice_${index}` &&
													styles.editableTableCell,
											}}
										>
											<TextField
												variant="standard"
												name="UNIT_PRICE"
												value={data[unitPriceField.id]}
												InputProps={{
													disableUnderline: true,
												}}
												onChange={(event) =>
													handleInputChange(
														event,
														index,
														unitPriceField.id
													)
												}
												placeholder="0"
												inputProps={{
													style: {
														textAlign: "right",
														fontSize: "14px",
													},
												}}
												error={!!error}
											/>
										</TableCell>

										<TableCell
											align="right"
											id={`taxPercentage_${index}`}
											onClick={(event) =>
												handleTableCell(event)
											}
											sx={{
												py: "0px",
												borderBottom:
													selectedSubFieldId ===
														`taxPercentage_${index}` &&
													styles.editableTableCell,
											}}
										>
											<TextField
												variant="standard"
												name="TAX"
												value={data[taxField.id]}
												InputProps={{
													disableUnderline: true,
												}}
												onChange={(event) =>
													handleInputChange(
														event,
														index,
														taxField.id
													)
												}
												placeholder="0%"
												inputProps={{
													style: {
														textAlign: "right",
														fontSize: "14px",
													},
												}}
											/>
										</TableCell>

										<TableCell
											align="right"
											id={`discount_${index}`}
											onClick={(event) =>
												handleTableCell(event)
											}
											sx={{
												py: "0px",
												borderBottom:
													selectedSubFieldId ===
														`discount_${index}` &&
													styles.editableTableCell,
											}}
										>
											<TextField
												variant="standard"
												name="DISCOUNT"
												value={data[discountField.id]}
												InputProps={{
													disableUnderline: true,
												}}
												onChange={(event) =>
													handleInputChange(
														event,
														index,
														discountField.id
													)
												}
												placeholder="0%"
												inputProps={{
													style: {
														textAlign: "right",
														fontSize: "14px",
													},
												}}
											/>
										</TableCell>

										<TableCell sx={styles.tableHead}>
											{priceData.length === 1 ? null : (
												<Box
													display="flex"
													onClick={() =>
														handleDeletePrice(index)
													}
												>
													{CloseIcon("20", "20")}
												</Box>
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<Box pl={2}>
						<TextButton
							size="small"
							startIcon={AddIcon(
								13,
								13,
								theme.palette.secondary.contrastText
							)}
							onClick={addPrice}
						>
							Add Price
						</TextButton>
					</Box>
				</Box>
			</Grid>
		</React.Fragment>
	);
}
