import { useEffect, useState } from "react";
import {
	Box,
	Button,
	Grid,
	IconButton,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import MenuSearchBar from "../../MenuSearchBar";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { default as DropdownIcon } from "../../../../assets/icons/dropDownCentered";
import { Controller, useForm } from "react-hook-form";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import { removeFieldsWithEmptyValues } from "../../../../utils/common";
import { getDropdownDataByName } from "../../../../utils/dropdownData";

export default function AddressFieldEditForm(params) {
	const { subFields, onClose, value, onSave, field } = params;

	const {
		register,
		handleSubmit,
		clearErrors,
		setValue,
		control,
		formState: { errors },
	} = useForm({ values: value });

	const [subField, setSubField] = useState({});

	useEffect(() => {
		if (!!subFields && Array.isArray(subFields)) {
			let subField = {};
			subFields.forEach((field) => {
				subField[field.name] = field;
			});
			setSubField(subField);
		}
	}, [subFields]);

	const countryList = getDropdownDataByName("COUNTRY_CODE");
	const [searchCountryName, setSearchCountryName] = useState("");
	const [filteredCountryList, setFilteredCountryList] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState(null);

	useEffect(() => {
		if (value[subField?.COUNTRY?.id]) {
			setSelectedCountry(value[subField?.COUNTRY?.id]);
		}
	}, [value, subField]);

	useEffect(() => {
		const getFilteredCountryList = () => {
			if (Array.isArray(countryList)) {
				if (searchCountryName.trim()) {
					return countryList?.filter((country) =>
						//filter for search
						country.name
							.toLowerCase()
							.startsWith(searchCountryName.toLowerCase())
					);
				}
				return countryList;
			}
			return [];
		};
		setFilteredCountryList(getFilteredCountryList());
	}, [countryList, searchCountryName]);

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					{countryList?.length === 0
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchCountryName(value);
	};

	const getSelectedCountryName = (value) => {
		if (!value) return "";
		const selectedCountry = countryList.find(
			(country) => country.value === value
		);
		return selectedCountry?.name ?? "";
	};

	const handleRemoveValue = (event, onChange) => {
		event.stopPropagation();
		setSelectedCountry(null);
		onChange("");
	};

	const handleSave = (addressData) => {
		removeFieldsWithEmptyValues(addressData);
		onSave(addressData);
	};

	return (
		<>
			<Box maxWidth="500px" px={2} py={1}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					rowSpacing={1}
				>
					<Grid item xs={12} md={12}>
						<Stack spacing={0.5}>
							<TextField
								{...register(
									subField?.ADDRESS_LINE1?.id + "",
									handleFieldValidation(field)
								)}
								placeholder={
									subField?.ADDRESS_LINE1?.placeHolder ||
									"Address Line 1"
								}
								onChange={(event) => {
									clearErrors(
										subField?.ADDRESS_LINE1?.id + ""
									);
									setValue(
										subField?.ADDRESS_LINE1?.id + "",
										event.target.value
									);
								}}
								FormHelperTextProps={{
									sx: {
										fontSize: 13,
										ml: "8px",
									},
								}}
								error={!!errors[subField?.ADDRESS_LINE1?.id]}
								helperText={
									!!errors[subField?.ADDRESS_LINE1?.id] &&
									errors[subField?.ADDRESS_LINE1?.id]?.message
								}
							/>
						</Stack>
					</Grid>

					<Grid item xs={12} md={12}>
						<Stack spacing={0.5}>
							<TextField
								{...register(subField?.ADDRESS_LINE2?.id + "")}
								placeholder={
									subField?.ADDRESS_LINE2?.placeHolder ||
									"Address Line 2"
								}
								onChange={(event) => {
									clearErrors(
										subField?.ADDRESS_LINE2?.id + ""
									);
									setValue(
										subField?.ADDRESS_LINE2?.id + "",
										event.target.value
									);
								}}
							/>
						</Stack>
					</Grid>

					<Grid item xs={12} md={5.9}>
						<Stack spacing={0.5}>
							<TextField
								{...register(subField?.CITY?.id + "")}
								placeholder={
									subField?.CITY?.placeHolder || "City"
								}
								onChange={(event) => {
									setValue(
										subField?.CITY?.id + "",
										event.target.value
									);
								}}
							/>
						</Stack>
					</Grid>

					<Grid item xs={12} md={5.9}>
						<Stack spacing={0.5}>
							<TextField
								{...register(subField?.STATE?.id + "")}
								placeholder={
									subField?.STATE?.placeHolder || "State"
								}
								onChange={(event) => {
									setValue(
										subField?.STATE?.id + "",
										event.target.value
									);
								}}
							/>
						</Stack>
					</Grid>

					<Grid item xs={12} md={5.9}>
						<Stack spacing={0.5}>
							<Controller
								name={subField?.COUNTRY?.id + ""}
								control={control}
								render={({ field: { onChange } }) => (
									<Select
										size="small"
										value={selectedCountry || ""}
										onChange={(event) => {
											clearErrors(
												subField?.COUNTRY?.id + ""
											);
											onChange(event.target.value);
											setSelectedCountry(
												event.target.value
											);
										}}
										MenuProps={{
											autoFocus: false,
											PaperProps: {
												style: {
													maxHeight: 300,
													marginTop: "5px",
												},
											},
										}}
										renderValue={(selected) => (
											<Typography
												fontSize={14}
												color={
													selected
														? "#000"
														: "rgba(0, 0, 0, 0.6)"
												}
												style={{
													opacity: selected ? 1 : 0.6,
												}}
											>
												{getSelectedCountryName(
													selected
												) ||
													subField?.COUNTRY
														?.placeHolder ||
													"Country"}
											</Typography>
										)}
										IconComponent={(props) =>
											selectedCountry ? (
												<Box pr={1.6}>
													<IconButton
														sx={{ padding: 0 }}
														onClick={(event) =>
															handleRemoveValue(
																event,
																onChange
															)
														}
													>
														{CloseIcon(
															18,
															18,
															"rgba(0, 0, 0, 0.6)"
														)}
													</IconButton>
												</Box>
											) : (
												<Box {...props}>
													{DropdownIcon(14, 14)}
												</Box>
											)
										}
										displayEmpty
										onOpen={() => setSearchCountryName("")}
									>
										<Box
											p={0.5}
											marginTop="-8px"
											sx={{
												position: "sticky",
												top: 0,
												zIndex: 1,
												backgroundColor: "#fff",
											}}
										>
											<MenuSearchBar
												value={searchCountryName}
												onChange={handleSearchValue}
											/>
										</Box>

										{filteredCountryList?.length > 0
											? filteredCountryList.map(
													(country) => (
														<MenuItem
															value={
																country.value
															}
															key={country.value}
														>
															<Stack
																direction="row"
																spacing={1}
																alignItems="center"
															>
																<Typography
																	fontSize={
																		14
																	}
																>
																	{
																		country.name
																	}
																</Typography>
															</Stack>
														</MenuItem>
													)
												)
											: renderEmptyView()}
									</Select>
								)}
							></Controller>
						</Stack>
					</Grid>

					<Grid item xs={12} md={5.9}>
						<Stack spacing={0.5}>
							<TextField
								{...register(subField?.ZIPCODE?.id + "")}
								placeholder={
									subField?.ZIPCODE?.placeHolder || "Pincode"
								}
								onChange={(event) => {
									setValue(
										subField?.ZIPCODE?.id + "",
										event.target.value
									);
								}}
							/>
						</Stack>
					</Grid>
				</Grid>

				<Stack
					direction="row"
					spacing={2}
					justifyContent="end"
					width="100%"
					p="16px 8px 8px 0px"
				>
					<Box display="flex">
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={onClose}
						>
							Cancel
						</Button>
					</Box>

					<Box display="flex">
						<Button
							variant="contained"
							color="primary"
							disableElevation
							onClick={handleSubmit(handleSave)}
						>
							Save
						</Button>
					</Box>
				</Stack>
			</Box>
		</>
	);
}
