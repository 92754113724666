const languages = [
	{
		name: "Arabic",
		displayName: "عربي",
		value: 1,
	},
	{
		name: "Catalan",
		displayName: "Català",
		value: 2,
	},
	{
		name: "Czech",
		displayName: "Čeština",
		value: 3,
	},
	{
		name: "Chinese",
		displayName: "简体中文 (Simplified)",
		value: 4,
	},
	{
		name: "Chinese",
		displayName: "繁體中文 (Traditional)",
		value: 5,
	},
	{
		name: "Danish",
		displayName: "Dansk",
		value: 6,
	},
	{
		name: "Dutch",
		displayName: "Nederlands",
		value: 7,
	},
	{
		name: "English",
		displayName: "English (United Kingdom)",
		value: 8,
	},
	{
		name: "English",
		displayName: "English (United States)",
		value: 9,
	},
	{
		name: "Estonian",
		displayName: "Eesti keel",
		value: 10,
	},
	{
		name: "Finnish",
		displayName: "Suomi",
		value: 11,
	},
	{
		name: "French",
		displayName: "Français",
		value: 12,
	},
	{
		name: "German",
		displayName: "Deutsch",
		value: 13,
	},
	{
		name: "Hebrew",
		displayName: "עִברִית",
		value: 14,
	},
	{
		name: "Hungarian",
		displayName: "Magyar",
		value: 15,
	},
	{
		name: "Indonesian",
		displayName: "Bahasa Indonesia",
		value: 16,
	},
	{
		name: "Italian",
		displayName: "Italiano",
		value: 17,
	},
	{
		name: "Japanese",
		displayName: "日本語",
		value: 18,
	},
	{
		name: "Korean",
		displayName: "한국어",
		value: 19,
	},
	{
		name: "Latvian",
		displayName: "Latviešu",
		value: 20,
	},
	{
		name: "Norwegian",
		displayName: "Norsk",
		value: 21,
	},
	{
		name: "Polish",
		displayName: "Polski",
		value: 22,
	},
	{
		name: "Portuguese",
		displayName: "Português (Brazil)",
		value: 23,
	},
	{
		name: "Portuguese",
		displayName: "Português (Portugal)",
		value: 24,
	},
	{
		name: "Romanian",
		displayName: "Română",
		value: 25,
	},
	{
		name: "Russian",
		displayName: "русский",
		value: 26,
	},
	{
		name: "Slovak",
		displayName: "Slovenčina",
		value: 27,
	},
	{
		name: "Slovenian",
		displayName: "Slovenčina",
		value: 28,
	},
	{
		name: "Spanish",
		displayName: "Español (Latin America)",
		value: 29,
	},
	{
		name: "Spanish",
		displayName: "Español (Spain)",
		value: 30,
	},
	{
		name: "Swedish",
		displayName: "Svenska",
		value: 31,
	},
	{
		name: "Thai",
		displayName: "ภาษาไทย",
		value: 32,
	},
	{
		name: "Turkish",
		displayName: "Türkçe",
		value: 33,
	},
	{
		name: "Ukrainian",
		displayName: "Українська",
		value: 34,
	},
	{
		name: "Vietnamese",
		displayName: "Tiếng Việt",
		value: 35,
	},
];

export default languages;
