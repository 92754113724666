import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getUserListKey,
	getUserDataKey,
	getUserTableDataKey,
	getUserSummaryKey,
	getUserAuditLogKey,
	getUserToDoAcitivityKey,
	getUserCompletedActivityKey,
	getUserDetailsKey,
} from "../../../../utils/queryKeys/userManagement/user";
import {
	activateUser,
	addUser,
	deactivateUser,
	getAuditlog,
	getUserData,
	getUserDetailsList,
	getUserList,
	getUserSummaryData,
	getUserTableData,
	transferUserRecords,
	updateUser,
	userPartialBulkUpdate,
	userReinvite,
} from "../../../../api/userManagement/user/userApi";
import { getUserActivities } from "../../../../api/activity/activityApi";

const useUserTableData = (stateId, searchValue, fieldToSort) => {
	return useQuery(
		getUserTableDataKey(stateId, searchValue, fieldToSort),
		() => getUserTableData(stateId, searchValue, fieldToSort)
	);
};

const useUserDetailsList = () => {
	let userDetailsListKey = getUserDetailsKey();
	return useQuery(userDetailsListKey, () => getUserDetailsList(), {
		select: (data) => data.users,
	});
};

const useUserList = (searchedValue) => {
	return useQuery(
		getUserListKey(searchedValue),
		() => getUserList(searchedValue),
		{
			select: (userList) => userList?.options,
		}
	);
};

const useUserData = (id) => {
	return useQuery(getUserDataKey(id), () => getUserData(id));
};

const useUserSummaryData = (id) => {
	return useQuery(getUserSummaryKey(id), () => getUserSummaryData(id));
};

const useAuditlogData = (id) => {
	return useQuery(getUserAuditLogKey(id), () => getAuditlog(id), {
		select: (auditlogData) => auditlogData?.list,
	});
};

const useUserToDoActivities = (id) => {
	let isDone = false;
	return useQuery(
		getUserToDoAcitivityKey(id),
		() => getUserActivities(id, isDone),
		{
			select: (userActivities) => userActivities?.activities,
		}
	);
};

const useUserCompletedActivties = (id) => {
	let isDone = true;
	return useQuery(
		getUserCompletedActivityKey(id),
		() => getUserActivities(id, isDone),
		{
			select: (userActivities) => userActivities?.activities,
		}
	);
};

const useInvalidateUserList = (selectedUserFilterId) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(
			getUserTableDataKey(selectedUserFilterId)
		);
	};
};

const useInvalidateUserDropdownList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getUserListKey());
	};
};

const useUserActivateMutation = (selectedUserFilterId) => {
	let invalidateUserList = useInvalidateUserList(selectedUserFilterId);
	let invalidateUserDropdownList = useInvalidateUserDropdownList();
	return useMutation(activateUser, {
		onSuccess: () => {
			invalidateUserList();
			invalidateUserDropdownList();
		},
	});
};

const useAddUserMutation = (selectedUserFilterId) => {
	let invalidateUserList = useInvalidateUserList(selectedUserFilterId);
	return useMutation(addUser, {
		onSuccess: () => {
			invalidateUserList();
		},
	});
};

const useUpdateUserMutation = (selectedUserFilterId) => {
	let invalidateUserList = useInvalidateUserList(selectedUserFilterId);
	return useMutation(updateUser, {
		onSuccess: () => {
			invalidateUserList();
		},
	});
};

const useUserPartialBulkUpdate = (selectedUserFilterId) => {
	let invalidateUserList = useInvalidateUserList(selectedUserFilterId);
	return useMutation(userPartialBulkUpdate, {
		onSuccess: () => {
			invalidateUserList();
		},
	});
};

const useTransferUserRecordMutation = (selectedUserFilterId) => {
	let invalidateUserList = useInvalidateUserList(selectedUserFilterId);
	let invalidateUserDropdownList = useInvalidateUserDropdownList();
	return useMutation(transferUserRecords, {
		onSuccess: () => {
			invalidateUserList();
			invalidateUserDropdownList();
		},
	});
};

const useDeactivateUserMutation = (selectedUserFilterId) => {
	let invalidateUserList = useInvalidateUserList(selectedUserFilterId);
	let invalidateUserDropdownList = useInvalidateUserDropdownList();
	return useMutation(deactivateUser, {
		onSuccess: () => {
			invalidateUserList();
			invalidateUserDropdownList();
		},
	});
};

const useUserReinviteMutation = () => {
	return useMutation(userReinvite);
};

export {
	useUserTableData,
	useUserList,
	useAddUserMutation,
	useUserData,
	useUpdateUserMutation,
	useUserSummaryData,
	useAuditlogData,
	useUserToDoActivities,
	useUserCompletedActivties,
	useUserPartialBulkUpdate,
	useUserDetailsList,
	useTransferUserRecordMutation,
	useDeactivateUserMutation,
	useUserReinviteMutation,
	useUserActivateMutation,
};
