import { getEmailKey } from "..";

export const emailTemplateAllFolderListKey = [
	...getEmailKey(),
	"templatesFolderList",
];

export const getEmailTemplateFolderListKey = (
	templateViewType,
	searchValue
) => {
	if (searchValue) {
		return [
			...getEmailKey(),
			"templatesFolderList",
			templateViewType,
			searchValue,
		];
	}
	return [...getEmailKey(), "templatesFolderList", templateViewType];
};

export const getEmailTemplateListKey = (folderId) => {
	return [...getEmailKey(), String(folderId), "templatesList"];
};

export const getEmailTemplateDataKey = (templateId) => {
	return [...getEmailKey(), String(templateId), "templateData"];
};

export const getPlaceHolderSourcesKey = () => {
	return [...getEmailKey(), "placeHolderSources"];
};

export const getPlaceHolderFieldsKey = (sourceName) => {
	return [...getEmailKey(), "placeHolderFields", sourceName];
};

export const getTemplateFolderSettingsDataKey = (folderId) => {
	return [...getEmailKey(), String(folderId), "folderSettingsData"];
};
