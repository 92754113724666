import { alpha } from "@mui/material";

export const activityFilterKeys = {
	taskType: "type",
	dueDate: "dueDate",
	status: "status",
};

export const activityView = {
	list: "list",
	calendar: "calendar",
};

export const getStatusProperties = (status, theme) => {
	const statusProperties = {
		overdue: {
			textColor: theme ? theme.palette.error.main : "#000",
			iconColor: theme ? theme.palette.error.main : "#000",
			textDecorationLine: "none",
			opacity: 1,
			tooltip: "Mark as Completed",
			checked: false,
			isDone: true,
		},
		pending: {
			textColor: "#000",
			iconColor: "rgba(0, 0, 0, 0.4)",
			textDecorationLine: "none",
			opacity: 1,
			tooltip: "Mark as Completed",
			checked: false,
			isDone: true,
		},
		completed: {
			textColor: "rgba(0, 0, 0, 0.6)",
			iconColor: "rgba(0, 0, 0, 0.4)",
			textDecorationLine: "line-through",
			opacity: 0.5,
			tooltip: "Mark as not Completed",
			checked: true,
			isDone: false,
		},
	};

	return status
		? statusProperties[status.toLowerCase()]
		: {
				textColor: "#000",
				iconColor: "rgba(0, 0, 0, 0.4)",
			};
};

export const getDealIconOrDealNameColor = (rowState, theme) => {
	const styleForStatus = {
		OVERDUE: {
			color: theme.palette.error.main,
		},
		PENDING: {
			color: theme.palette.secondary.contrastText,
		},
		COMPLETED: {
			color: alpha(theme.palette.secondary.contrastText, 0.6),
		},
	};

	return rowState ? styleForStatus[rowState] : { color: "#000" };
};
