import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getProductDropdownKey,
	getProductPriceTagsDetailsKey,
	getProductDataKey,
	getProductPriceListKey,
	getProductSummaryKey,
	getProductTableKey,
	getProductSummaryViewKey,
} from "../../../utils/queryKeys";
import { getPriceTagDetails } from "../../../api/product/prices/pricesApi";
import {
	getProductDropdownList,
	createProduct,
	deleteProduct,
	getProductData,
	updateProductSummaryData,
	assignOwnerToProducts,
	getProductSummaryData,
	partialUpdateProduct,
	updateProduct,
} from "../../../api/product/productApi";

const productTableKey = getProductTableKey();

const useProductData = (id) => {
	return useQuery(getProductDataKey(id), () => getProductData(id));
};

const useProductSummaryData = (productId, hideEmptyFields) => {
	let productSummaryKey = getProductSummaryViewKey(
		productId,
		hideEmptyFields
	);
	return useQuery(productSummaryKey, () =>
		getProductSummaryData(productId, hideEmptyFields)
	);
};

const useInvalidateProductTableData = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(productTableKey);
	};
};

const useInvalidateProductSummary = (productId) => {
	let queryClient = useQueryClient();
	let productSummaryKey = getProductSummaryKey(productId);
	return () => {
		queryClient.invalidateQueries(productSummaryKey);
	};
};

const useInvalidateProductPriceList = (productId) => {
	let queryClient = useQueryClient();
	let productPriceListKey = getProductPriceListKey(productId);
	return () => {
		queryClient.invalidateQueries(productPriceListKey);
	};
};

const useInvalidateProductData = (productId) => {
	let queryClient = useQueryClient();
	let productDataKey = getProductDataKey(productId);
	return () => {
		queryClient.invalidateQueries(productDataKey);
	};
};

const useCreateProductMutation = () => {
	let invalidateProductTableData = useInvalidateProductTableData();
	return useMutation(createProduct, {
		onSuccess: () => {
			invalidateProductTableData();
		},
	});
};

const useUpdateProductMutation = (productId) => {
	let invalidateProductTableData = useInvalidateProductTableData();
	let invalidateProductSummaryData = useInvalidateProductSummary(productId);
	const invalidateProductPriceList = useInvalidateProductPriceList(productId);
	const invalidateProductData = useInvalidateProductData(productId);
	return useMutation(updateProductSummaryData, {
		onSuccess: () => {
			invalidateProductSummaryData();
			invalidateProductPriceList();
			invalidateProductTableData();
			invalidateProductData();
		},
	});
};

const useDeleteProductMutation = () => {
	let invalidateProductTableData = useInvalidateProductTableData();
	return useMutation(deleteProduct, {
		onSuccess: () => {
			invalidateProductTableData();
		},
	});
};

const useProductList = () => {
	let productDropdownKey = getProductDropdownKey();
	return useQuery(productDropdownKey, () => getProductDropdownList(), {
		select: (data) => data.options,
	});
};

const useProductPriceTagsDetails = (
	productId,
	currencyValue,
	isTaxInclusive,
	priceTagsEnabled
) => {
	let productPriceTagsDetailsKey = getProductPriceTagsDetailsKey(
		productId,
		currencyValue,
		isTaxInclusive
	);
	return useQuery(
		productPriceTagsDetailsKey,
		() => getPriceTagDetails(productId, currencyValue, isTaxInclusive),
		{
			enabled: priceTagsEnabled,
			select: (data) => data?.list,
		}
	);
};

//Assign owner mutation
const useAssignOwnerToProducts = () => {
	let invalidateProductTableData = useInvalidateProductTableData();
	return useMutation(assignOwnerToProducts, {
		onSuccess: () => {
			invalidateProductTableData();
		},
	});
};

// partial update
const usePartialUpdateProduct = (productId) => {
	let invalidateSummaryData = useInvalidateProductSummary(productId);
	return useMutation(partialUpdateProduct, {
		onSuccess: () => {
			invalidateSummaryData();
		},
	});
};

// partial bulk update
const usePartialBulkUpdateProduct = () => {
	let invalidateProductTableData = useInvalidateProductTableData();
	return useMutation(updateProduct, {
		onSuccess: () => {
			invalidateProductTableData();
		},
	});
};

export {
	useProductData,
	useDeleteProductMutation,
	useProductList,
	useCreateProductMutation,
	useUpdateProductMutation,
	useProductPriceTagsDetails,
	useAssignOwnerToProducts,
	useInvalidateProductTableData,
	useProductSummaryData,
	usePartialUpdateProduct,
	usePartialBulkUpdateProduct,
};
